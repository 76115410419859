import { memo } from 'react';
import { Flex, TabsProps } from 'antd';
import { Collapse } from 'antd-mobile';

import './MobileView.scss';

export interface MobileViewProps {
    tabs: TabsProps['items'];
}

export const MobileView = memo<MobileViewProps>(({ tabs = [] }) => {
    const t = tabs.filter((t) => !t.isChild);

    return (
        <Flex className={'mobile_view_detail'} vertical>
            {t?.map((tab, i) => {
                return (
                    <Collapse key={tab.key + i} defaultActiveKey={['common_info']}>
                        <Collapse.Panel
                            className={'mobile_view_detail__collapse_panel'}
                            key={tab.key}
                            title={<strong>{tab.label}</strong>}
                        >
                            {tab.children}
                        </Collapse.Panel>
                    </Collapse>
                );
            })}
        </Flex>
    );
});
