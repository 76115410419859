import { MergeDeep } from '@gilbarbara/types';
import { Collapse, Divider, Flex } from 'antd';
import { observer } from 'mobx-react-lite';
import { isArray } from 'is-lite/exports';
import { ReactNode, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Meta, MetaField } from 'modules/services/backend-api/generated_info';
// import { HelpIcon } from 'smart/ui';
import { fieldEditRender, fieldRender, getExcludeFieldsPattern } from 'smart/utils';

import { FieldsBox } from '../../ui';
import { SmartDetailPageChildTabContent } from '../SmartDetailPageChildTabContent/SmartDetailPageChildTabContent';

// const MAX_VALUE_LENGTH = 30;

// const truncateValue = (value: string) =>
//     value.length > MAX_VALUE_LENGTH ? `${value.substring(0, MAX_VALUE_LENGTH)}...` : value;

interface SmartDetailPageMainTabContentProps {
    metaFields: MetaField[];
    data: any;
    setData: (updater: any | ((prevValue: any) => any)) => void;
    onChange: (key: string, newValue: any) => void;
    mode: 'view' | 'edit';
    childNodeMeta: Meta[];
    exclude?: string[]; // FieldName[]
    rootMeta?: string;
}

interface FieldType {
    key: string;
    label: ReactNode;
    data: any;
    metaSource: MergeDeep<MetaField, Meta>;
    dataSource: SmartDetailPageMainTabContentProps['data'];
    setDataSource: SmartDetailPageMainTabContentProps['setData'];
    area?: string;
    hidden?: boolean; // temp
    readOnly?: boolean;
    description?: string;
}

interface ICollapseItem {
    key: string;
    label: React.ReactNode;
    children: React.ReactNode;
    disableCollapse?: boolean;
    ref?: React.RefObject<HTMLDivElement>;
}

const SIZE = 'small';

const getFieldsFilterRule =
    (exclude?: string[]) => (field: FieldType, index: number, fieldDataSource: FieldType[]) =>
        exclude &&
        !exclude.includes(field.key) &&
        getExcludeFieldsPattern(field, fieldDataSource, {
            isDetail: true,
            replaceFieldNameKey: 'key'
        });

export const SmartDetailPageMainTabContent = observer<SmartDetailPageMainTabContentProps>(
    ({ metaFields, data, setData, onChange, exclude, mode, childNodeMeta, rootMeta }) => {
        const {
            t,
            i18n: { language }
        } = useTranslation();
        const mainRef = useRef<HTMLDivElement>(null);
        const auditRef = useRef<HTMLDivElement>(null);
        const totalRef = useRef<HTMLDivElement>(null);

        const rootMetaCode = rootMeta || childNodeMeta?.[0].RootMeta_Code;

        // const handleDataSourceChange = useCallback(
        //     (key: string, value: any) => {
        //         const keys = key.split('.');

        //         if (keys.length > 1) {
        //             setData((prevData: any) => ({
        //                 ...prevData,
        //                 [keys[0]]: { ...prevData[keys[0]], [keys[1]]: value }
        //             }));
        //         } else {
        //             setData((prevData: any) => ({ ...prevData, [key]: value }));
        //         }
        //     },
        //     [setData]
        // );

        // ### ФИЛДЫ (ДАННЫЕ)
        const fieldDataSource = useMemo(() => {
            const fields: FieldType[] = [];

            // ### КЛАССИЧЕСКИЕ ФИЛДЫ
            for (const field of metaFields) {
                // if (
                //     // !isArray
                //     data[field.FieldName] &&
                //     isArray(data[field.FieldName]) &&
                //     field.FieldName !== 'DaysPattern'
                // )
                //     continue;

                const displayFieldName = field.Name
                    ? field.Name[language] || t(field.ColumnName) || field.FieldName
                    : t(field.ColumnName || field.FieldName);

                fields.push({
                    key: field.FieldName,
                    area: field?.LayoutArea,
                    label: displayFieldName,
                    // label формируем из меты, а если там пусто то i18n
                    data: data[field.FieldName],
                    metaSource: field,
                    dataSource: data,
                    setDataSource: setData,
                    // поле НЕ редактироемое если 1) есть такой флаг; 2) если оно в Main/Totals или Main/Audit.
                    readOnly:
                        field.LayoutArea === 'Main/Audit' ||
                        field.LayoutArea === 'Main/Totals' ||
                        field.IsReadOnly,
                    hidden: field.IsHiddenOnDetail, // temp,
                    description: field.Description?.[language],
                    required: field.IsMandatory
                });
            }

            // ### МАССИВНЫЕ ФИЛДЫ (чайлды, котолрые нужно рисовать в основном табе)
            for (const node of childNodeMeta) {
                if (node.ArrayNameInRoot) {
                    fields.push({
                        key: node.ArrayNameInRoot,
                        area: node?.LayoutArea,
                        label: '', // тут лейбл будем верстать свой ниже по коду
                        data: data[node.ArrayNameInRoot] || [],
                        metaSource: node,
                        dataSource: data,
                        setDataSource: setData,
                        readOnly: node.IsReadOnly, // TODO: ждем readOnly у секций/чайлдов
                        hidden: node.IsDisabled // temp
                        // description: node.Description?.[language]
                    });
                }
            }

            console.log('[SmartDetailPageMainTabContent] fields data:', fields);
            return fields;
        }, [childNodeMeta, data, language, metaFields, setData, t]);

        // ### ФИЛДЫ (РАЗМЕТКА)
        const fields = useMemo(
            () =>
                fieldDataSource.filter(getFieldsFilterRule(exclude)).map((field) => {
                    const isArrayNode = isArray(field.data) && !field.metaSource.ValueType;

                    const isInlineJson = // metaSource.FieldName === 'DaysPattern' ||
                        field.metaSource.ValueType?.includes('json') &&
                        field.metaSource.ValueType?.includes('display:inline') &&
                        !field.metaSource.ValueType?.includes('json_type') &&
                        !field.metaSource.FieldName.includes('DefaultValue') &&
                        !field.metaSource.ValueType?.includes('multilang_text');

                    return {
                        key: field.key,
                        label: field.label,
                        area: field.area,
                        hidden: field.hidden,
                        vertical: isInlineJson,
                        span: isArrayNode ? 2 : 1,
                        description: field.description,
                        children: isArrayNode ? (
                            <SmartDetailPageChildTabContent
                                data={field.dataSource}
                                setData={field.setDataSource}
                                onChange={onChange}
                                childNodeMeta={field.metaSource as Meta}
                                mode={mode}
                                padding={!field.area?.includes('/') ? 5 : 0}
                                readOnly={field.readOnly}
                                tableTitle={
                                    !field.area?.includes('/')
                                        ? field.metaSource.PluralName
                                            ? field.metaSource.PluralName[language] ||
                                              (t(field.metaSource.TableName) as string)
                                            : (t(field.metaSource.TableName) as string)
                                        : undefined
                                }
                                rootMeta={rootMetaCode}
                            />
                        ) : // <HelpIcon text={field.description}>
                        //     {
                        !field.readOnly && mode === 'edit' ? (
                            <div style={{ width: '100%' }}>
                                {fieldEditRender({
                                    data: field.data,
                                    // onChange: handleDataSourceChange,
                                    onChange,
                                    language,
                                    metaFieldData: metaFields,
                                    fieldName: field.key,
                                    dataSource: field.dataSource,
                                    rootDataSource: field.dataSource,
                                    rootMeta: rootMetaCode
                                })}
                            </div>
                        ) : (
                            fieldRender({
                                data: field.data,
                                language,
                                metaFieldData: metaFields,
                                fieldName: field.key,
                                dataSource: field.dataSource,
                                rootDataSource: field.dataSource,
                                rootMeta: rootMetaCode
                            })
                        )
                        //     }
                        // </HelpIcon>
                    };
                }),
            [
                exclude,
                fieldDataSource,
                // handleDataSourceChange,
                onChange,
                language,
                metaFields,
                mode,
                rootMetaCode,
                t
            ]
        );

        const notHiddenFields = useMemo(() => fields.filter((field) => !field.hidden), [fields]);

        // ### РАЗМЕТКА ГРУПП ФИЛДОВ
        const collapseItems = useMemo(() => {
            const areas = new Set<string>();
            fields.forEach((field) => {
                if (field.area) areas.add(field.area);
            });

            const collapseItems: ICollapseItem[] = [];

            // ### Группа ОСНОВНЫЕ ДАННЫЕ

            const mainTopFields = fields.filter((field) => field.area === 'Main/Header');

            const mainMiddleFields = notHiddenFields.filter(
                (field) => field.area === 'Main' || !areas.has(field.area || '')
            );
            const mainBottomFields = notHiddenFields.filter(
                (field) => field.area === 'Main/Footer'
            );

            if (mainTopFields.length || mainMiddleFields.length || mainBottomFields.length)
                collapseItems.push({
                    key: 'common_info',
                    label: <strong>{t('common_info')}</strong>,
                    disableCollapse: true,
                    children: (
                        <>
                            <FieldsBox items={mainTopFields} size={SIZE} />
                            {!!mainTopFields.length && (
                                <Divider style={{ margin: '5px 1px 5px 1px' }} />
                            )}
                            <FieldsBox items={mainMiddleFields} size={SIZE} />
                            {!!mainBottomFields.length && (
                                <Divider style={{ margin: '5px 1px 5px 1px' }} />
                            )}
                            <FieldsBox items={mainBottomFields} size={SIZE} />

                            <Divider style={{ margin: '8px 0 0 0', borderBlockStart: '0px' }} />
                        </>
                    ),
                    ref: mainRef
                });

            // ### ДРУГИЕ группы
            areas.forEach((area) => {
                if (
                    area !== 'Main' &&
                    area !== 'Main/Header' &&
                    area !== 'Main/Footer' &&
                    area !== 'Main/Statuses' &&
                    area !== 'Main/Audit' &&
                    area !== 'Main/Totals'
                ) {
                    const isChildArea = area.includes('/');

                    const itemKey = isChildArea ? area.split('/')[1] : area;

                    const items = notHiddenFields.filter((field) => field.area === area);

                    if (items.length) {
                        collapseItems.push({
                            key: itemKey,
                            label: <strong>{t(itemKey)}</strong>,
                            children: <FieldsBox size={SIZE} items={items} />,
                            disableCollapse: !isChildArea
                        });
                    }
                }
            });

            // ### Группа СТАТУСЫ
            const statusFields = notHiddenFields.filter((field) => field.area === 'Main/Statuses');
            if (statusFields.length) {
                collapseItems.push({
                    key: 'statuses',
                    label: <strong>{t('statuses')}</strong>,
                    children: <FieldsBox size={SIZE} items={statusFields} />
                });
            }

            // ### Группа ИТОГИ
            const totalFields = notHiddenFields.filter((field) => field.area === 'Main/Totals');
            if (totalFields.length) {
                collapseItems.push({
                    key: 'totals',
                    label: <strong>{t('totals')}</strong>,
                    children: <FieldsBox size={SIZE} items={totalFields} />,
                    ref: totalRef
                });
            }

            // ### Группа АУДИТ
            const auditFields = fields.filter((field) => field.area === 'Main/Audit');
            if (auditFields.length) {
                collapseItems.push({
                    key: 'audit',
                    label: <strong>{t('audit')}</strong>,
                    children: <FieldsBox size={SIZE} items={auditFields} />,
                    ref: auditRef
                });
            }

            return collapseItems;
        }, [fields, notHiddenFields, t]);

        const collapsedItems = collapseItems.filter((item) => !item.disableCollapse);
        const uncollapsedItems = collapseItems.filter((item) => item.disableCollapse);

        // ### RENDER
        return (
            <Flex vertical className="smart_detail_page__main_content">
                <Flex vertical ref={mainRef}>
                    {uncollapsedItems.map((item) => item.children)}
                </Flex>
                {!!collapsedItems?.length && (
                    <Collapse
                        // onChange={() => setIsExpand((prev) => !prev)}
                        className="smart_detail_page__collapse"
                        size={SIZE}
                        defaultActiveKey={['common_info']}
                        items={collapsedItems}
                    />
                )}
            </Flex>
        );
    }
);
