import { baseRoutes, other, transactional_data } from 'routing/allRoutes';
import { RouteController } from 'routing/routesTypes';
// когда загружаем DetailPage у мастерданных и транзакционных данных (документов)
// то загружаем по KEY, а когда загружаем страницу с настрйоками то по ID

// Для компонентов, таких как ХК и Aside
export const routes: RouteController = {
    baseRoutes
    // transactional_data
};

// Для App.tsx
export const allRoutes = [...baseRoutes, ...other];
