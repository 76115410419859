/*
 * - Разбивает строку пути на массив ключей с помощью split('.').
 * - Итерируется по массиву ключей и последовательно переходит по объекту.
 * - Если на каком-то этапе result становится undefined или null, возвращает undefined.
 * - Возвращает значение найденного поля.
 * - Принимает два аргумента:
 * @param {T} obj - объект, из которого нужно получить значение.
 * @param {String} path - строка, описывающая путь к нужному полю.
 */
export function getNestedValueFromObject<T>(obj: T, path: string, language: string): any {
    const keys = path.split('.');

    let result: any = obj;
    for (const key of keys) {
        if (result === undefined || result === null) {
            return undefined;
        }
        result = result[key];
    }

    if (typeof result === 'object') {
        return result?.[language] || JSON.stringify(result);
    }

    return result;
}
