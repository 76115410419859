import { makeAutoObservable } from 'mobx';

export class ModalStore {
    isOpen = false;

    constructor() {
        makeAutoObservable(this);
    }

    open() {
        this.isOpen = true;
    }

    close() {
        this.isOpen = false;
    }
}

export const modalStores = { changelog: new ModalStore() };
