import { Form, GetRef } from 'antd';
import React from 'react';

type FormInstance<T> = GetRef<typeof Form<T>>;

interface EditableContextValueType {
    form: FormInstance<any>;
    initialized: boolean;
}

export const EditableContext = React.createContext<EditableContextValueType | null>(null);
