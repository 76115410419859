import { PostgrestError } from '@supabase/postgrest-js';
import _ from 'lodash';
import { createContext, Dispatch } from 'react';
import { Database } from 'modules/supabase/types/database.types';
import { ViewName } from '../../modules/supabase/types/Dataset';
import { Filter, OrderBy } from '../../modules/supabase/utils/supabaseClient';

// Клевая штука
type Substring<T extends string, U extends string> = T extends `${infer F}${U}${infer R}`
    ? T
    : never;

type ViewsFilter<T extends string> = Substring<ViewName, T>;

export type DictsViews = ViewsFilter<'dicts'>;

export type DictDataType<T extends DictsViews> = Database['public']['Views'][T]['Row'];

type DictFetchResult = {
    data: DictDataType<DictsViews>[];
    error: PostgrestError | null;
};

type DictRequestType = {
    dictViewName: DictsViews;
    filters?: Filter[];
    orderBy?: OrderBy[];
    pageNumber?: number;
    pageSize?: number;
    virtualization?: boolean;
};

// Запросы могут быть и с фильтром и с сортировкой и с пагинацией - все это учитываем
type DictStorageType<T extends DictsViews> = {
    request: DictRequestType;
    data: DictDataType<T>[];
    error: PostgrestError | null;
};

export type getDictByRequestType = ({
    dictViewName,
    filters,
    orderBy,
    pageNumber,
    pageSize,
    virtualization
}: {
    dictViewName: DictsViews;
    filters?: Filter[];
    orderBy?: OrderBy[];
    pageNumber?: number;
    pageSize?: number;
    virtualization?: boolean;
}) => DictFetchResult | null;

type DictStateType = DictStorageType<DictsViews>[];

type DictStorageAction = {
    type: 'ADD_DICT';
    payload: {
        request: DictRequestType;
        fetchResult: DictFetchResult;
    };
};

export const DictContext = createContext<{
    state: DictStateType;
    dispatch: Dispatch<DictStorageAction>;
    getDictByRequest: getDictByRequestType;
}>({
    state: [],
    getDictByRequest: () => {
        return {
            data: [],
            error: null,
            isLoading: false
        };
    },
    dispatch: () => {}
});

// TODO: сделать автообновление
export const DictContextReducer = (
    state: DictStateType,
    action: DictStorageAction
): DictStateType => {
    const copyState: DictStateType = [...state];

    let alreadyLoadedDictIndex = -1;

    switch (action.type) {
        // Добавляем словарь
        case 'ADD_DICT':
            // Ищим уже загруженный словарь
            alreadyLoadedDictIndex = copyState.findIndex((data) =>
                _.isEqual(data.request, action.payload.request)
            );

            // Если нашли - удаляем
            if (alreadyLoadedDictIndex === 0 || alreadyLoadedDictIndex > 0) {
                copyState.splice(alreadyLoadedDictIndex, 1);
            }
            // Заносим новые данные в Storage
            copyState.push({
                request: action.payload.request,
                data: action.payload.fetchResult.data,
                error: action.payload.fetchResult.error
            });
            return copyState;
        default:
            return copyState;
    }
};
