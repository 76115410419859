// Breadcrumbs component
// This component is used to display the breadcrumbs for the current page
// It splits the path from URL into parts
// and translates each part via i18n and creates breadcrumbs clickable elements

import { Breadcrumb } from 'antd';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { StoreLink } from 'ui';

import { useBreadcrumbs } from 'utils/hooks/useBreadcrumbs';
import { metaStore } from 'utils/store/MetaStore';
import { fnv1aHash } from 'smart/utils';
import { ID_REGX, USER_ID_REGX } from '../constants';

export const Breadcrumbs = memo(() => {
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const { key } = useBreadcrumbs();

    const isHomePage = location.pathname === '/';

    const generateBreadcrumbs = () => {
        const paths = location.pathname.split('/').filter((path) => path !== '');
        let url = '';

        const breadcrumbs = paths.map((path, index) => {
            url += `/${path}`;

            const isLast = index === paths.length - 1;

            const metaMenuItem = metaStore.meta.get('all')?.menu?.items.find((item) => {
                //  item.path ===
                //     `${pathname}${state?.filterString ? `?${state?.filterString}` : ''}`
                return item.path.split('?')[0] === url;
            });

            let itemTitle = key && (ID_REGX.test(path) || USER_ID_REGX.test(path)) ? key : path;
            let filterString = '';
            let cacheKey;

            if (metaMenuItem && metaMenuItem.name?.[i18n.language]) {
                itemTitle = metaMenuItem.name[i18n.language];
                filterString = metaMenuItem.path.split('?')[1];
                cacheKey = fnv1aHash(`${metaMenuItem.id}_${metaMenuItem.name?.[i18n.language]}`);
            }

            if (isLast) itemTitle = location.state?.pageTitle || path;

            return {
                title: isLast ? (
                    t(itemTitle)
                ) : (
                    <StoreLink
                        to={url}
                        state={{ filterString, cacheKey }}
                        className="active-breadcrumb"
                    >
                        {t(itemTitle)}
                    </StoreLink>
                )
            };
        });

        breadcrumbs.unshift({
            title: isHomePage ? (
                (t('home') as string)
            ) : (
                <StoreLink to="/" className="active-breadcrumb">
                    {t('home')}
                </StoreLink>
            )
        }); // Добавляем домашнюю страницу в начало массива breadcrumbs
        return breadcrumbs;
    };

    const items = generateBreadcrumbs();

    return <Breadcrumb style={{ margin: '16px 0' }} items={items} separator="\" />;
});
