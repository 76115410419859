// WebSocketService.ts
import { i18n } from 'utils/i18n/i18n';
import { v4 as uuidv4 } from 'uuid';
import { action, makeAutoObservable } from 'mobx';
import { Request, Response } from './generated_api';

export type ProcessSubscriptionFn = (response: Response) => void;
export type UnsubscribeFn = () => void;

class WebSocketService {
    public isConnected = false;

    private url: string;

    private ws: WebSocket | null = null;

    private responseHandlers = new Map<string, (response: Response) => void>();

    private subscriptions = new Map<string, (response: Response) => void>();

    private reconnectInterval = 5000; // 5 seconds

    private isReconnecting = false;

    constructor(url: string) {
        makeAutoObservable(this, {
            setIsConnected: action
        });

        this.url = url;
    }

    connect = (): Promise<void> => {
        console.log('WebSocket: connect()');

        return new Promise((resolve, reject) => {
            this.ws = new WebSocket(this.url);

            // Event listeners for WebSocket connection
            this.ws.addEventListener('open', () => {
                console.log('WebSocket connection established');
                this.setIsConnected(true);
                resolve();
            });

            this.ws.addEventListener('error', (err) => {
                console.error('WebSocket error:', err);
                this.setIsConnected(false);
                reject(err);
            });

            this.ws.addEventListener('message', this.handleMessage);

            this.ws.addEventListener('close', (event) => {
                console.log('WebSocket connection closed:', event);
                this.setIsConnected(false);
                this.handleReconnect();
            });
        });
    };

    getWs = (): WebSocket | null => {
        return this.ws;
    };

    waitForConnection = (callback: () => void, interval: number = 1000): void => {
        if (this.ws) {
            if (this.ws.readyState === WebSocket.OPEN) {
                callback();
            } else {
                setTimeout(() => this.waitForConnection(callback, interval), interval);
            }
        }
    };

    sendRequest = (
        data: Omit<Request, 'request_id' | 'return_log_messages'>
    ): Promise<Response> => {
        return new Promise((resolve, reject) => {
            this.waitForConnection(() => {
                if (!this.ws) {
                    reject(new Error('WebSocket is not connected.'));
                    return;
                }

                const request_id = uuidv4();
                const return_log_messages = false;

                const message: Request & { Lang: string } = {
                    ...data,
                    request_id,
                    return_log_messages,
                    Lang: i18n.language || 'en'
                };

                this.responseHandlers.set(request_id, (response: Response) => {
                    resolve(response);
                    this.responseHandlers.delete(request_id);
                });

                this.ws.send(JSON.stringify(message));
            }, 1000);
        });
    };

    subscribe = (
        data: Omit<Request, 'request_id' | 'return_log_messages'>,
        process: ProcessSubscriptionFn
    ): UnsubscribeFn => {
        let wasResponse = false;
        const request_id = uuidv4();

        const unsubscribe = () => {
            console.log('Unsubscribed from', request_id);

            // FIXME: на backend не реализована отписка, поэтому после отписки на фронте,
            //  будем продолжать получать сообщения
            this.subscriptions.delete(request_id);
        };

        this.waitForConnection(() => {
            if (!this.ws) {
                throw new Error('WebSocket is not connected.');
            }

            const return_log_messages = false;

            const message: Request & { Lang: string } = {
                ...data,
                request_id,
                return_log_messages,
                Lang: i18n.language || 'en'
            };

            this.subscriptions.set(request_id, (response: Response) => {
                if (!wasResponse) {
                    console.log('Subscribed to', request_id);

                    wasResponse = true;

                    return;
                }

                process(response);
            });

            this.ws.send(JSON.stringify(message));
        }, 1000);

        return unsubscribe;
    };

    close = (): void => {
        if (this.ws && this.ws.readyState === WebSocket.OPEN) {
            console.log('WebSocket: close()');
            this.ws.close();
            this.ws = null;
            this.responseHandlers.clear();
            this.subscriptions.clear();
        }
    };

    private handleMessage = (event: MessageEvent): void => {
        try {
            const message = event.data;

            // Check if the message is a "ping" message
            if (message === 'ping') {
                console.log('Ping message received from server');
            } else {
                const response: Response = JSON.parse(message);

                const handler = this.responseHandlers.get(response.request_id);
                if (handler) {
                    handler(response);

                    return;
                }

                const subscriptionHandler = this.subscriptions.get(response.request_id);
                if (subscriptionHandler) {
                    subscriptionHandler(response);

                    return;
                }

                console.warn(`Unhandled message: ${message}`);
            }
        } catch (error) {
            console.error('Error parsing message:', error);
        }
    };

    private handleReconnect = (): void => {
        if (!this.isReconnecting) {
            this.isReconnecting = true;
            this.responseHandlers.clear();
            this.subscriptions.clear();

            setTimeout(() => {
                console.log('Attempting to reconnect...');
                this.connect()
                    .then(() => {
                        this.isReconnecting = false;
                        console.log('Reconnected successfully');

                        // TODO: наверное, надо перезапускать подписки
                    })
                    .catch((err) => {
                        console.error('Reconnection failed:', err);
                        this.isReconnecting = false;
                        this.handleReconnect();
                    });
            }, this.reconnectInterval);
        }
    };

    private setIsConnected = (value: boolean) => {
        this.isConnected = value;
    };
}

export default WebSocketService;
