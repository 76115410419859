import { Flex, Typography } from 'antd';
import { toJS } from 'mobx';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncFn, useList } from 'react-use';
import { ListActions } from 'react-use/lib/useList';

import {
    RunStatusEnum,
    RunStatusCreated,
    RunStatusQueued,
    RunStatusFailedToEnqueue,
    RunStatusStarted,
    RunStatusFailed,
    RunStatusSucceeded,
    RunStatusSkipped
} from 'modules/services/backend-api/generated_info';
import { RunRequest } from 'modules/services/backend-api/generated_api';
import { LogMessage } from 'modules/services/backend-api/generated_smart_context';
import { LogsField } from 'smart/components/LogsField/LogsField';
import { StoreLink } from 'ui';
import { useNotifications } from 'utils/hooks';
import { metaStore } from 'utils/store/MetaStore';
import { v4 } from 'uuid';

const NotifyDescription = ({
    requestId,
    linkText,
    text,
    navParams,
    onCloseNotify
}: {
    navParams?: { to: string; state: { filterString: string } };
    text?: string;
    requestId?: string;
    linkText?: string;
    onCloseNotify?: () => void;
}) => (
    <>
        <Typography.Paragraph style={{ padding: 0, marginBottom: 2 }}>{text}</Typography.Paragraph>
        <Flex gap={5} align="center">
            {navParams && linkText ? (
                <StoreLink style={{ padding: 0 }} {...navParams} onClick={onCloseNotify}>
                    {linkText}
                </StoreLink>
            ) : null}
            {requestId && (
                <div style={{ position: 'absolute', bottom: 10, right: 20 }}>
                    <LogsField requestId={requestId} onClick={onCloseNotify} />
                </div>
            )}
        </Flex>
    </>
);

const notifyKey = v4();

export const useHandlerRun = (customLogsMethods?: Partial<ListActions<LogMessage>>) => {
    const { t } = useTranslation();
    const { notification } = useNotifications();

    const [logs, logsMethods] = useList<LogMessage>([]);

    const requestsRoute = useMemo(() => {
        return toJS(
            metaStore.meta.get('all')?.routes?.find((route) => route.meta === 'InfoRequests')
        );
    }, []);

    const uiAllowView = useMemo(() => {
        return toJS(metaStore.meta.get('InfoRequests')?.info?.UiAllowView) ?? true;
    }, []);

    const handleClickNotifyActions = () => {
        notification.destroy(notifyKey);
    };

    const getRequestNavParams = useCallback(
        (requestId: string | undefined) => {
            if (requestsRoute) {
                const [pathname, filterString] = requestsRoute.path.split('?');

                if (!requestId) return undefined;

                return {
                    to: pathname.endsWith('/')
                        ? `${pathname}${requestId}`
                        : `${pathname}/${requestId}`,
                    state: {
                        filterString
                    }
                };
            }

            return undefined;
        },
        [requestsRoute]
    );

    // Generalized function to handle different statuses
    const handleNotification = (
        status: RunStatusEnum,
        requestId: string | undefined,
        errorText?: string
    ) => {
        const notificationConfig = {
            key: notifyKey,
            description: (
                <NotifyDescription
                    navParams={getRequestNavParams(requestId)}
                    requestId={requestId}
                    linkText={uiAllowView ? `${t('show_details')}...` : undefined}
                    text={errorText}
                    onCloseNotify={handleClickNotifyActions}
                />
            ),
            onClick: handleClickNotifyActions,
            duration: 20
        };

        switch (status) {
            case RunStatusSucceeded:
            case RunStatusQueued:
                notification.success({
                    ...notificationConfig,
                    message: t('handler_response_success')
                });
                break;
            case RunStatusFailed:
            case RunStatusSkipped:
            case RunStatusFailedToEnqueue:
                notification.error({
                    ...notificationConfig,
                    message: t('handler_response_fail'),
                    duration: 60
                });
                break;
            case RunStatusCreated:
            case RunStatusStarted:
                notification.info({
                    ...notificationConfig,
                    message: t('handler_response_info')
                });
                break;
            default:
                notification.info({
                    ...notificationConfig,
                    message: t('handler_response_unknown')
                });
        }
    };

    const [result, run] = useAsyncFn(
        async (options: RunRequest, disableNotify?: boolean) => {
            const response = await metaStore.makeRun(options);

            if (disableNotify) return response;

            const requestId = response?.request_id;
            const status = response?.run?.[0]?.Status as RunStatusEnum;
            const errorText = response?.error_text;

            handleNotification(status, requestId, errorText);

            if (response?.log_messages?.length) {
                if (customLogsMethods?.set) customLogsMethods.set(response?.log_messages);
                else logsMethods.set(response?.log_messages!);
            }

            return response;
        },
        [customLogsMethods?.set, getRequestNavParams, t]
    );

    return { result, run, logs: { value: logs, ...logsMethods } };
};
