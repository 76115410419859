import { Flex, Typography } from 'antd';
import { toJS } from 'mobx';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncFn, useList } from 'react-use';
import { ListActions } from 'react-use/lib/useList';

import { RunRequest } from 'modules/services/backend-api/generated_api';
import { LogMessage } from 'modules/services/backend-api/generated_smart_context';
import { LogsField } from 'smart/components/LogsField/LogsField';
import { StoreLink } from 'ui';
import { useNotifications } from 'utils/hooks';
import { metaStore } from 'utils/store/MetaStore';
import { v4 } from 'uuid';

const NotifyDescription = ({
    requestId,
    linkText,
    text,
    navParams,
    onCloseNotify
}: {
    navParams?: { to: string; state: { filterString: string } };
    text?: string;
    requestId?: string;
    linkText?: string;
    onCloseNotify?: () => void;
}) => (
    <>
        <Typography.Paragraph style={{ padding: 0, marginBottom: 2 }}>{text}</Typography.Paragraph>
        <Flex gap={5} align="center">
            {navParams && linkText ? (
                <StoreLink style={{ padding: 0 }} {...navParams} onClick={onCloseNotify}>
                    {linkText}
                </StoreLink>
            ) : null}
            {requestId && (
                <div style={{ position: 'absolute', bottom: 10, right: 20 }}>
                    <LogsField requestId={requestId} onClick={onCloseNotify} />
                </div>
            )}
        </Flex>
    </>
);

const notifyKey = v4();

export const useHandlerRun = (customLogsMethods?: Partial<ListActions<LogMessage>>) => {
    const { t } = useTranslation();
    const { notification } = useNotifications();

    const [logs, logsMethods] = useList<LogMessage>([]);

    const requestsRoute = useMemo(() => {
        return toJS(
            metaStore.meta.get('all')?.routes?.find((route) => route.meta === 'InfoRequests')
        );
    }, []);

    const uiAllowView = useMemo(() => {
        return toJS(metaStore.meta.get('InfoRequests')?.info?.UiAllowView) ?? true;
    }, []);

    const handleClickNotifyActions = () => {
        notification.destroy(notifyKey);
    };

    const getRequestNavParams = useCallback(
        (requestId: string | undefined) => {
            if (requestsRoute) {
                const [pathname, filterString] = requestsRoute.path.split('?');

                if (!requestId) return undefined;

                return {
                    to: pathname.endsWith('/')
                        ? `${pathname}${requestId}`
                        : `${pathname}/${requestId}`,
                    state: {
                        filterString
                    }
                };
            }

            return undefined;
        },
        [requestsRoute]
    );

    const [result, run] = useAsyncFn(
        async (options: RunRequest, disableNotify?: boolean) => {
            const response = await metaStore.makeRun(options);

            if (disableNotify) return response;

            const requestId = response?.request_id;

            if (response?.run?.[0]?.Status === 'EXECUTION_SUCCEEDED') {
                notification.success({
                    key: notifyKey,
                    description: (
                        <NotifyDescription
                            navParams={getRequestNavParams(requestId)}
                            requestId={requestId}
                            linkText={uiAllowView ? `${t('show_details')}...` : undefined}
                            text={response?.error_text}
                            onCloseNotify={handleClickNotifyActions}
                        />
                    ),
                    message: t('handler_response_success'),
                    onClick: handleClickNotifyActions
                });
            }
            if (response?.run?.[0]?.Status === 'EXECUTION_FAILED') {
                notification.error({
                    key: notifyKey,
                    description: (
                        <NotifyDescription
                            navParams={getRequestNavParams(requestId)}
                            requestId={requestId}
                            linkText={uiAllowView ? (t('show_details') as string) : undefined}
                            text={response?.error_text}
                            onCloseNotify={handleClickNotifyActions}
                        />
                    ),
                    message: t('handler_response_fail'),
                    onClick: handleClickNotifyActions
                });
            } else if (response?.error_text) {
                notification.error({
                    key: notifyKey,
                    description: (
                        <NotifyDescription
                            navParams={getRequestNavParams(requestId)}
                            requestId={requestId}
                            linkText={uiAllowView ? (t('show_details') as string) : undefined}
                            text={response?.error_text}
                            onCloseNotify={handleClickNotifyActions}
                        />
                    ),
                    message: t('handler_response_fail'),
                    onClick: handleClickNotifyActions
                });
            }

            if (response?.log_messages?.length) {
                if (customLogsMethods?.set) customLogsMethods.set(response?.log_messages);
                else logsMethods.set(response?.log_messages!);
            }

            return response;
        },
        [customLogsMethods?.set, getRequestNavParams, t]
    );

    return { result, run, logs: { value: logs, ...logsMethods } };
};
