import { ButtonWithTooltips } from 'ui';

import './WeekDay.scss';

interface WeekDayPropsType {
    weekDay: string;
    onDaySelect: Function;
    selected: boolean;
    mode?: 'edit' | 'view';
}

export const WeekDay = ({ weekDay, onDaySelect, selected, mode }: WeekDayPropsType) => {
    if (mode !== 'edit') {
        return <div className={`weekDay ${selected ? 'weekDaySelected' : ''}`}>{weekDay}</div>;
    }

    return (
        <ButtonWithTooltips
            id={`${weekDay}`}
            className={`weekDay ${selected ? 'weekDaySelected' : ''}`}
            onClick={() => {
                onDaySelect();
            }}
        >
            {weekDay}
        </ButtonWithTooltips>
    );
};
