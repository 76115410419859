import React, { useContext, useMemo, useState } from 'react';

const breadcrumbsContext = React.createContext<{
    key: string | null;
    setKey: React.Dispatch<React.SetStateAction<string | null>> | null;
}>({ key: null, setKey: null });

export const useBreadcrumbs = () => {
    const context = useContext(breadcrumbsContext);
    return context;
};

export const BreadcrumbsProvider = ({ children }: React.PropsWithChildren) => {
    const [key, setKey] = useState<string | null>(null);

    const value = useMemo(() => ({ key, setKey }), [key]);

    return <breadcrumbsContext.Provider value={value}>{children}</breadcrumbsContext.Provider>;
};
