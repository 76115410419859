import { useEffect, useState } from 'react';
import {
    SCREEN_2K,
    SCREEN_FULL_HD,
    SCREEN_LG,
    SCREEN_MD,
    SCREEN_SM,
    SCREEN_XL,
    SCREEN_XXL
} from './constants';

interface IWidth {
    isScreenSm: boolean;
    isScreenMd: boolean;
    isScreenLg: boolean;
    isScreenXl: boolean;
    isScreenXxl: boolean;
    isScreenFullHd: boolean;
    isScreen2K: boolean;
}

const initWidth = {
    isScreenSm: false,
    isScreenMd: false,
    isScreenLg: false,
    isScreenXl: false,
    isScreenXxl: false,
    isScreenFullHd: false,
    isScreen2K: false
};

export const useResize = () => {
    const [width, setWidth] = useState<IWidth>(initWidth);

    useEffect(() => {
        const handleResize = (event: any) => {
            const comparable = {
                isScreenSm: event.target.innerWidth <= SCREEN_SM,
                isScreenMd: event.target.innerWidth <= SCREEN_MD,
                isScreenLg: event.target.innerWidth <= SCREEN_LG,
                isScreenXl: event.target.innerWidth <= SCREEN_XL,
                isScreenXxl: event.target.innerWidth <= SCREEN_XXL,
                isScreenFullHd: event.target.innerWidth <= SCREEN_FULL_HD,
                isScreen2K: event.target.innerWidth <= SCREEN_2K
            };

            setWidth((prev) =>
                JSON.stringify(prev) === JSON.stringify(comparable) ? prev : comparable
            );
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // пока убрал возвращение width, очень много ререндера происходит при ресайзе
    // пока нигде не надо получать ширину, лучше так
    return width;
};
