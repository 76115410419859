import { i18n } from 'utils/i18n/i18n';

// FIXME: поставить корректный тип
export const renderOptionLabel = (item: any, viewFieldName: string) => {
    if (item) {
        const multilangField = item.Title || item.ShortTitle || item.Name || item.PluralName;

        // TODO: убрать когда не будет кастомной реализации в модалке хэндлера doc_trips.create_execution_event
        if (viewFieldName === 'StopNum') {
            return `${item.StopNum}. ${item.Location.Name[i18n.language]}`;
        }
        if (viewFieldName === 'Service') {
            return item.Service.Name[i18n.language];
        }

        const defaultLabel =
            multilangField && multilangField?.[i18n.language]
                ? multilangField[i18n.language]
                : item.Key || item.Code || item.Id;

        if (!item[viewFieldName]) {
            return defaultLabel;
        }

        if (typeof item[viewFieldName] === 'object') {
            return item[viewFieldName][i18n.language] || defaultLabel;
        }

        return item[viewFieldName];
    }

    return item;
};
