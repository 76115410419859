// WebSocketService.ts
import { i18n } from 'utils/i18n/i18n';
import { v4 as uuidv4 } from 'uuid';
import { Request, Response } from './generated_api';

class WebSocketService {
    private url: string;
    private ws: WebSocket | null = null;
    private responseHandlers = new Map<string, (response: Response) => void>();
    private reconnectInterval = 5000; // 5 seconds
    private isReconnecting = false;

    constructor(url: string) {
        this.url = url;
    }

    connect = (): Promise<void> => {
        console.log('WebSocket: connect()');

        return new Promise((resolve, reject) => {
            this.ws = new WebSocket(this.url);

            // Event listeners for WebSocket connection
            this.ws.addEventListener('open', () => {
                console.log('WebSocket connection established');
                resolve();
            });

            this.ws.addEventListener('error', (err) => {
                console.error('WebSocket error:', err);
                reject(err);
            });

            this.ws.addEventListener('message', this.handleMessage);

            this.ws.addEventListener('close', (event) => {
                console.log('WebSocket connection closed:', event);
                this.handleReconnect();
            });
        });
    };

    getWs = (): WebSocket | null => {
        return this.ws;
    };

    waitForConnection = (callback: () => void, interval: number = 1000): void => {
        if (this.ws) {
            if (this.ws.readyState === WebSocket.OPEN) {
                callback();
            } else {
                setTimeout(() => this.waitForConnection(callback, interval), interval);
            }
        }
    };

    sendRequest = (
        data: Omit<Request, 'request_id' | 'return_log_messages'>
    ): Promise<Response> => {
        return new Promise((resolve, reject) => {
            this.waitForConnection(() => {
                if (!this.ws) {
                    reject(new Error('WebSocket is not connected.'));
                    return;
                }

                const request_id = uuidv4();
                const return_log_messages = true;

                const message: Request & { Lang: string } = {
                    ...data,
                    request_id,
                    return_log_messages,
                    Lang: i18n.language || 'en'
                };

                this.responseHandlers.set(request_id, (response: Response) => {
                    resolve(response);
                    this.responseHandlers.delete(request_id);
                });

                this.ws.send(JSON.stringify(message));
            }, 1000);
        });
    };

    private handleMessage = (event: MessageEvent): void => {
        try {
            const message = event.data;
            
            // Check if the message is a "ping" message
            if (message === 'ping') {
                console.log('Ping message received from server');
            } else {
                const response: Response = JSON.parse(message);
                const handler = this.responseHandlers.get(response.request_id);
                if (handler) {
                    handler(response);
                } else {
                    console.warn(`Unhandled message: ${message}`);
                }
            }
        } catch (error) {
            console.error('Error parsing message:', error);
        }
    };

    private handleReconnect = (): void => {
        if (!this.isReconnecting) {
            this.isReconnecting = true;
            setTimeout(() => {
                console.log('Attempting to reconnect...');
                this.connect()
                    .then(() => {
                        this.isReconnecting = false;
                        console.log('Reconnected successfully');
                    })
                    .catch((err) => {
                        console.error('Reconnection failed:', err);
                        this.isReconnecting = false;
                        this.handleReconnect();
                    });
            }, this.reconnectInterval);
        }
    };

    close = (): void => {
        if (this.ws && this.ws.readyState === WebSocket.OPEN) {
            console.log('WebSocket: close()');
            this.ws.close();
            this.ws = null;
        }
    };
}

export default WebSocketService;
