import { StorageError } from '@supabase/storage-js';

import { getFileUrlForDownload } from './getFileUrlForDownload';

export const downloadFile = async (bucket: string, path: string, name?: string) => {
    try {
        // Скачиваем файл из bucket storage в supabase
        const downloadUrl = await getFileUrlForDownload(bucket, path);

        // Создаем ссылку для скачивания
        const link = document.createElement('a');

        link.href = downloadUrl;
        link.download = name || '';

        // Кликаем по ссылке для начала скачивания
        document.body.appendChild(link);

        link.click();
        document.body.removeChild(link);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error((error as StorageError | Error).message);
    }
};
