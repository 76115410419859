import { Empty, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

interface EmptyMarkerProps {
    noDescription?: boolean;
    noImage?: boolean;
    title?: string | null;
    size?: 'small' | 'default';
}

export const EmptyMarker = ({
    noDescription = false,
    noImage = false,
    title,
    size = 'default'
}: EmptyMarkerProps) => {
    const { t } = useTranslation();

    if (noImage && noDescription) {
        return <></>;
    }

    if (noImage) {
        return <div style={{ margin: -8 }}>{t('no_data')}</div>;
    }

    return (
        <Empty
            style={{
                marginInline: size === 'default' ? '18px' : '9px',
                marginTop: size === 'default' ? '32px' : '1px',
                marginBottom: size === 'default' ? '32px' : '1px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}
            description={
                !noDescription ? (
                    <Typography.Text style={{ padding: 0 }} type="secondary">
                        {title || t('no_data')}
                    </Typography.Text>
                ) : (
                    ' '
                )
            }
            image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
    );
};
