import React, { CSSProperties } from 'react';

export interface ColorMarkerProps {
    status: 'filled' | 'partial_filled' | 'free' | 'closed';
    // fullWidth?: boolean;
    width?: string;
    text?: number | string;
    color?: CSSProperties['color'];
    border?: CSSProperties['border'];
    isContentStart?: boolean;
    isContentEnd?: boolean;
}

export const ColorMarker = ({
    status,
    width,
    color,
    border,
    text = '',
    isContentStart = false,
    isContentEnd = false
}: ColorMarkerProps) => {
    const getColor = () => {
        switch (status) {
            case 'filled':
                return 'red';
            case 'partial_filled':
                return '#FFB6C1';
            case 'free':
                return 'skyblue';
            case 'closed':
                return 'lightgray';
            default:
                return 'white';
        }
    };

    return (
        <div
            className={'color_marker'}
            style={{
                margin: width ? -7 : undefined,
                // width: width ? `calc(${width} + 0.7rem)` : 5,
                width: width ? 'calc(100% + 0.9rem)' : 5,
                height: width ? 'calc(44px - 2 * 0.7rem)' : 15,
                padding: width ? '0.7rem 0rem' : undefined,
                backgroundColor: 'lightgray',
                color: 'white',
                textAlign: 'center',
                border,
                boxSizing: 'content-box'
            }}
        >
            <div
                style={{
                    // boxSizing: 'content-box',
                    // width: width === '100%' || status !== 'free' ? '100%' : `calc(100% - ${width})`,
                    width: status === 'free' && width !== '100%' ? width : '100%',
                    height: '43px',
                    backgroundColor: status !== 'closed' ? 'skyblue' : 'transparent',
                    marginTop: -11,
                    // marginRight: -1,
                    marginLeft: status === 'free' && width !== '100%' ? 'auto' : undefined
                }}
            >
                <div
                    style={{
                        // boxSizing: 'content-box',
                        width: isContentEnd ? width : `calc(${width} + 3px)`,
                        boxShadow:
                            status !== 'closed' && status !== 'free'
                                ? '8px 5px 8px 0px rgba(34, 60, 80, 0.3)'
                                : undefined,
                        height: '100%',
                        backgroundColor:
                            status !== 'closed' && status !== 'free'
                                ? color ?? getColor()
                                : 'transparent',
                        marginLeft: isContentStart && width !== '100%' ? 'auto' : undefined,
                        // marginRight: -2

                        padding: '10px 0'
                    }}
                >
                    {text}
                </div>
            </div>
        </div>
    );
};
