// Code generated by tygo. DO NOT EDIT.
/* eslint-disable */
import { CODE, ANY_DATA, OBJECT_ID, MULTILANG_TEXT, VALUE_TYPE, COLUMN_NAME } from './generated_types';
import { LogMessage } from './generated_smart_context';
import { Meta, HandlerRun, ObjectLock } from './generated_info';

//////////
// source: requests.go

/**
 * структура чтобы только прочитать request id
 */
export interface SafeRequest {
    request_id: string; // will be returned back in response packets
}
/**
 * Base Request structure
 * TODO: добавить уровень вложенности - команды - и может быть массив команд
 */
export interface Request {
    request_id: string; // will be returned back in response packets
    access_token: string; // JWT access token for authentication
    return_log_messages: boolean; // should the server return log messages in the response
    ping?: PingRequest;
    info?: MetaRequest;
    get?: GetRequest;
    get_param?: GetParamRequest;
    save?: SaveRequest; // can be *Entity or Entities
    select?: SelectRequest;
    run?: RunRequest;
    menu?: MenuRequest;
    routes?: RoutesRequest;
    association?: AssociationRequest;
    acquire_lock?: AcquireLockRequest;
    extend_lock?: ExtendLockRequest;
    release_lock?: ReleaseLockRequest;
    logs?: LogsRequest;
}
/**
 * Specific request payload structures
 */
export interface MetaRequest {
    meta: CODE;
}
/**
 * api.get({meta: "PRODUCT", id: 1}) -> {id: 1, name: "Product 1", ...}
 */
export interface GetRequest {
    meta: CODE;
    id: OBJECT_ID; // TODO: решить - тут ID или условие сложное тоже нужно?
}
export interface Entity {
    meta: CODE;
    object: any;
}
export interface SelectResponse {
    /**
     * TODO: решить может быть тут еще мету возвращать?
     */
    objects: { [key: string]: any}[];
}
export interface SaveRequest {
    meta: CODE;
    unique_fields?: string[]; // список полей, по которым нужно искать существующие объекты (для upsert)
    /**
     * TODO: а что мы делаем с детьми которые тут не указаны? удаляем? оставляем?
     */
    objects?: any[];
}
export interface DictRequest {
    meta: CODE;
    code: CODE;
}
export interface RunRequest {
    /**
     * update: убрать поле meta - оно лишнее - нет - оно НЕ лишнее. оно нужно когда у handler нет meta! это когда handler обобщенный типа SetStatus - тогда мета приходит здесь из Action
     */
    Action_Id: OBJECT_ID; // ID действия, если оно есть. Используется чтобы проверить разрешения на выполнение действия относительно переданных объектов
    handler: CODE; // теперь это поле становится не нужным - а нужным - ActionId!
    meta: CODE; // на самом деле это не нужно - ведь у HandlerCode уже указан Meta. Может быть убрать?
    ids: string[]; // в зависимости от типа обработчика он либо будет запущен ДЛЯ КАЖДОГО объекта отдельно или для всех сразу
    args: ANY_DATA; // TODO: rename to Params?
}
export interface PingRequest {
    payload: string;
}
export interface MenuRequest {
}
export interface RoutesRequest {
}
export interface AssociationRequest {
    /**
     * код конкретной ассоциации
     */
    association: CODE;
    /**
     * ID объектов модели SourceMeta из ассоциации для которых нужно вернуть результирующие ID модели TargetMeta
     * IDs of associated objects - могут быть как int так и uuid
     */
    ids: string[];
}
export interface SelectRequest {
    meta: CODE;
    fields: string[]; // Fields to select
    filters: string; // Filters e.g., "Id=gt.10,Name=eq.John"
    sort: string; // Sorting e.g., "Name asc,Id desc"
    page: number /* int */;
    page_size: number /* int */;
}
export interface AcquireLockRequest {
    meta: CODE;
    id: OBJECT_ID; // NB! всегда заплашиваем на лок только последнюю версию! Даже если клиент не знает какая она.
    force?: boolean; // перезаписать чужой лок если он есть
}
export interface ExtendLockRequest {
    lock_id: OBJECT_ID; // ID блокировки
}
/**
 * Снимает блокировку если объект все еще был нами залочен
 */
export interface ReleaseLockRequest {
    lock_id: OBJECT_ID; // ID блокировки
}
/**
 * Запрашивает параметры с парасета
 */
export interface GetParamRequest {
    param_name: string;
    /**
     * TODO: meta_code, object_id вместо paraset_id
     */
    meta_code?: CODE;
    object_id?: OBJECT_ID;
}
export interface LogsRequest {
    request_id: string;
    filters: ANY_DATA; // json
}

//////////
// source: responses.go

export interface Response {
    session_id: string;
    request_id: string;
    status_code: StatusCode;
    error_text?: string;
    /**
     * тут только то что юзеру разрешено в настройках!
     */
    log_messages?: LogsResponse;
    ping?: PingResponse;
    info?: Meta;
    select?: SelectResponse; // массив объектов запрошенной меты с полями которые могут быть объектами и массивами
    get?: GetResponse; // тут сам объект
    get_param?: GetParamResponse;
    save?: SaveResponse; // тут возвращаем все те же отправленные на сохранение объекты но в них уже могут быть дозаполнены некоторые поля - например ID
    run?: (HandlerRun | undefined)[];
    menu?: MenuResponse;
    routes?: Route[];
    association?: AssociationResponse;
    acquire_lock?: ObjectLock;
    extend_lock?: ObjectLock;
    release_lock?: ReleaseLockResponse;
    logs?: LogsResponse;
}
export interface PingResponse {
    payload: string;
}
export interface Route {
    id: OBJECT_ID; // Unique ID (taken from nav_item.id)
    path: string; // can be with :id
    component?: string; // какой компонент нужно рендерить по этому пути - опциональное поле
    meta?: CODE; // NULLABLE. что в этот компонент нужно передавать в качестве меты чтобы он знал что дальше делать
    detail_route_id?: OBJECT_ID; // NULLABLE. если это табличная страница то тут будет ID роута с детальной страницей
    layout?: any /* info.Layout */; // NULLABLE. layout для этого роута
}
export interface SaveResponse {
    objects?: any[]; // or many objects
}
export interface MenuItem {
    id: OBJECT_ID; // Unique ID
    name: MULTILANG_TEXT; // название страницы
    icon: string; // Material-UI icon name
    parent_id?: OBJECT_ID; // Pointer to allow null values for top-level items
    child_index?: number /* int */; // порядок внутри родителя (если есть) или внутри вернхнего уровня
    is_folder: boolean; // папки или не папка (определяется по тому есть ли дети у этого пункта меню)
    /**
     * у папки путь всегда будет отсутствовать!
     */
    path: string; // URL route path
    is_hidden: boolean; // скрытый пункт меню (не показывать в меню но показывать в роутах - хотя для роутов у нас другой метод есть - так что вопрос нужно ли эту галочку - можно было бы весь пункт не возвращать на фронтенд!?)
}
export interface MenuResponse {
    items: MenuItem[];
}
export interface AssociationResponse {
    hash_key: string; // Hash Key перехода (для переиспользование табов с одним и тем же hash_key)
    ids: string[]; // IDs of associated objects - могут быть как int так и uuid (передается через state)
}
export interface GetResponse {
    /**
     * TODO: решить может быть тут еще мету возвращать? или это не нужно?
     */
    object: any;
}
/**
 * Запрашивает параметры с парасета
 */
export interface GetParamResponse {
    param_value: any /* types.ANY_TYPE */;
    param_value_type: VALUE_TYPE;
}
export interface ReleaseLockResponse {
}
export interface LogMessageResponse {
    CurrentPath: string;
    children: (LogMessageResponse | undefined)[];
    LogMessage: LogMessage;
}
export interface LogsResponse {
    created_objects?: LogMessage[];
    log_messages?: (LogMessageResponse | undefined)[];
}

//////////
// source: status.go

export type StatusCode = number /* int */;
export const StatusCodeOK: StatusCode = 200;
export const StatusCodeBadRequest: StatusCode = 400;
export const StatusCodeUnauthorized: StatusCode = 401;
export const StatusCodeForbidden: StatusCode = 403;
export const StatusCodeNotFound: StatusCode = 404;
export const StatusCodeMethodNotAllowed: StatusCode = 405;
export const StatusCodeRequestTimeout: StatusCode = 408;
export const StatusCodeConflict: StatusCode = 409;
export const StatusCodeInternalServerError: StatusCode = 500;
export const StatusCodeNotImplemented: StatusCode = 501;
export const StatusCodeBadGateway: StatusCode = 502;
export const StatusCodeServiceUnavailable: StatusCode = 503;
export const StatusCodeGatewayTimeout: StatusCode = 504;
