import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Card, Flex, List, Modal } from 'antd';
import { toJS } from 'mobx';
import { HandlerParam, MetaField } from 'modules/services/backend-api/generated_info';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { metaStore } from 'utils/store/MetaStore';

interface AllFiltersModalProps {
    meta: string;
    setData: (data: HandlerParam[]) => void;
    selectedIds: string[];
}

export const useChooseFieldsModal = ({ meta, setData, selectedIds }: AllFiltersModalProps) => {
    const [metaFields, setMetaFields] = useState<MetaField[]>([]);

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const fetchFields = () => {
            const response = toJS(metaStore.meta.get(meta)?.info);

            if (response) {
                setMetaFields(response.Fields.filter((field) => !field.IsHiddenOnDetail));
            }
        };

        if (isOpen) fetchFields();
    }, [isOpen]);

    const {
        t,
        i18n: { language }
    } = useTranslation();

    const [activeData, setActiveData] = useState<MetaField[]>([]);

    useEffect(() => {
        // Когда заново открываем хэндлер, активные фильтры убираем
        setActiveData([]);
    }, [selectedIds]);

    const allFilters = useMemo(() => {
        return metaFields?.filter(
            (field) =>
                field.IsReadOnly === false &&
                !activeData.find((active) => active.ColumnName === field.ColumnName)
        );
    }, [metaFields, activeData]);

    const removeAppliedField = useCallback((columnName: string) => {
        setActiveData((prevValue) => prevValue.filter((value) => value.ColumnName !== columnName));
    }, []);

    const handleCancel = () => {
        setActiveData([]);
        setIsOpen(false);
    };

    const handleOk = () => {
        setData(
            activeData.map((active) => ({
                IsRequested: true,
                ParamName: active.FieldName,
                ValueType: active.ValueType,
                ChildIndex: active.ChildIndex,
                Id: active.Id,
                Name: active.Name,
                ParentHandler_Code: 'MassUpdate'
            }))
        );
        setActiveData([]);
        setIsOpen(false);
    };

    return {
        openModal: () => setIsOpen(true),
        chooseFieldsModal: (
            <Modal
                open={isOpen}
                onCancel={handleCancel}
                onOk={handleOk}
                width="70%"
                destroyOnClose
                title={t('choose_fields')}
                zIndex={3000}
            >
                <Flex gap={5}>
                    <Card title={`${t('all_fields')}:`} style={{ width: '50%' }}>
                        <List
                            className="all_filters"
                            dataSource={allFilters || []}
                            itemLayout="horizontal"
                            style={{
                                height: 'calc(100vh - 24rem)',
                                paddingRight: 5,
                                overflow: 'auto'
                            }}
                            renderItem={(item) => (
                                <List.Item
                                    onClick={() => {
                                        setActiveData((prev) => [...prev, item]);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <Flex gap={15}>
                                        <PlusOutlined
                                            style={{
                                                cursor: 'pointer',
                                                color: '#3287d3'
                                            }}
                                        />
                                        {item.Name[language] || t(item.ColumnName)}
                                    </Flex>
                                </List.Item>
                            )}
                        ></List>
                    </Card>
                    <Flex
                        style={{ fontSize: 20, color: 'rgba(0, 0, 0, 0.25)' }}
                        align="center"
                        justify="center"
                        vertical
                    >
                        <span>{'>'}</span>
                        <span>{'<'}</span>
                    </Flex>
                    <Card title={`${t('active_fields')}:`} style={{ width: '50%' }}>
                        <List
                            className="active_filters"
                            dataSource={activeData || []}
                            itemLayout="horizontal"
                            style={{
                                height: 'calc(100vh - 24rem)',
                                paddingRight: 5,
                                overflow: 'auto'
                            }}
                            renderItem={(item) => (
                                <List.Item
                                    onClick={() => {
                                        removeAppliedField(item.ColumnName);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <Flex gap={15}>
                                        <MinusOutlined
                                            style={{
                                                cursor: 'pointer',
                                                color: '#fc5e53'
                                            }}
                                        />
                                        {item.Name[language] || t(item.ColumnName)}
                                    </Flex>
                                </List.Item>
                            )}
                        ></List>
                    </Card>
                </Flex>
            </Modal>
        )
    };
};
