import { CloseCircleFilled } from '@ant-design/icons';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import React, { memo, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { REGEX_REPLACE_GMT, TIME_FORMAT, localeFormats } from 'utils/helpers/dates';
import { LANGUAGES, i18n } from 'utils/i18n/i18n';

interface SmartDateFieldProps {
    value: string | null; // ISO string (from Supabase DB) aka DbDateTime
    onChange: (newValue: string | null) => void; // ISO string (from Supabase DB) aka DbDateTime
    popoverContainerHtmlId?: string;
    disabled?: boolean;
    showTime?: boolean;
    utc?: boolean; //  если utc=true Сохраняем в UTC, отображаем как есть, т.е. учитывается часовой пояс
    style?: { [key: string]: any };
    className?: string;
}
// The component is used to display and edit date and time values using user's preferred format!

// TODO: добавить новый язык и формат к нему, если требуется
export const langMasks = {
    ru: {
        date: 'DD.MM.YYYY',
        datetime: 'DD.MM.YYYY HH:mm'
    },
    en: {
        date: 'MM/DD/YYYY',
        datetime: 'MM/DD/YYYY HH:mm'
    }
};

export const SmartDateField = memo<SmartDateFieldProps>(
    ({ value, onChange, disabled, popoverContainerHtmlId, className, style, showTime, utc }) => {
        const { t } = useTranslation();
        const datePickerRef = useRef(null);
        const [localValue, setLocalValue] = useState();

        const format = showTime
            ? langMasks[i18n.language || 'en'].datetime
            : langMasks[i18n.language || 'en'].date;

        // Определение функции для рендеринга компонента выбора даты и времени
        const handleClear: React.MouseEventHandler<HTMLSpanElement> = (e) => {
            e.stopPropagation();
            onChange(null);
        };

        const handleChange = useCallback(
            (date: dayjs.Dayjs | null) => {
                setLocalValue(date);
                onChange(dayjs(date).format(showTime ? 'YYYY-MM-DDTHH:mm:ssZ' : 'YYYY-MM-DD'));
            },
            [onChange, showTime, utc]
        );

        const handleKeyPress: (event: React.KeyboardEvent<HTMLElement>) => void = (e) => {
            if (['Enter', 'Tab'].includes(e.key)) {
                if (!e.target.value) {
                    onChange(null);
                    return;
                }

                const date = dayjs(
                    e.target.value,
                    localeFormats[(i18n.language as Exclude<LANGUAGES, 'tech'>) || 'en'].formats.L,
                    true
                ).locale(i18n.language);

                if (date.isValid()) {
                    handleChange(date);
                }
            }
        };

        // Рендеринг базового компонента поля ввода с переданными пропсами и определенными функциями рендеринга компонентов выбора и просмотра даты и времени
        return (
            <DatePicker
                ref={datePickerRef}
                placeholder={t('no_value') as string}
                value={value ? dayjs(value.replace(REGEX_REPLACE_GMT, '').replace('Z', '')) : null}
                onChange={handleChange}
                onBlur={(e) =>
                    handleKeyPress({
                        target: { value: e.target.value || setLocalValue },
                        key: 'Enter'
                    })
                }
                format={{ format, type: 'mask' }}
                allowClear={{ clearIcon: <CloseCircleFilled onClick={handleClear} /> }}
                showTime={showTime ? { format: TIME_FORMAT } : false}
                className={className}
                style={style}
                disabled={disabled}
                onKeyDown={handleKeyPress}
                getPopupContainer={
                    popoverContainerHtmlId
                        ? () => document.getElementById(popoverContainerHtmlId) as HTMLElement
                        : undefined
                }
            />
        );
    }
);
