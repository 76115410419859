// ServerApi.ts
import { supabaseClient } from 'modules/supabase/contexts/SupabaseContext/SupabaseContext';
import { makeErrorReadable } from 'utils/helpers/makeErrorReadable';
import WebSocketService from './WebSocketService';
import {
    AcquireLockRequest,
    AssociationRequest,
    ExtendLockRequest,
    GetParamRequest,
    GetRequest,
    LogsRequest,
    MetaRequest,
    MetasRequest,
    NotificationResponse,
    ReleaseLockRequest,
    Response,
    RunRequest,
    SaveRequest,
    SelectRequest,
    StatusCodeOK
} from './generated_api';
import { CODE } from './generated_types';

// Define the extended interface with additional specific properties
export interface GetParamRequestWithDefault extends GetParamRequest {
    default_value?: any; // Add or override specific properties here
}

export type ProcessNotificationFn = (notification: NotificationResponse) => void;

export class ServerApi {
    public channel: WebSocketService | null = null;

    private token: string = '';

    isConnected: boolean = false;

    private connectionPromise: Promise<void> | null = null;

    constructor(url: string) {
        console.log('ServerApi: constructor');
        if (!this.channel) {
            this.channel = new WebSocketService(url);
            this.checkAndRefreshToken();
        }
    }

    private checkAndRefreshToken = async () => {
        if (supabaseClient.auth) {
            const { data, error } = await supabaseClient.auth.getSession();
            if (error) {
                throw error;
            }

            if (data) {
                const session = data.session;
                if (!session) {
                    if (
                        window.location.pathname.includes('/login') ||
                        window.location.pathname.includes('/signup')
                    ) {
                        return;
                    }

                    throw new Error(makeErrorReadable('Нет сессии'));
                }
                this.token = session.access_token;
            } else {
                throw new Error(makeErrorReadable('Данные для определения сессии пусты'));
            }
        } else {
            const supabaseKey = process.env.SUPABASE_SERVICE_ROLE_KEY;
            this.token = supabaseKey || '';
        }

        if (!this.isConnected || this.channel?.getWs()?.readyState === WebSocket.CLOSED) {
            await this.connect();
        }
    };

    connect = async (): Promise<void> => {
        if (this.connectionPromise) {
            return this.connectionPromise; // Return existing promise if connection is in progress
        }

        if (!this.channel) {
            return Promise.reject(new Error('WebSocket channel is not initialized.'));
        }

        // Ensure that connect() always returns a Promise<void>
        this.connectionPromise = this.channel
            .connect()
            .then(() => {
                this.isConnected = true;
                this.connectionPromise = null; // Reset promise after connection is established
            })
            .catch((err) => {
                console.error('Failed to connect WebSocket:', err);
                this.connectionPromise = null; // Reset promise on failure
                throw err; // Re-throw error to propagate it up the call stack
            });

        return this.connectionPromise;
    };

    routes = async () => {
        await this.checkAndRefreshToken();

        const result = await this.channel?.sendRequest({
            access_token: this.token,
            routes: {}
        });

        if (result && result.status_code === StatusCodeOK && result.routes) {
            return result.routes;
        }

        const error = new Error(
            `Routes returned error: ${result?.error_text}. RequestId: ${result?.request_id}`
        );
        console.error(error.message);
        return error;
    };

    menu = async () => {
        await this.checkAndRefreshToken();

        const result = await this.channel?.sendRequest({
            access_token: this.token,
            menu: {}
        });

        if (result && result.status_code === StatusCodeOK && result.menu) {
            return result.menu;
        }

        const error = new Error(
            `Menu returned error: ${result?.error_text}. RequestId: ${result?.request_id}`
        );
        console.error(error.message);
        return error;
    };

    infos = async (options: MetasRequest) => {
        await this.checkAndRefreshToken();

        const result = await this.channel?.sendRequest({
            access_token: this.token,
            infos: options
        });

        if (result && result.status_code === StatusCodeOK && result.infos) {
            return result.infos;
        }

        const error = new Error(
            `Info returned error: ${result?.error_text}. RequestId: ${result?.request_id}`
        );
        console.error(error.message);
        return error;
    };

    info = async (options: MetaRequest) => {
        await this.checkAndRefreshToken();

        const result = await this.channel?.sendRequest({
            access_token: this.token,
            info: options
        });

        if (result && result.status_code === StatusCodeOK && result.info) {
            return result.info;
        }

        const error = new Error(
            `Info returned error: ${result?.error_text}. RequestId: ${result?.request_id}`
        );
        console.error(error.message);
        return error;
    };

    get = async (options: GetRequest) => {
        await this.checkAndRefreshToken();

        const result = await this.channel?.sendRequest({
            access_token: this.token,
            get: options
        });

        if (result && result.status_code === StatusCodeOK && result.get) {
            return result.get;
        }

        const error = new Error(
            `Get returned error: ${result?.error_text}. RequestId: ${result?.request_id}`
        );
        console.error(error.message);
        return error;
    };

    get_param = async (options: GetParamRequestWithDefault) => {
        await this.checkAndRefreshToken();

        const { default_value, ...restOptions } = options;

        const result = await this.channel?.sendRequest({
            access_token: this.token,
            get_param: restOptions // omitted default_value
        });

        if (result && result.status_code === StatusCodeOK && result.get_param) {
            return result.get_param.param_value;
        }

        // Handle error - use default value
        // Log the warning with the default value
        console.warn(
            `GetParam failed. Using default value: ${JSON.stringify(default_value)}. RequestId: ${
                result?.request_id
            }`
        );
        return default_value;
    };

    save = async (options: SaveRequest) => {
        await this.checkAndRefreshToken();
        const paramRequest = {
            access_token: this.token,
            save: options
        };

        const result = await this.channel?.sendRequest(paramRequest);

        if (result && result.status_code === StatusCodeOK && result.save) {
            return result.save;
        }

        const error = new Error(
            `Save returned error: ${result?.error_text}. RequestId: ${result?.request_id}`
        );
        console.error(error.message);
        return error;
    };

    run = async (options: RunRequest) => {
        await this.checkAndRefreshToken();
        const paramRequest = {
            access_token: this.token,
            run: options
        };

        const result = await this.channel?.sendRequest(paramRequest);

        if (result && result.status_code === StatusCodeOK) {
            console.log('Run result:', result);
            return result;
        }

        const error = new Error(
            `Run returned error: ${result?.error_text}. RequestId: ${result?.request_id}`
        );
        console.error(error.message);
        return result;
    };

    association = async (options: AssociationRequest) => {
        await this.checkAndRefreshToken();
        const paramRequest = {
            access_token: this.token,
            association: options
        };

        const result = await this.channel?.sendRequest(paramRequest);

        if (result && result.status_code === StatusCodeOK && result.association) {
            return result.association;
        }

        const error = new Error(
            `Association returned error: ${result?.error_text}. RequestId: ${result?.request_id}`
        );
        console.error(error.message);
        return error;
    };

    select = async (options: { meta: CODE } & Partial<Omit<SelectRequest, 'meta'>>) => {
        await this.checkAndRefreshToken();
        const paramRequest = {
            access_token: this.token,
            select: options
        };

        const result = await this.channel?.sendRequest(paramRequest);

        if (result && result.status_code === StatusCodeOK && result.select) {
            return result.select;
        }

        const error = new Error(
            `Select returned error: ${result?.error_text}. RequestId: ${result?.request_id}`
        );
        console.error(error.message);
        return error;
    };

    acquireLock = async (options: AcquireLockRequest) => {
        await this.checkAndRefreshToken();
        const paramRequest = {
            access_token: this.token,
            acquire_lock: options
        };

        const result = await this.channel?.sendRequest(paramRequest);

        if (result && result.status_code === StatusCodeOK && result.acquire_lock) {
            return result.acquire_lock;
        }

        const error = new Error(
            `AcquireLock returned error: ${result?.error_text}. RequestId: ${result?.request_id}`
        );
        console.error(error.message);
        return error;
    };

    extendLock = async (options: ExtendLockRequest) => {
        await this.checkAndRefreshToken();
        const paramRequest = {
            access_token: this.token,
            extend_lock: options
        };

        const result = await this.channel?.sendRequest(paramRequest);

        if (result && result.status_code === StatusCodeOK && result.extend_lock) {
            return result.extend_lock;
        }

        const error = new Error(
            `ExtendLock returned error: ${result?.error_text}. RequestId: ${result?.request_id}`
        );
        console.error(error.message);
        return error;
    };

    releaseLock = async (options: ReleaseLockRequest) => {
        await this.checkAndRefreshToken();
        const paramRequest = {
            access_token: this.token,
            release_lock: options
        };

        const result = await this.channel?.sendRequest(paramRequest);

        if (result && result.status_code === StatusCodeOK && result.release_lock) {
            return result.release_lock;
        }

        const error = new Error(
            `ReleaseLock returned error: ${result?.error_text}. RequestId: ${result?.request_id}`
        );
        console.error(error.message);
        return error;
    };

    logs = async (options: LogsRequest) => {
        await this.checkAndRefreshToken();
        const paramRequest = {
            access_token: this.token,
            logs: options
        };

        const result = await this.channel?.sendRequest(paramRequest);

        if (result && result.status_code === StatusCodeOK && result.logs) {
            return result.logs;
        }

        const error = new Error(
            `Logs returned error: ${result?.error_text}. RequestId: ${result?.request_id}`
        );
        console.error(error.message);
        return error; // done: надо здесь и везде выше решить что возвращать в таких случаях. Ничего нельзя - потребитель же ждет результат!
    };

    subscribeNotifications = async (process: ProcessNotificationFn) => {
        await this.checkAndRefreshToken();

        const paramRequest = {
            access_token: this.token,
            subscribe_notifications: {}
        };

        const internalProcess = (response: Response) => {
            const notification = response.notification;
            if (!notification) {
                console.error('subscribeNotifications: No notification in response:', response);
                return;
            }

            process(notification);
        };

        return this.channel?.subscribe(paramRequest, internalProcess);
    };
}
