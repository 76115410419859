import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { supabaseClient } from 'modules/supabase/contexts/SupabaseContext/SupabaseContext';
import { TableName } from 'modules/supabase/types/Dataset';

const initialStore = new Map<TableName, Record<string, Record<string, any> | undefined>>();

export class DetailPageStore {
    info = initialStore;

    constructor() {
        makeAutoObservable(this);
        // makePersistable(this, {
        //     name: 'DetailPageStore',
        //     properties: ['info'],
        //     storage: window.sessionStorage
        // });
    }

    fecthDetailPageInfo = async (tableName: TableName, id: string) => {
        return runInAction(async () => {
            let type_info = {};
            let response: PostgrestSingleResponse<{
                key: any;
                type_code: any;
                parent_type_code: any;
                type_short_title: any;
                type_long_title: any;
                lifecycle_status_group_id: any;
            }>;

            if (tableName === 'docs_transportation_tasks') {
                response = await supabaseClient
                    .from(`v${tableName}`)
                    .select(
                        'key, type_code, parent_type_code, type_short_title, type_long_title, lifecycle_status_group_id'
                    )
                    .eq('id', id)
                    .single();
            } else {
                response = await supabaseClient
                    .from(`v${tableName}`)
                    .select(
                        'key, type_code, type_short_title, type_long_title, lifecycle_status_group_id'
                    )
                    .eq('id', id)
                    .single();
            }

            const { data, error } = response;

            console.log(data);

            if (error) throw error;

            if (data) {
                type_info = {
                    code: data.type_code,
                    parent_code: data.parent_type_code,
                    long_title: data.type_long_title,
                    short_title: data.type_short_title,
                    ...data
                };
                console.log(type_info);

                const currentDetailInfo = this.info.get(tableName);

                if (currentDetailInfo) {
                    this.info.set(tableName, { ...currentDetailInfo, [id]: type_info });
                } else {
                    this.info.set(tableName, { [id]: type_info });
                }
            }

            return type_info;
        });
    };

    getDetailPageInfo = (tableName: TableName, id: string) => {
        return runInAction(() => {
            const currentDetailInfo = this.info.get(tableName);

            if (currentDetailInfo) {
                return currentDetailInfo[id];
            }

            return undefined;
        });
    };

    setDetailPageCreateInfo = (tableName: TableName, typeInfo: Record<string, any> | undefined) => {
        return runInAction(() => {
            const currentDetailInfo = this.info.get(tableName);

            if (currentDetailInfo) {
                this.info.set(tableName, { ...currentDetailInfo, create: typeInfo });
            } else {
                this.info.set(tableName, { create: typeInfo });
            }
        });
    };

    clear = () => {
        runInAction(() => {
            this.info.clear();
        });
    };

    removeByTableName = (tableName: TableName) => {
        runInAction(() => {
            this.info.delete(tableName);
        });
    };

    removeById = (tableName: TableName, id: string) => {
        runInAction(() => {
            const currentDetailInfo = this.info.get(tableName);

            if (currentDetailInfo) {
                this.info.set(tableName, { ...currentDetailInfo, [id]: undefined });
            }
        });
    };

    removeCreateInfo = (tableName: TableName) => {
        runInAction(() => {
            const currentDetailInfo = this.info.get(tableName);

            if (currentDetailInfo) {
                this.info.set(tableName, { ...currentDetailInfo, create: undefined });
            }
        });
    };
}

export const detailPageStore = new DetailPageStore();
