import { VALUE_TYPE } from 'modules/services/backend-api/generated_info';

export const isRangeType = (type: VALUE_TYPE | undefined): boolean => {
    if (!type) return false;

    if (
        type.includes('daterange') ||
        type.includes('datetimerange') ||
        type.includes('datetime_range') ||
        type.includes('datetimelocalrange') ||
        type.includes('timerange')
    ) {
        return true;
    }
    return false;
};
