import { toJS } from 'mobx';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useList } from 'react-use';

import { Meta, MetaField } from 'modules/services/backend-api/generated_info';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';

export const useSelectData = ({ meta, filters }: { meta: string; filters: string }) => {
    const initialMetaSource = metaStore.meta.get(meta)?.info;

    const { t, i18n } = useTranslation();
    const [metaSource, setMetaSource] = useState<Meta | undefined>(initialMetaSource);
    const [dataSource, dataSourceMethods] = useList<IObjectWithId>([]);

    const [isLoading, setIsLoading] = useState(false);

    const [modalTitle, setModalTitle] = useState('');
    const [modalFields, setModalFields] = useState<MetaField[]>([]);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        let metaInfo = toJS(metaStore.meta.get(meta)?.info);
        if (!metaInfo) metaInfo = await metaStore.getInfo(meta);

        const fields = metaInfo?.Fields || [];
        const sortOrder = metaInfo?.SortOrder;

        setMetaSource(metaInfo);
        setModalFields(fields);

        if (metaInfo) {
            setModalTitle(
                metaInfo.PluralName
                    ? metaInfo.PluralName[i18n.language] || (t(metaInfo.TableName) as string)
                    : (t(metaInfo.TableName) as string)
            );
        }

        const metaSelect = await metaStore.makeSelect({
            meta,
            filters,
            sort: sortOrder
        });

        setIsLoading(false);

        dataSourceMethods.set(
            metaSelect?.objects.filter((item) => {
                if (typeof item.IsActive === 'boolean') {
                    return item.IsActive;
                }
                return true;
            }) || []
        );

        return metaSelect?.objects;
    }, [filters, i18n.language, meta, t]);

    return {
        fetchData,
        dataSource,
        insertAtDataSource: dataSourceMethods.insertAt,
        metaSource,
        modalTitle,
        modalFields,
        isLoading
    };
};
