import { Gantt, GanttProps, Task, ViewMode } from 'gantt-task-react';
import 'gantt-task-react/dist/index.css';
import { observer } from 'mobx-react-lite';
import { useList } from 'react-use';
import { useCallback, useMemo, useState } from 'react';
import { Card, Descriptions, Modal, Segmented } from 'antd';
import { useTranslation } from 'react-i18next';

import './GanttDiagram.scss';
import { isNull } from 'is-lite/exports';

const tasksInitial: Task[] = [
    {
        start: new Date(),
        end: new Date(new Date().setDate(new Date().getDate() + 2)),
        name: 'Idea',
        id: 'Task 0',
        type: 'task',
        progress: 45
        // isDisabled: true
        // styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' }
    },
    {
        start: new Date(2024, 8, 21),
        end: new Date(2024, 9, 2),
        name: 'Work',
        id: 'Task 1',
        type: 'project',
        progress: 90,
        project: 'ITVectura'
        // isDisabled: true
        // styles: { progressColor: '#6bff54', progressSelectedColor: '#39ff0d' }
    },
    {
        start: new Date(2024, 8, 21),
        end: new Date(2024, 9, 2),
        name: 'Mile',
        id: 'Task 2',
        type: 'milestone',
        progress: 100,
        project: 'ITVectura'
        // isDisabled: true,
        // styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' }
    }
];

export const GanttDiagram = observer(() => {
    const {
        t,
        i18n: { language }
    } = useTranslation();

    const [tasks, tasksMethods] = useList(tasksInitial);
    const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Day);
    const [openedTask, setOpenedTask] = useState<Task | null>(null);

    // NOTE: Object.keys() для числового enum можно использовать
    const viewModeOptions = useMemo(
        () => [
            ...Object.values(ViewMode).map((mode) => ({
                value: mode,
                label: t(mode.toLowerCase())
            }))
        ],
        [t]
    );

    // в аргументах летит информация об элементе диаграммы Ганта
    const handleClick = useCallback<NonNullable<GanttProps['onClick']>>((...args) => {
        console.log('Gantt Click!', args);
    }, []);

    const handleSelect = useCallback<NonNullable<GanttProps['onSelect']>>((...args) => {
        console.log('Gantt Select!', args);
    }, []);

    const handleDoubleClick = useCallback<NonNullable<GanttProps['onDoubleClick']>>((...args) => {
        console.log('Gantt Double Click!', args);
        setOpenedTask(args[0]);
    }, []);

    const handleDelete = useCallback<NonNullable<GanttProps['onDelete']>>((...args) => {
        console.log('Gantt Task Deleted!', args);
    }, []);

    const handleDateChange = useCallback<NonNullable<GanttProps['onDateChange']>>((...args) => {
        console.log('Gantt Date Changed!', args);
        tasksMethods.update((a, b) => a.id === args[0].id, args[0]);
    }, []);

    const handleProgressChange = useCallback<NonNullable<GanttProps['onProgressChange']>>(
        (...args) => {
            console.log('Gantt Progress Changed!', args);
            tasksMethods.update((a, b) => a.id === args[0].id, args[0]);
        },
        []
    );

    const handleExpanderClick = useCallback<NonNullable<GanttProps['onExpanderClick']>>(
        (...args) => {
            console.log('Gantt Expander Click!', args);
        },
        []
    );

    return (
        <Card
            className={'gantt_diagram'}
            classNames={{
                body: 'gantt_diagram__body',
                header: 'gantt_diagram__header',
                extra: 'gantt_diagram__toolbar'
            }}
            extra={
                <Segmented
                    style={{ width: '100%' }}
                    block
                    id="timeline_table_display_mode"
                    options={viewModeOptions}
                    value={viewMode}
                    onChange={setViewMode}
                />
            }
        >
            <Gantt
                tasks={tasks}
                viewMode={viewMode}
                onClick={handleClick}
                onSelect={handleSelect}
                onDateChange={handleDateChange}
                onDelete={handleDelete}
                onProgressChange={handleProgressChange}
                onDoubleClick={handleDoubleClick}
                onExpanderClick={handleExpanderClick}
                locale={language}
            />

            <Modal
                open={!isNull(openedTask)}
                onCancel={() => setOpenedTask(null)}
                onClose={() => setOpenedTask(null)}
                onOk={() => setOpenedTask(null)}
                centered
                title={openedTask?.name}
                width={'60vw'}
            >
                <Descriptions
                    bordered
                    column={2}
                    items={Object.entries(openedTask ?? {}).map(([key, value]) => {
                        return {
                            key,
                            label: key,
                            children: value?.toString()
                        };
                    })}
                />
            </Modal>
        </Card>
    );
});
