import _ from 'lodash';
import React, { useCallback, useReducer } from 'react';
import { DictContext, DictContextReducer, getDictByRequestType } from './DictsContext';

export const DictsContextProvider = ({ children }: React.PropsWithChildren) => {
    const [state, dispatch] = useReducer(DictContextReducer, []);

    // Функция возвращаем словарь по запросу, используется в useSupabaseViewOrTableData
    const getDictByRequest = useCallback<getDictByRequestType>(
        ({ dictViewName, filters, orderBy, pageNumber, pageSize, virtualization }) => {
            // Ищим данные по запросу
            const alreadyLoadedDictIndex = state.findIndex((data) =>
                _.isEqual(data.request, {
                    dictViewName,
                    filters,
                    orderBy,
                    pageNumber,
                    pageSize,
                    virtualization
                })
            );

            // Если нашли - возвращаем
            if (alreadyLoadedDictIndex === 0 || alreadyLoadedDictIndex > 0) {
                const dict = state[alreadyLoadedDictIndex];

                return {
                    data: dict.data,
                    error: dict.error
                };
            }

            return null;
        },
        [state]
    );

    return (
        <DictContext.Provider value={{ state, dispatch, getDictByRequest }}>
            {children}
        </DictContext.Provider>
    );
};
