import { CloseCircleFilled } from '@ant-design/icons';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { DATE_FORMAT, localeFormats } from 'utils/helpers/dates';
import { i18n, LANGUAGES } from 'utils/i18n/i18n';
import { langMasks } from '../SmartDateField/SmartDateField';

type AntdRangeValue<T> = [T | null, T | null];

type DateRangeValue = {
    FromDate: string | null;
    ToDate: string | null;
};

interface SmartDateRangeFieldProps {
    value: DateRangeValue | null; // ISO string (from Supabase DB) aka DbDateTime
    onChange: (newValue: DateRangeValue | null) => void; // ISO string (from Supabase DB) aka DbDateTime
    popoverContainerHtmlId?: string;
    disabled?: boolean;
    showTime?: boolean;
    utc?: boolean; //  если utc=true Сохраняем в UTC, отображаем как есть, т.е. учитывается часовой пояс
    style?: { [key: string]: any };
    className?: string;
}
// The component is used to display and edit date and time values using user's preferred format!

export const SmartDateRangeField = memo<SmartDateRangeFieldProps>(
    ({ value, onChange, disabled, popoverContainerHtmlId, className, style, showTime, utc }) => {
        const { t } = useTranslation();

        const [focusedField, setFocusedField] = React.useState<'FromDate' | 'ToDate' | null>(null);
        const [antdValue, setAntdValue] = React.useState<AntdRangeValue<Dayjs> | null>([
            value?.FromDate ? dayjs(value.FromDate) : null,
            value?.ToDate ? dayjs(value.ToDate) : null
        ]);
        // Определение функции для рендеринга компонента выбора даты и времени
        const handleClear: React.MouseEventHandler<HTMLSpanElement> = (e) => {
            e.stopPropagation();
            onChange(null);
        };

        const handleChange = useCallback(
            (dates: [Dayjs | null, Dayjs | null] | null) => {
                const [FromDate, ToDate] = dates || [null, null];
                setAntdValue(dates);
                onChange({
                    FromDate: FromDate ? FromDate.format(DATE_FORMAT) : null,
                    ToDate: ToDate ? ToDate.format(DATE_FORMAT) : null
                });
            },
            [onChange, value]
        );

        const format = langMasks[i18n.language || 'en'].date;

        const handleFocus = (inputType: 'FromDate' | 'ToDate') => {
            setFocusedField(inputType);
        };

        const handleKeyPress: (event: React.KeyboardEvent<HTMLElement>) => void = (e) => {
            const inputElement = e.target as HTMLInputElement;
            const inputElementValue = inputElement.value || '';
            if (['Enter', 'Tab'].includes(e.key)) {
                const date = dayjs(
                    inputElementValue,
                    localeFormats[(i18n.language as Exclude<LANGUAGES, 'tech'>) || 'en'].formats.L,
                    true
                ).locale(i18n.language);

                if (date.isValid()) {
                    const newAntdValue = [
                        ...(antdValue || [null, null])
                    ] as AntdRangeValue<Dayjs | null>;
                    if (focusedField === 'FromDate') {
                        newAntdValue[0] = date;
                    } else if (focusedField === 'ToDate') {
                        newAntdValue[1] = date;
                    }
                    setAntdValue(newAntdValue);
                    handleChange(newAntdValue);
                }
            }
        };
        // Рендеринг базового компонента поля ввода с переданными пропсами и определенными функциями рендеринга компонентов выбора и просмотра даты и времени
        return (
            <DatePicker.RangePicker
                placeholder={[t('no_value') as string, t('no_value') as string]}
                value={antdValue}
                onChange={handleChange}
                format={{ format, type: 'mask' }}
                allowClear={{ clearIcon: <CloseCircleFilled onClick={handleClear} /> }}
                showTime={showTime}
                className={className}
                onFocus={(e) => {
                    const inputElement = e.target as HTMLInputElement;
                    const dateRangeAttr = inputElement.getAttribute('date-range');
                    handleFocus(dateRangeAttr === 'start' ? 'FromDate' : 'ToDate');
                }}
                onBlur={(e) =>
                    handleKeyPress({
                        target: { value: e.target.value },
                        key: 'Enter'
                    })
                }
                onKeyDown={handleKeyPress}
                style={style}
                disabled={disabled}
                getPopupContainer={
                    popoverContainerHtmlId
                        ? () => document.getElementById(popoverContainerHtmlId) as HTMLElement
                        : undefined
                }
            />
        );
    }
);
