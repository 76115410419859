export const FILE_BUCKET = window.env.SUPABASE_BUCKET;

export const SqlStateErrorCodes = [
    'SQLSTATE(23503)',
    'SQLSTATE 23503',
    'SQLSTATE(23505)',
    'SQLSTATE 23505',
    'P0001'
];

export const STATUS_MARKERS: { [key: string]: React.CSSProperties } = {
    ACTIVE: { color: '#09A953', backgroundColor: '#DBF6EB' },
    ACCEPTED: { color: '#09A953', backgroundColor: '#DBF6EB' },
    APPROVED: { color: '#09A953', backgroundColor: '#DBF6EB' },
    CREATED: { color: '#3185D2', backgroundColor: '#E0EDF8' },
    DELETED: { color: '#D71A21', backgroundColor: '#F7D1D3' },
    CLOSED_MANUALLY: { color: '#09A953', backgroundColor: '#DBF6EB' },
    CLOSED_AUTOMATICALLY: { color: '#09A953', backgroundColor: '#DBF6EB' },
    CLOSED: { color: '#A9B3BC', backgroundColor: '#EEF0F2' },
    // other statuses, not are lifecycle
    NOT_VERIFY: { color: '#A9B3BC', backgroundColor: '#EEF0F2' },
    NOT_APPROVED: { color: '#FF0009', backgroundColor: '#FFE6E6' },
    APPROVAL_REQUIRED: { color: '#FFB800', backgroundColor: '#FFF8E7' },
    NOT_REQUIRED_APPROVAL: { color: '#14AD6D', backgroundColor: '#E6F5EF' },
    ROUTE_APPROVED: { color: '#14AD6D', backgroundColor: '#E6F5EF' },
    NOT_RELEVANT: { color: '#A9B3BC', backgroundColor: '#EEF0F2' },
    THERE_ACTIVE: { color: '#FFB800', backgroundColor: '#FFF8E7' },
    THEY_CLOSED: { color: '#09A953', backgroundColor: '#DBF6EB' },
    // правки по задаче 8693d2tmw
    BOOKED: { color: '#09A953', backgroundColor: '#DBF5EA' },
    UNBOOKED: { color: '#D71A21', backgroundColor: '#F7D1D3' },
    SCHEDULED_PARTIALLY: { color: '#D71A21', backgroundColor: '#F7D1D3' },
    SCHEDULED: { color: '#09A953', backgroundColor: '#DBF5EA' },
    ASSIGNED_PARTIALLY: { color: '#D71A21', backgroundColor: '#F7D1D3' },
    ASSIGNED: { color: '#EDBB3B', backgroundColor: '#FEFBF5' },
    PLANNED: { color: '#09A953', backgroundColor: '#DBF6EB' },
    PLANNED_PARTIALLY: { color: '#EDBB3B', backgroundColor: '#FEFBF5' },
    PLANNING_STARTED: { color: '#EDBB3B', backgroundColor: '#FEFBF5' },
    IN_PLANNING: { color: '#A9B3BC', backgroundColor: '#EEF0F2' },
    CLOSED_LOT: { color: '#D71A21', backgroundColor: '#F7D1D3' },
    // rfq
    FORMED_LOT: { color: '#A9B3BC', backgroundColor: '#EEF0F2' },
    PUBLISHED_LOT: { color: '#FFB800', backgroundColor: '#FFF8E7' },
    DECISION_LOT: { color: '#09A953', backgroundColor: '#DBF6EB' },
    // ROUND STATUSES
    FORMED_ROUND: { color: '#A9B3BC', backgroundColor: '#EEF0F2' },
    PUBLISHED_ROUND: { color: '#FFB800', backgroundColor: '#FFF8E7' },
    COPLETE_PLANNED_ROUND: { color: '#09A953', backgroundColor: '#DBF6EB' },
    COPLETE_UNPLANNED_ROUND: { color: '#09A953', backgroundColor: '#DBF6EB' },
    CANCELED_ROUND: { color: '#FF0009', backgroundColor: '#FFE6E6' },
    CLOSED_ROUND: { color: '#A9B3BC', backgroundColor: '#EEF0F2' },
    // result_round_statuses
    WAITING_LOT: { color: '#A9B3BC', backgroundColor: '#EEF0F2' },
    WAITING_DECISION_LOT: { color: '#FFB800', backgroundColor: '#FFF8E7' },
    DECISION_LOT_MADE: { color: '#09A953', backgroundColor: '#DBF6EB' },
    WINNER_LOT: { color: '#09A953', backgroundColor: '#DBF6EB' },
    // response_statuses
    AWAITING_RESPONCE: { color: '#A9B3BC', backgroundColor: '#EEF0F2' },
    REJECTED_RESPONCE: { color: '#FF0009', backgroundColor: '#FFE6E6' },
    RECIVEED_RESPONCE: { color: '#09A953', backgroundColor: '#DBF6EB' },
    // execution
    EXECUTED: { color: '#14AD6D', backgroundColor: '#E6F5EF' },
    INTRANSIT: { color: '#FFB800', backgroundColor: '#FFF8E7' },
    DEPARTURE: { color: '#FFB800', backgroundColor: '#FFF8E7' },
    NOT_STARTED: { color: '#A9B3BC', backgroundColor: '#EEF0F2' },
    READY_FOR_EXECUTION: { color: '#A9B3BC', backgroundColor: '#EEF0F2' },
    START_OF_LOADING: { color: '#A9B3BC', backgroundColor: '#EEF0F2' },
    FINISH_OF_LOADING: { color: '#A9B3BC', backgroundColor: '#EEF0F2' },
    START_OF_UNLOADING: { color: '#A9B3BC', backgroundColor: '#EEF0F2' },
    FINISH_OF_UNLOADING: { color: '#A9B3BC', backgroundColor: '#EEF0F2' }
};

export const FLOW_MARKERS: { [key: string]: React.CSSProperties } = {
    IN: { color: '#3185D2' },
    OUT: { color: '#14AD6D' }
};
