import { Meta } from 'modules/services/backend-api/generated_info';

import { getNestedValueFromObject } from './getNestedValueFromObject';
import { isFunction } from 'is-lite/exports';
import { UserSpecificFormat } from 'utils/helpers/dates';

type ColumnOptionsRefType = { meta: string; fieldName?: string };

interface Context {
    root?: any;
    current?: any;
    self?: any;
    info?: Meta;
}

export type ColumnOptionsType = {
    ref?: ColumnOptionsRefType;
    default?: number | string | boolean;
    filters?: string;
    fields?: string[];
    path?: string;
    file_name?: string;
    bucket?: string;
    group?: string;
    display?: 'inline' | 'dialog';
    is_root_id?: boolean;
    json_type?: string;
    value?: string;
    language?: string;
    [key: string]: any; // TODO: а зачем мы это поддерживаем? Может, лучше убрать и не создавать рисков с неожиданными ключами?
};

// Helper function to retrieve the function by name
const getFunctionByName = (name: string) => {
    const functionsMap: { [key: string]: Function } = {
        toLocalDateTime: (value: any, language: string) => {
            // Example implementation for toLocalDateTime
            // return new Date(value).toLocaleString();
            return UserSpecificFormat.convertFromDbDateTimeLocalToUiDateTime(value, language);
        }
        // Add more functions here as needed
    };

    return functionsMap[name];
};

export const parseTemplate = (template: string, language: string, contextData: Context) => {
    if (template.includes('{{') && template.includes('}}')) {
        // const regex = /{{\s*([\w.]+)\s*}}/g;
        // const regex = /{{\s*(\w+)?\s*([\w.]+)\s*}}/g;
        // const regex = /{{\s*(\w+)?\s*([\w.]+)\s*}}/;
        // const regex = /{{\s*(\w+)?\.?([\w.]+)\s*}}/g;
        // const regex = /{{\s*(\w+)?\.?((?:\w+\.)*\w+)\s*}}/g;
        // const regex = /{{\s*(?:(\w+)\.)?((?:\w+\.)*\w+)\s*}}/g;
        // const regex = /{{\s*(\w+)\s+((?:\w+\.)*\w+)\s*}}/g;
        const regex = /{{\s*(?:(\w+)\s+)?((?:\w+\.)*\w+)\s*}}/g;

        return template.replace(regex, (match, funcName, path) => {
            const objectPath = path
                ? path
                      .replace('Current.Root.', 'Current.root.') // case 1
                      .replace('Root.', '')
                      .replace('Current.', '')
                      .replace('Self.', '')
                      .replace('Info.', '')
                      .replace('root.', 'Root.') // case 1
                : '';

            let nestedValue;

            if (path?.includes('Root') && contextData.root) {
                nestedValue = getNestedValueFromObject(contextData.root, objectPath, language);
            } else if (path?.includes('Current') && contextData?.current) {
                nestedValue = getNestedValueFromObject(contextData.current, objectPath, language);
            } else if (path?.includes('Self') && contextData?.self) {
                nestedValue = getNestedValueFromObject(contextData.self, objectPath, language);
            } else if (path?.includes('Info') && contextData?.info) {
                nestedValue = getNestedValueFromObject(contextData.info, objectPath, language);
            } else {
                nestedValue = getNestedValueFromObject(contextData.current, objectPath, language);
            }

            if (funcName) {
                try {
                    const func = getFunctionByName(funcName);
                    if (isFunction(func)) return func(nestedValue, language);
                } catch (error) {
                    console.error(`Error applying function ${funcName}:`, error);
                }
            }

            return nestedValue;
        });
    }

    return template;
};

export const parseValueType = (valueType: string, language: string, contextData?: Context) => {
    const [type, ...optionParts] = valueType.split(';');

    const options: ColumnOptionsType = optionParts.reduce<ColumnOptionsType>((acc, part) => {
        if (part.startsWith('ref:')) {
            const [meta, fieldName] = part.replace('ref:', '').split('.');
            acc.ref = { meta, fieldName };
        } else if (part.startsWith('default:')) {
            const value = part.replace('default:', '');
            acc.default = Number.isNaN(Number(value)) ? value : Number(value);
        } else if (part.startsWith('json_type:')) {
            const value = part.replace('json_type:', '');
            acc.json_type = value;
        } else if (part.startsWith('filters:')) {
            acc.filters = part.replace('filters:', '');

            if (contextData) {
                const parsedValue = parseTemplate(acc.filters, language, contextData);

                if (parsedValue !== acc.filters) {
                    acc.filters = parsedValue.replaceAll('undefined', 'null');
                }
            }
        } else if (part.startsWith('value:')) {
            acc.value = part.replace('value:', '');

            if (contextData) {
                const parsedValue = parseTemplate(acc.value, language, contextData);
                acc.value = parsedValue === 'undefined' ? undefined : parsedValue;
            }
        } else if (part.startsWith('file_name:')) {
            acc.file_name = part.replace('file_name:', '');

            if (contextData) {
                const parsedValue = parseTemplate(acc.file_name, language, contextData);
                acc.file_name = parsedValue.replaceAll('undefined', '');
            }
        } else if (part.startsWith('path:')) {
            acc.path = part.replace('path:', ''); // TODO: прикрутить тут шаблон
        } else if (part.startsWith('language:')) {
            acc.language = part.replace('language:', '');

            if (contextData) {
                const parsedValue = parseTemplate(acc.language, language, contextData);
                acc.language = parsedValue === 'undefined' ? undefined : parsedValue;
            }
        } else if (part.startsWith('bucket:')) {
            acc.bucket = part.replace('bucket:', '');
        } else if (part.startsWith('fields:')) {
            acc.fields = part.replace('fields:', '').split(',');
        } else if (part.startsWith('group:')) {
            acc.group = part.replace('group:', '');
        } else if (part.startsWith('display:')) {
            acc.display = part.replace('display:', '') as 'inline' | 'dialog';
        } else if (part === 'is_root_id') {
            acc.is_root_id = true;
        } else {
            const [key, value] = part.split(':');
            acc[key] = Number.isNaN(Number(value)) ? value : Number(value);
        }
        return acc;
    }, {});

    return { type, options: Object.keys(options).length ? options : null };
};
