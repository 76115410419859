import { Flex } from 'antd';

import { PageTitleText } from './ui';
import { PageHeaderProps } from './types';

import './PageHeader.scss';

export const PageHeader: React.FC<PageHeaderProps> = ({
    title,
    pageWidth,
    disabled,
    center,
    right,
    extraContent,
    onVertical = 900
}) => {
    if (disabled) return <></>;

    return (
        <div className="page-header">
            <Flex
                vertical={Boolean(pageWidth && pageWidth < onVertical)}
                align="center"
                justify="space-between"
            >
                <div className="page-title">
                    <PageTitleText title={title} />
                </div>

                {center}
                {right}
            </Flex>
            {extraContent}
        </div>
    );
};
