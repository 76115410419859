import { useEffect, useState } from 'react';
import { PatternMonth, PatternWeek } from './components';

interface DaysPatternFieldProps {
    value: boolean[];
    onChange: (newValue: boolean[]) => void;
    mode: 'edit' | 'view';
    dataSource?: any;
    centered?: boolean;
}

export const DaysPatternField: React.FC<DaysPatternFieldProps> = ({
    value,
    onChange,
    dataSource,
    mode,
    centered
}) => {
    const [cachedValue, setCachedValue] = useState(value);

    useEffect(() => {
        if (
            (dataSource.ScheduleItemCategory?.Code === 'PER_DAY' ||
                dataSource.Interval?.Code === 'PER_DAY') &&
            value
        ) {
            setCachedValue(value);
            onChange(value.map(() => true));
        } else {
            onChange(cachedValue);
        }
    }, [dataSource.Interval?.Code, dataSource.ScheduleItemCategory?.Code]); // DO NOT CHANGE !!!

    return (
        <div
            style={{
                marginTop: '10px',
                marginLeft: centered ? 'auto' : undefined,
                marginRight: centered ? 'auto' : undefined,
                marginBottom: '0'
            }}
        >
            {(dataSource.ScheduleItemCategory?.Code === 'PER_DAY' ||
                dataSource.Interval?.Code === 'PER_DAY') && (
                <PatternWeek mode={mode} value={value} onChange={onChange} />
            )}
            {(dataSource.ScheduleItemCategory?.Code === 'PER_WEEK' ||
                dataSource.Interval?.Code === 'PER_WEEK') && (
                <PatternWeek mode={mode} value={value} onChange={onChange} />
            )}
            {(dataSource.ScheduleItemCategory?.Code === 'PER_MONTH' ||
                dataSource.Interval?.Code === 'PER_MONTH') && (
                <PatternMonth mode={mode} value={value} onChange={onChange} />
            )}
        </div>
    );
};
