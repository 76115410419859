import { MetaField } from 'modules/services/backend-api/generated_info';
import { LANGUAGES } from 'utils/i18n/i18n';
import { IObjectWithId } from 'utils/store/MetaStore';
import { fieldRender as render } from '../../../utils';

type FieldRenderType = {
    (options: {
        field: MetaField;
        language: LANGUAGES;
        fields: MetaField[];
        isViewAsLink?: boolean;
        rootMeta?: string;
        rootDataSource?: IObjectWithId;
    }): (value: unknown, row: IObjectWithId, rowIndex: any) => React.ReactNode;
};

export const fieldRender: FieldRenderType = ({
    field,
    language,
    fields,
    rootMeta,
    rootDataSource,
    isViewAsLink = false
}) => {
    // memoizeOne(
    return (value, row) => {
        return render({
            data: value,
            dataSource: row,
            language,
            metaFieldData: fields,
            fieldName: field.FieldName,
            isForTable: true,
            isViewAsLink,
            rootMeta,
            rootDataSource
        });
    };
};

// export const fieldRender = memoizeOne(r, (...args) => {
//     const res = isEqual(...args);
//     // console.log('1', res);
//     return res;
// });
