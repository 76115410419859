import { useCallback } from 'react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { routeStore } from '../../index';

export const useStoreNavigate = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const storeNavigate: NavigateFunction = useCallback(
        (...args) => {
            // console.log('store navigate', args, location);
            if (typeof args[0] === 'string' && typeof args[1] === 'object') {
                const [pathname, search] = args[0].split('?');
                const openedRouteIndex = routeStore.findIndex({
                    pathname: location.pathname,
                    search: location.search,
                    state: location.state
                });
                // const currentRoute = routeStore.getStateByLocation({ pathname });
                // if (currentRoute && pathname !== '/') {
                //     // const currentRouteIndex = routeStore.findIndex(currentRoute);
                //     // routeStore.replace(currentRoute, {
                //     //     pathname,
                //     //     search: search ? `?${search}` : '',
                //     //     ...args[1]
                //     // });
                //     // routeStore.remove(currentRoute);
                //     routeStore.push({
                //         pathname,
                //         search: search ? `?${search}` : '',
                //         ...args[1]
                //     });
                // } else {
                routeStore.insertAt(openedRouteIndex + 1, {
                    pathname,
                    search: search ? `?${search}` : '',
                    ...args[1]
                });
                // }
            } else if (typeof args[0] === 'string') {
                const [pathname, search] = args[0].split('?');
                // const currentRoute = routeStore.getStateByLocation({ pathname });
                //
                // if (currentRoute && pathname !== '/') {
                //     routeStore.remove(currentRoute);
                // }
                const openedRouteIndex = routeStore.findIndex({
                    pathname: location.pathname,
                    search: location.search,
                    state: location.state
                });

                routeStore.insertAt(openedRouteIndex + 1, {
                    pathname,
                    search: search ? `?${search}` : '',
                    state: null
                });

                // routeStore.push({
                //     pathname,
                //     search: search ? `?${search}` : '',
                //     state: null
                // });
            } else if (typeof args[0] === 'object' && typeof args[1] === 'object') {
                const pathname = args[0].pathname?.split('?')[0] ?? location.pathname;
                const search = args[0].search || '';
                const openedRouteIndex = routeStore.findIndex({
                    pathname: location.pathname,
                    search: location.search,
                    state: location.state
                });

                routeStore.insertAt(openedRouteIndex + 1, {
                    pathname,
                    search,
                    ...args[1]
                });
                // routeStore.push({
                //     pathname: args[0].pathname?.split('?')[0],
                //     search: args[0].search || '',
                //     ...args[1]
                // } as Path);
            } else if (typeof args[0] === 'object') {
                // const currentRoute = routeStore.getStateByLocation({
                //     pathname: args[0].pathname?.split('?')[0]
                // });
                //
                // if (currentRoute) {
                //     routeStore.remove(currentRoute);
                // }
                const pathname = args[0].pathname?.split('?')[0] ?? location.pathname;
                const search = args[0].search || '';
                const openedRouteIndex = routeStore.findIndex({
                    pathname: location.pathname,
                    search: location.search,
                    state: location.state
                });

                routeStore.insertAt(openedRouteIndex + 1, {
                    pathname,
                    search,
                    state: null
                });

                // routeStore.push({
                //     pathname: args[0].pathname?.split('?')[0] as string,
                //     search: args[0].search || '',
                //     state: null
                // });
            }

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            navigate(...args);
        },
        [navigate]
    );

    return storeNavigate;
};
