import { Input, InputProps } from 'antd';
import { type InputHTMLAttributes, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';

interface StringFieldProps extends Omit<InputProps, 'onChange'> {
    onChange?: (value: InputHTMLAttributes<HTMLInputElement>['value'] | bigint) => void;
}

export const StringField = memo<StringFieldProps>(({ value, onChange, ...props }) => {
    const { t } = useTranslation();

    const [innerValue, setInnerValue] = useState(value);

    useDebounce(
        () => {
            if (onChange) onChange(innerValue);
        },
        400,
        [innerValue]
    );

    return (
        <Input
            {...props}
            placeholder={t('no_value') as string}
            value={innerValue}
            onChange={(e) => setInnerValue(e.target.value)}
        />
    );
});
