import { isEqual, uniqWith } from 'lodash';
import { runInAction } from 'mobx';
import { IObjectWithId } from 'utils/store/MetaStore';

interface IMetaWithChildren extends IObjectWithId {
    children?: IObjectWithId[];
}

// Удаляем пустые массивы children
function cleanEmptyChildren(items: IMetaWithChildren[]) {
    const cleanedItems = [...items];

    cleanedItems.forEach((item) => {
        if (item.children && item.children.length === 0) {
            delete item.children;
        } else if (item.children) {
            cleanEmptyChildren(item.children);
        }
    });

    return cleanedItems;
}

export const checkAndMakeTreeData = (
    data: IObjectWithId[],
    parentFieldName: string = 'Parent',
    disableReconstructArray = true,
    viewFieldName: string | undefined = undefined
): IMetaWithChildren[] => {
    const elementsByKey: { [key: string | number]: IMetaWithChildren } = {};
    const dataWithChildren = data;

    // Создаем словарь для быстрого доступа к элементам по ключам
    dataWithChildren.forEach((item) => {
        elementsByKey[item.Id] = item;
        runInAction(() => {
            item.children = [];
        });
    });

    const result: IMetaWithChildren[] = [];

    // Проходимся по всем элементам массива
    dataWithChildren.forEach((item) => {
        // Проверяем, есть ли у элемента ParentId
        if (item[parentFieldName]?.Id) {
            const parent = elementsByKey[item[parentFieldName]?.Id];
            // Если родитель присутсвует в общем массиве данных, то...
            if (parent && parent.children) {
                // ...добавляем текущий элемент в массив children родителя
                parent.children.push(item);
                // Иначе будем отображать в корне
            } else result.push(item);
        } else {
            // Если у элемента нет ParentId, добавляем его в корневой массив
            result.push(item);
        }
    });

    // Проверяем, совпадает ли выходной массив с исходным
    if (!disableReconstructArray && result.length === data.length) {
        // Вычленяем все уникальные элементы с parentFieldName
        // const parentItemsSet = new Set<string>();
        const parentItemsSet: IObjectWithId[] = [];
        data.forEach((item) => {
            if (item[parentFieldName]?.Id) {
                const { Name, Key, Code, PluralName, ShortTitle } = item[parentFieldName];

                // [viewFieldName]: ShortTitle || PluralName || Name || Key || Code
                const addable = viewFieldName
                    ? // JSON.stringify(
                      {
                          ...item[parentFieldName],
                          isHardGroup: true,
                          [viewFieldName]: ShortTitle || PluralName || Name || Key || Code
                      }
                    : // )
                      // JSON.stringify(
                      {
                          ...item[parentFieldName],
                          isHardGroup: true
                      };
                // );

                const hasItem = parentItemsSet.findIndex((i) => i.Id === addable.Id) !== -1;

                // console.log(addable.includes('System') ? addable : undefined);

                if (!hasItem) parentItemsSet.push(addable);

                // parentItemsSet = uniqWith([...parentItemsSet, addable], isEqual);
                // parentItemsSet.add(addable);
            }
        });
        // const parentItems = JSON.parse(`[${Array.from(parentItemsSet).join(',')}]`);
        const parentItems = parentItemsSet;

        // Объединяем родительские элементы с остальными и снова выполняем преобразование
        const combinedData = [...parentItems, ...data];

        return checkAndMakeTreeData(combinedData, parentFieldName, true);
    }

    const finalResult = [];

    // Сортируем детей если у них есть ChildIndex
    result.forEach((itemWithChildren) => {
        finalResult.push(
            itemWithChildren.children?.slice()?.sort((a, b) => {
                if (a.ChildIndex && b.ChildIndex) {
                    const indexA = a.ChildIndex as number;
                    const indexB = b.ChildIndex as number;
                    return indexA < indexB ? -1 : 1;
                }

                return 1;
            })
        );
    });

    return cleanEmptyChildren(result);
};
