import dayjs, { Dayjs } from 'dayjs';

import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import az from 'dayjs/locale/az';
import en from 'dayjs/locale/en-ca';
import kk from 'dayjs/locale/kk';
import ru from 'dayjs/locale/ru';
import tr from 'dayjs/locale/tr';

import { LANGUAGES } from 'utils/i18n/i18n';

export const localeFormats: Record<Exclude<LANGUAGES, 'tech'>, typeof az> = {
    az,
    kk,
    ru,
    tr,
    en: { ...en, formats: { ...en.formats, L: 'MM/DD/YYYY' } }
};

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

export const UI_YEAR_FORMAT = 'YYYY';
export const UI_DATE_FORMAT = 'L';
export const UI_DATE_TIME_LOCAL_FORMAT = 'L HH:mm';
export const UI_DATE_TIME_FORMAT = 'L HH:mm:ss Z';
export const UI_TIME_FORMAT = 'HH:mm';
export const UI_TIME_FORMAT_FULL = 'HH:mm:ss';

export const YEAR_FORMAT = 'YYYY';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_LOCAL_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss Z';
export const TIME_FORMAT = 'HH:mm';
export const TIME_FORMAT_FULL = 'HH:mm:ss';

const MINUTE = 60;
const HOUR = 3_600;
const DAY = 86_400;

export const REGEX_REPLACE_GMT = /(\+|-)\d{2}:\d{2}$/;

export const UserSpecificFormat = {
    getUserTimeZone: (): string => {
        return dayjs.tz.guess();
    },

    convertFromDbDateToUiDate: (date: string | null, language = 'en') => {
        if (!date) {
            return '';
        }

        return dayjs(date.replace(REGEX_REPLACE_GMT, '').replace('Z', ''))
            .locale(language)
            .format(UI_DATE_FORMAT);
    },

    getDateFormat: () => {
        return DATE_FORMAT;
    },

    getYearFormat: () => {
        return YEAR_FORMAT;
    },

    convertFromDbDateTimeToUiDateTime: (date: string | null, language = 'en') => {
        if (!date) {
            return '';
        }

        return dayjs(date.replace(REGEX_REPLACE_GMT, '').replace('Z', ''))
            .locale(language)
            .format(UI_DATE_TIME_FORMAT);
    },

    convertFromDbDateTimeLocalToUiDateTime: (date: string | null, language = 'en') => {
        if (!date) {
            return '';
        }

        return dayjs(date.replace(REGEX_REPLACE_GMT, '').replace('Z', ''))
            .locale(language)
            .format(UI_DATE_TIME_LOCAL_FORMAT);
    },

    getDateTimeLocalFormat: () => {
        return DATE_TIME_LOCAL_FORMAT;
    },

    getDateTimeFormat: () => {
        return DATE_TIME_FORMAT;
    },

    convertFromDbTimeToUiTime: (date: string | null, language = 'en') => {
        if (!date) {
            return '';
        }

        return dayjs(date, [TIME_FORMAT_FULL, TIME_FORMAT]).format(UI_TIME_FORMAT);
    },
    getTimeFormat: () => {
        return TIME_FORMAT;
    },
    convertFromDbTimeFullToUiTimeFull: (date: string | null) => {
        if (!date) {
            return '';
        }

        return dayjs(date.replace(REGEX_REPLACE_GMT, '').replace('Z', '')).format(
            UI_TIME_FORMAT_FULL
        );
    },

    getTimeFormatFull: () => {
        return TIME_FORMAT_FULL;
    },

    convertFromUIDateTimeToDBDateTime: (date: Date | Dayjs) => {
        return date.toISOString();
    }
};

export const convertDateTimeToTimeZone = (
    dateTime: string | number | Date | Dayjs | null,
    timeZone: string | null
): Dayjs => {
    if (!timeZone) {
        return dayjs(dateTime).tz(UserSpecificFormat.getUserTimeZone());
    }
    return dayjs(dateTime).tz(timeZone);
};

// TODO: Брать Таймзону юзера
export const dateTimeToMonthStart = (date: string | Date | null): Dayjs | string => {
    const dateInUserTimeZone = convertDateTimeToTimeZone(
        date,
        UserSpecificFormat.getUserTimeZone()
    );
    return dayjs(dayjs(dateInUserTimeZone).startOf('day').startOf('month').format());
};

export const dateTimeToMonthEnd = (date: string | Date | null): Dayjs | string => {
    const dateInUserTimeZone = convertDateTimeToTimeZone(
        date,
        UserSpecificFormat.getUserTimeZone()
    );
    return dayjs(dayjs(dateInUserTimeZone).endOf('month').endOf('day').format());
};

export const dateTimeToDayStart = (date: null | string | Date): Dayjs | string => {
    const dateInUserTimeZone = convertDateTimeToTimeZone(
        date,
        UserSpecificFormat.getUserTimeZone()
    );
    return dayjs(dayjs(dateInUserTimeZone).startOf('day').format());
};

export const dateTimeToDayUTCStart = (date: null | string | Date): Dayjs | string => {
    return dayjs(date)
        .startOf('day')
        .utc(false)
        .local()
        .format(UserSpecificFormat.getDateTimeLocalFormat());
};

export const dateTimeToDayUTCEnd = (date: null | string | Date): Dayjs | string => {
    return dayjs(date)
        .endOf('day')
        .utc(false)
        .local()
        .format(UserSpecificFormat.getDateTimeLocalFormat());
};

export const dateTimeToDayEnd = (date: null | string | Date): Dayjs | string => {
    const dateInUserTimeZone = convertDateTimeToTimeZone(
        date,
        UserSpecificFormat.getUserTimeZone()
    );
    return dayjs(dayjs(dateInUserTimeZone).endOf('day').format());
};

export class TimeConverter {
    static intervalToString(interval: string | null | unknown) {
        if (!interval) return '';
        const [hours, minutes, seconds] = interval.toString().split(':').map(Number);
        const data = hours * HOUR + minutes * MINUTE + seconds;
        const leftHours = data % DAY;
        const leftMinutes = leftHours % HOUR;
        const resultDay = (data - leftHours) / DAY;
        const resultHour = (leftHours - leftMinutes) / HOUR;
        const resultMinute = Math.floor(leftMinutes / MINUTE);

        return `${resultDay !== 0 ? `${resultDay}d ` : ''}${
            resultHour !== 0 ? `${resultHour}h ` : ''
        }${resultMinute !== 0 ? `${resultMinute}m` : ''}`.trim();
    }

    static StringToInterval(data: string) {
        const regex = /(\d+)\s*(d|h|m)\s*/g;
        let result = 0;
        let match = regex.exec(data);

        while (match !== null) {
            const value = parseInt(match[1], 10);
            const unit = match[2];
            if (unit === 'd') {
                result += value * DAY;
            } else if (unit === 'h') {
                result += value * HOUR;
            } else if (unit === 'm') {
                result += value * MINUTE;
            }
            match = regex.exec(data);
        }

        const hours = Math.floor(result / HOUR);
        const minutes = Math.floor((result % HOUR) / MINUTE);
        const seconds = Math.floor(result % MINUTE);

        return `${hours.toString().padStart(2, '0')}:${minutes
            .toString()
            .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }

    static numToString(data: number) {
        const type = typeof data;
        if (type === 'number') {
            let resultDay = 0;
            let resultHour = 0;
            let resultMinute = 0;
            let resultSecond = 0;

            const leftHours = data % DAY;
            const leftMinutes = leftHours % HOUR;
            const leftSeconds = leftMinutes % MINUTE;

            resultDay = Math.floor(data / DAY);
            resultHour = Math.floor(leftHours / HOUR);
            resultMinute = Math.floor(leftMinutes / MINUTE);
            resultSecond = Math.floor(leftSeconds);

            return (
                (resultDay !== 0 ? `${resultDay}d ` : '') +
                (resultHour !== 0 ? `${resultHour}h ` : '') +
                (resultMinute !== 0 ? `${resultMinute}m ` : '') +
                (resultSecond !== 0 ? `${resultSecond}s` : '')
            );
        }
        return ' ';
    }
}
