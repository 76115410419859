import i18next from 'i18next';

import { SqlStateErrorCodes } from 'utils/config/constants';

// Данная функция берет текст ошибки и находит в нем код ошибки из списка заведенных в константу ошибок. Если она нашла его, то возвращает переведенный текст ошибки на текущем в системе языке, заранее заведенный в tolgee.io и импортированный в проект
export const makeErrorReadable = (errorMessage: string): string => {
    const errorCode = SqlStateErrorCodes.find((code) => errorMessage.includes(code));
    if (!errorCode) console.error(errorMessage);

    return errorCode ? i18next.t(errorCode) : i18next.t(errorMessage);
};
