export const getPercentFormat = (percent = 0, successPercent = 0): React.ReactNode => {
    // let color = '#3185D2';

    // if (percent >= 90 && percent <= 100) {
    //     color = '#388e3c';
    // }

    // if (percent > 100) {
    //     color = '#D71A21';
    // }
    // // if (percent < 40) {
    // //     color = '#EBBA3D';
    // // }

    // // if (percent < 30) {
    // //     color = '#D71A21';
    // // }

    // return <span style={{ color }}>{percent}%</span>;
    return <span style={{ color: 'rgba(0,0,0,.88)' }}>{percent}%</span>;
};
