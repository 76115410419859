import { PartialDeep } from '@gilbarbara/types';
import { v4 } from 'uuid';

import { Action, Handler } from 'modules/services/backend-api/generated_info';

export const getMockActionDataByGetPartially = (action: PartialDeep<Action>): Action => {
    return {
        Handler_Code: '',
        Id: '',
        Meta_Code: '',
        Handler: {
            ChildParams: [],
            Code: '',
            Name: {},
            Id: v4(),
            Meta_Code: '',
            Type_Code: '',
            Description: {},
            IsDisabled: false,
            IsAsyncEvents: false,
            IsForArray: false,
            ...(action.Handler as Partial<Handler>)
        },
        Name: {},
        Type_Code: '',
        IsFolder: false,
        IsVisibleInList: false,
        IsVisibleInForm: false,
        IsVisibleInContextMenu: false,
        IsBuiltIn: false,
        IsDisabled: false,
        IsActive: false,
        IsSingle: false,
        IsButton: false,
        Type: {
            Code: '',
            Name: {}
        },
        Conditions: [],
        ...(action as Partial<Action>)
    };
};
