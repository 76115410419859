import { HomeOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router';

import { ButtonWithTooltips } from 'ui';
import { Header } from '../Header/Header';

const { Content } = Layout;

interface IWmsEmployeeProvider {
    onlyChildren: ReactNode;
}

export const WorkerTerminalProvider = ({
    children,
    onlyChildren
}: React.PropsWithChildren<IWmsEmployeeProvider>) => {
    const navigate = useNavigate();

    const handleToggleMenu = () => {
        navigate(`/worker-terminal`);
    };

    if (!window.location.href.match('worker-terminal')) return children;

    return (
        <Layout>
            <Header
                style={{
                    width: '100%'
                }}
                toggleMenuButton={
                    <ButtonWithTooltips
                        id="menu_mode"
                        className="btn-menu"
                        onClick={handleToggleMenu}
                        icon={<HomeOutlined />}
                        type="text"
                    />
                }
                showUserProfile={false}
            />
            <Content
                style={{
                    padding: 10,
                    marginTop: '50px',
                    marginBottom: '57px',
                    paddingTop: 0
                }}
            >
                <div className="worker-terminal__wrapper">{onlyChildren}</div>
            </Content>
        </Layout>
    );
};
