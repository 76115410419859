import { CloseCircleFilled } from '@ant-design/icons';
import { TimePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { TIME_FORMAT, UI_TIME_FORMAT } from 'utils/helpers/dates';

type AntdRangeValue<T> = [T | null, T | null];

type TimeRangeValue = {
    FromTime: string | null;
    ToTime: string | null;
};

interface SmartTimeRangeFieldProps {
    value: TimeRangeValue | null; // ISO string (from Supabase DB) aka DbDateTime
    onChange: (newValue: TimeRangeValue | null) => void; // ISO string (from Supabase DB) aka DbDateTime
    popoverContainerHtmlId?: string;
    disabled?: boolean;
    style?: { [key: string]: any };
    className?: string;
}
// The component is used to display and edit date and time values using user's preferred format!

export const SmartTimeRangeField = memo<SmartTimeRangeFieldProps>(
    ({ value, onChange, disabled, popoverContainerHtmlId, className, style }) => {
        const { t } = useTranslation();

        // Определение функции для рендеринга компонента выбора даты и времени
        const handleClear: React.MouseEventHandler<HTMLSpanElement> = (e) => {
            e.stopPropagation();
            onChange(null);
        };

        const handleChange = useCallback(
            (date: AntdRangeValue<Dayjs> | null) => {
                onChange({
                    FromTime: date && date[0] ? dayjs(date[0]).format(TIME_FORMAT) : null,
                    ToTime: date && date[1] ? dayjs(date[1]).format(TIME_FORMAT) : null
                });
            },
            [onChange]
        );

        const antdValue: AntdRangeValue<Dayjs> =
            value?.FromTime && value?.ToTime
                ? [dayjs(value?.FromTime), dayjs(value?.ToTime)]
                : [null, null];

        // Рендеринг базового компонента поля ввода с переданными пропсами и определенными функциями рендеринга компонентов выбора и просмотра даты и времени
        return (
            <TimePicker.RangePicker
                placeholder={[t('no_value') as string, t('no_value') as string]}
                value={antdValue}
                onChange={handleChange}
                format={UI_TIME_FORMAT}
                allowClear={{ clearIcon: <CloseCircleFilled onClick={handleClear} /> }}
                className={className}
                style={style}
                disabled={disabled}
                getPopupContainer={
                    popoverContainerHtmlId
                        ? () => document.getElementById(popoverContainerHtmlId) as HTMLElement
                        : undefined
                }
            />
        );
    }
);
