import { Typography } from 'antd';
import { DefaultTFuncReturn } from 'i18next';

import './PageTitleText.scss';

const { Title } = Typography;

interface PageTitleTextProps {
    title: string | DefaultTFuncReturn;
    description?: string | DefaultTFuncReturn;
}

// TODO: FIXME: лучше передавать ключи descriptionKey, titleKey
// потому что мы лишний раз вызываем функции при передаче
// параметров title={t(key)} description={t(key)}
// а лучше всего вызов t() делать внутри компонента и передавать туда ключи
// тогда у нас будет title={key} description={key}
export const PageTitleText: React.FC<PageTitleTextProps> = ({ title, description = '' }) => {
    return (
        <div className="page-title-text">
            <Title level={3} style={{ marginTop: 0, marginBottom: 0 }}>
                {title}
            </Title>
            {/* <Typography.Text>{description}</Typography.Text> */}
        </div>
    );
};
