import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Flex, Popover } from 'antd';

interface HelpIconProps extends React.PropsWithChildren {
    text?: string;
    trigger?: 'click' | 'hover';
    style?: React.CSSProperties;
    iconStyle?: React.CSSProperties;
    placement?: 'start' | 'end';
}

export const HelpIcon: React.FC<HelpIconProps> = ({
    text,
    children,
    style,
    iconStyle,
    trigger = 'click',
    placement = 'end'
}) => {
    return (
        <Flex
            style={{
                width: '100%',
                alignItems: !children ? 'center' : undefined,
                justifyContent: !children ? 'center' : undefined,
                ...style
            }}
            gap={3}
        >
            {placement === 'end' ? children : null}
            {text ? (
                <Popover
                    placement="bottomLeft"
                    arrow={false}
                    content={text}
                    destroyTooltipOnHide
                    mouseEnterDelay={0.4}
                    trigger={[trigger]}
                >
                    <InfoCircleOutlined
                        style={{
                            justifySelf: 'flex-end',
                            color: 'rgba(0, 0, 0, 0.2)',
                            transition: 'all 0.3s ease',
                            marginLeft: placement === 'end' ? 5 : undefined,
                            marginRight: placement === 'start' ? 5 : undefined,
                            marginTop: 'auto',
                            marginBottom: 'auto',
                            ...iconStyle
                        }}
                    />
                </Popover>
            ) : placement === 'start' ? (
                <div
                    style={{
                        width: 14,
                        height: 14,
                        // marginLeft: placement === 'end' ? 5 : undefined,
                        marginRight: placement === 'start' ? 5 : undefined
                    }}
                />
            ) : null}
            {placement === 'start' ? (
                <div style={{ width: 'calc(100% - 22px)' }}>{children}</div>
            ) : null}
        </Flex>
    );
};
