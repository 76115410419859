import { PlainObject } from '@gilbarbara/types';
import { Dropdown, Flex, MenuProps, Space } from 'antd';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsync, useMedia, useWindowSize } from 'react-use';

import { Filter } from 'modules/supabase/utils/supabaseClient';
import { ButtonWithTooltips } from 'ui';
import { LANGUAGES } from 'utils/i18n/i18n';
import { metaStore } from 'utils/store/MetaStore';

import { Response, Route } from 'modules/services/backend-api/generated_api';
import { Action, MetaField } from 'modules/services/backend-api/generated_info';
import { HelpIcon, LazyIcon } from 'smart/ui';

import { useLocation } from 'react-router-dom';
import { Toast } from 'antd-mobile';
import { LocalSearchComponent } from '../LocalSearchComponent/LocalSearchComponent';
import { SmartTableFilterMenuToolbarButton } from '../SmartTableFilterMenu/SmartTableFilterMenuToolbarButton';
import { FilterField } from '../SmartTableFilterMenu/types';
import { MobileCardToolbar } from './components';

type MenuItem = NonNullable<MenuProps['items']>[number] & { index?: number; children?: MenuItem[] };

interface SmartTablePageToolbarProps {
    meta: string;
    selectedRowKeys: string[];
    hasSelected: boolean;
    searchFilter?: string;
    setSearchFilter: (filter: string) => void;
    filters: FilterField[];
    filterString: string;
    setFilters: Dispatch<SetStateAction<FilterField[]>>;
    onRefresh: () => any;
    onCreate: () => void | Promise<void>;
    onCopy: () => void | Promise<void>;
    onDelete: () => void | Promise<void>;
    navigateAfterRun: (
        actionResponse: Response,
        action: Action,
        filters: FilterField[] | Filter[],
        metaFields?: MetaField[]
    ) => void;
    onClickAction: (
        action: Action,
        hardIds?: string[],
        hardArgs?: PlainObject
    ) => Promise<Response | undefined>;

    detailsRenderer?: () => React.ReactNode;

    // setContextMenuActions?: Dispatch<SetStateAction<MenuProps['items']>>;
    showComponents?: {
        isReload?: boolean;
        isSearch?: boolean;
        isFilter?: boolean;
        isRightPart?: boolean;
        isDisplayViewPreferences?: boolean;
        isCreate?: boolean;
        isDelete?: boolean;
        isDescription?: boolean;
        isActions?: boolean;
        isFollows?: boolean;
        isReports?: boolean;
        isActionButtons?: boolean;
        isCopyLink?: boolean;
    };

    isMobile?: boolean;
}

interface SmartTablePageToolbarDesktopProps
    extends Omit<SmartTablePageToolbarProps, 'navigateAfterRun'> {
    currentNavItem: Route | undefined;
    actionsMenuItems: MenuItem[];
    reportsMenuItems: MenuItem[];
    followsMenuItems: MenuItem[];
}

const initComponentsDisplay = {
    isReload: true,
    isSearch: true,
    isFilter: true,
    isRightPart: true,
    isDisplayViewPreferences: true,
    isCreate: true,
    isDelete: true,
    isDescription: true,
    isActions: true,
    isFollows: true,
    isReports: true,
    isActionButtons: true,
    isDetails: true,
    isCopyLink: true
};

const SmartTablePageHeaderToolbarDesktop = observer<SmartTablePageToolbarDesktopProps>(
    ({
        meta,
        selectedRowKeys,
        hasSelected,
        searchFilter,
        setSearchFilter,
        filters,
        filterString,
        setFilters,
        onRefresh,
        onCreate,
        onCopy,
        onDelete,
        showComponents: showComponentsLocal,
        onClickAction,
        actionsMenuItems,
        reportsMenuItems,
        followsMenuItems,
        currentNavItem,
        // navigateAfterRun,

        detailsRenderer
        // setContextMenuActions
    }) => {
        // const location = useLocation();
        const {
            t,
            i18n: { language }
        } = useTranslation();
        const isBigMobile = useMedia('(max-width: 480px)');

        const metaData = useMemo(
            () => toJS(metaStore.meta.get(meta)),
            [meta, metaStore.meta.get(meta)]
        );

        const actions = useMemo(
            () =>
                metaData?.info?.Actions?.sort((action) => (action.Type_Code === 'FOLDER' ? -1 : 1)),
            [metaData?.info?.Actions]
        );

        const isMetaReadOnly = metaData?.info?.IsReadOnly || false;
        const uiAllowCreate = metaData?.info?.UiAllowCreate;
        const uiAllowDelete = metaData?.info?.UiAllowDelete;
        const metaDescription = metaData?.info?.Description?.[language];

        // REESPONSIVE
        const { width } = useWindowSize();
        const isShowActionButtons = width > 660;
        const isShowActionButtonsLabel = width > 980;
        const isSmallTablet = useMedia('(max-width: 620px)');

        const showComponents = useMemo(
            () => ({ ...initComponentsDisplay, ...showComponentsLocal }),
            [showComponentsLocal]
        );

        // const handleOpenDpModal = async () => {
        //     setDpModal(true);
        // };

        const actionButtons = useMemo(() => {
            if (actions) {
                return actions
                    .filter((action) => {
                        const cond =
                            action.IsButton &&
                            action.IsVisibleInList &&
                            action.IsActive &&
                            (!action.AllowedForNavItems?.length ||
                                action.AllowedForNavItems?.includes(currentNavItem?.id as string));

                        if (!uiAllowDelete) {
                            return (
                                cond &&
                                action.Handler_Code !== 'Delete' &&
                                action.Handler_Code !== 'SoftDelete'
                            );
                        }

                        return cond; // ID есть всегда - это я подразумеваю
                    })
                    .map((action) => {
                        const isDisabled =
                            (!action?.Handler?.IsForNoneObjects && !hasSelected) ||
                            (action.IsSingle && selectedRowKeys?.length > 1);

                        return (
                            <ButtonWithTooltips
                                type={'default'}
                                className={''}
                                disabled={isDisabled}
                                tooltipTitle={
                                    isShowActionButtonsLabel ? action.Name?.[language] : undefined
                                }
                                id={action.Id || ''}
                                onClick={() => onClickAction(action)}
                                icon={<LazyIcon icon={action.Icon} />}
                            >
                                {isShowActionButtonsLabel ? action.Name?.[language] : null}
                            </ButtonWithTooltips>
                        );
                    });
            }

            return null;
        }, [
            actions,
            currentNavItem?.id,
            uiAllowDelete,
            hasSelected,
            selectedRowKeys?.length,
            isShowActionButtonsLabel,
            language,
            onClickAction
        ]);

        return (
            <Flex justify="space-between" className="smart_table__header">
                {/* ### Дейсвия (мета) ### */}
                <Space.Compact className="smart_table__header_left">
                    {actionsMenuItems.length && showComponents.isActions && !isBigMobile ? (
                        <Dropdown
                            menu={{ items: actionsMenuItems }}
                            trigger={['click']}
                            placement="bottomLeft"
                            destroyPopupOnHide
                        >
                            <ButtonWithTooltips type={'default'} className={''} id="actions">
                                {isSmallTablet ? <LazyIcon icon={'MenuOutlined'} /> : t('actions')}
                                {!isBigMobile && <LazyIcon icon={'CaretDownOutlined'} />}
                            </ButtonWithTooltips>
                        </Dropdown>
                    ) : null}

                    {/* ### Отчеты (мета) ### */}
                    {reportsMenuItems.length && showComponents.isReports && !isBigMobile ? (
                        <Dropdown
                            menu={{ items: reportsMenuItems }}
                            trigger={['click']}
                            placement="bottomLeft"
                            destroyPopupOnHide
                        >
                            <ButtonWithTooltips type={'default'} className={''} id="reports">
                                {isSmallTablet ? (
                                    <LazyIcon icon={'FilePdfOutlined'} />
                                ) : (
                                    t('reports')
                                )}
                                {!isBigMobile && <LazyIcon icon={'CaretDownOutlined'} />}
                            </ButtonWithTooltips>
                        </Dropdown>
                    ) : null}

                    {/* ### Переходы (мета) ### */}
                    {followsMenuItems.length && showComponents.isFollows && !isBigMobile ? (
                        <Dropdown
                            menu={{ items: followsMenuItems }}
                            trigger={['click']}
                            placement="bottomLeft"
                            destroyPopupOnHide
                        >
                            <ButtonWithTooltips type={'default'} className={''} id="follows">
                                {isSmallTablet ? (
                                    <LazyIcon icon={'ShareAltOutlined'} />
                                ) : (
                                    t('follows')
                                )}
                                {!isBigMobile && <LazyIcon icon={'CaretDownOutlined'} />}
                            </ButtonWithTooltips>
                        </Dropdown>
                    ) : null}

                    {!!detailsRenderer && !isBigMobile && detailsRenderer()}

                    {/* ### Обновить ### */}
                    {showComponents.isReload ? (
                        <ButtonWithTooltips
                            id="reload"
                            tooltipTitle={t('reload')}
                            tooltipPlacement="top"
                            onClick={onRefresh}
                            icon={<LazyIcon icon={'ReloadOutlined'} />}
                            type={'default'}
                            className={''}
                        />
                    ) : null}

                    {/* ### Удалить ### */}
                    {uiAllowDelete && showComponents.isDelete && !isBigMobile ? (
                        <ButtonWithTooltips
                            id="delete"
                            tooltipTitle={t('delete')}
                            tooltipPlacement="top"
                            onClick={onDelete}
                            icon={<LazyIcon icon={'DeleteOutlined'} />}
                            disabled={!hasSelected || isMetaReadOnly}
                            type={'default'}
                            className={''}
                        />
                    ) : null}

                    {/* ### Создать ### */}
                    {uiAllowCreate && showComponents.isCreate && !isBigMobile ? (
                        <ButtonWithTooltips
                            id="create"
                            tooltipTitle={t('create')}
                            onClick={onCreate}
                            icon={<LazyIcon icon={'PlusOutlined'} />}
                            type={'default'}
                            className={''}
                            disabled={isMetaReadOnly}
                        />
                    ) : null}

                    {/* ### Скопировать ### */}
                    {uiAllowCreate && showComponents.isCreate && !isBigMobile ? (
                        <ButtonWithTooltips
                            id="copy"
                            tooltipTitle={t('copy')}
                            onClick={onCopy}
                            disabled={!hasSelected || isMetaReadOnly}
                            icon={<LazyIcon icon={'CopyOutlined'} />}
                            type={'default'}
                            className={''}
                        />
                    ) : null}

                    {/* ### Описание ### */}
                    {showComponents.isDescription && metaDescription ? (
                        <HelpIcon
                            text={metaDescription}
                            style={{ width: 32 }}
                            iconStyle={{ color: 'rgba(0, 0, 0, 0.88)' }}
                            trigger="click"
                        />
                    ) : null}

                    {isShowActionButtons && showComponents.isActionButtons && !isBigMobile
                        ? actionButtons
                        : null}
                </Space.Compact>

                <Flex style={{ display: 'flex' }} className="smart_table__header_center">
                    {/* TODO: center part of toolbar */}
                    <></>
                </Flex>

                {showComponents.isRightPart && (
                    <Space.Compact className="smart_table__header_right">
                        {/* {showComponents.isDisplayViewPreferences ? (
                        <ButtonWithTooltips
                            id="view_display_preferences"
                            type="default"
                            className=""
                            onClick={handleOpenDpModal}
                            icon={<SettingOutlined />}
                        />
                    ) : null} */}
                        {showComponents.isSearch ? (
                            <LocalSearchComponent
                                searchFilter={searchFilter}
                                setFilter={setSearchFilter}
                                placeholder={`${t('search')}...`}
                            />
                        ) : null}
                        {showComponents.isFilter ? (
                            <SmartTableFilterMenuToolbarButton
                                meta={meta}
                                filters={filters}
                                setFilters={setFilters}
                            />
                        ) : null}
                        {showComponents.isCopyLink ? (
                            <ButtonWithTooltips
                                tooltipTitle={t('copy_link')}
                                className={''}
                                tooltipPlacement={'bottomLeft'}
                                type={'default'}
                                icon={<LazyIcon icon={'mdiLinkVariant'} />}
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        `${window.location.href}${filterString ? `?${filterString}` : ''}`
                                    );
                                    Toast.show({
                                        content: t('copied')
                                    });
                                }}
                            />
                        ) : null}
                    </Space.Compact>
                )}

                {/* {showComponents.isDisplayViewPreferences && (
                <ViewDisplayPreferencesModal
                    open={dpModal}
                    onCancel={() => setDpModal(false)}
                    onOk={() => setDpModal(false)}
                />
            )} */}
            </Flex>
        );
    }
);

export const SmartTablePageHeaderToolbar = observer<SmartTablePageToolbarProps>(
    ({
        meta,
        selectedRowKeys,
        hasSelected,
        filters,
        onClickAction,
        navigateAfterRun,
        isMobile,
        ...props
    }) => {
        // const isBigMobile = useMedia('(max-width: 480px)');
        const location = useLocation();
        const {
            // t,
            i18n: { language }
        } = useTranslation();

        const metaData = useMemo(
            () => toJS(metaStore.meta.get(meta)),
            [meta, metaStore.meta.get(meta)]
        );
        const actions = useMemo(
            () =>
                metaData?.info?.Actions?.sort((action) => (action.Type_Code === 'FOLDER' ? -1 : 1)),
            [metaData?.info?.Actions]
        );

        const currentNavItem = useMemo(
            () =>
                toJS(metaStore.meta.get('all')?.routes)?.find(
                    (route) =>
                        route.path ===
                        `${location.pathname}${
                            location.state?.filterString ? `?${location.state?.filterString}` : ''
                        }`
                ),
            [location.pathname, location.state?.filterString, metaStore.meta.get('all')?.routes]
        );

        // const isMetaReadOnly = metaData?.info?.IsReadOnly || false;
        // const uiAllowCreate = metaData?.info?.UiAllowCreate;
        const uiAllowDelete = metaData?.info?.UiAllowDelete;

        const { width } = useWindowSize();
        const isShowActionButtons = width > 660;

        const fields = metaData?.info?.Fields;

        const { value: isShowFramedAssocInFollowsMenu } = useAsync(
            async () =>
                metaStore.getParam({
                    param_name: 'SHOW_FRAMED_ASSOC_IN_FOLLOWS_MENU',
                    default_value: false
                }),
            []
        );

        const actionsMenuItems = useMemo(() => {
            const folders: MenuItem[] = [];
            const folderless: MenuItem[] = [];

            for (const action of actions ?? []) {
                if (
                    !action.IsVisibleInList ||
                    (isShowActionButtons && action.IsButton && !isMobile) ||
                    !action.IsActive ||
                    (action.AllowedForNavItems?.length &&
                        !action.AllowedForNavItems.includes(currentNavItem?.id as string)) || // ID есть всегда - это я подразумеваю
                    ((action.Handler_Code === 'Delete' || action.Handler_Code === 'SoftDelete') &&
                        !uiAllowDelete)
                ) {
                    continue;
                }

                // if (action.IsButton) console.log(action);

                if (action.IsFolder || action.Type_Code === 'FOLDER') {
                    folders.push({
                        key: action.Id!,
                        index: action.ChildIndex,
                        label: action.Name[language as LANGUAGES],
                        disabled: action.IsDisabled,
                        icon: <LazyIcon icon={action.Icon} />,
                        children: []
                    });
                }

                if (!action.IsFolder && action.Type_Code === 'HANDLER') {
                    const parentId = action.Parent_Id;

                    const folderIndex = folders.findIndex(
                        (folder) => folder && folder.key === parentId
                    );

                    const item = {
                        isButton: action.IsButton,
                        key: action.Id,
                        index: action.ChildIndex,
                        label: action.Name[language as LANGUAGES],
                        // icon: <LazyIcon icon={action.Icon} />,
                        disabled:
                            (!action?.Handler?.IsForNoneObjects && !hasSelected) ||
                            action.IsDisabled ||
                            (action.IsSingle && selectedRowKeys.length > 1),
                        onClick: () => onClickAction(action, isMobile ? selectedRowKeys : undefined)
                    };

                    // если экшн кнопка, то на МОБИЛЕ вынимаем ее из папки
                    if (isMobile && item.isButton) {
                        folderless.push(item);
                    } else if (folderIndex !== -1) {
                        folders[folderIndex]?.children?.push(item);
                    } else {
                        folderless.push(item);
                    }
                }

                if (!action.IsFolder && action.Type_Code === 'EVENT') {
                    const parentId = action.Parent_Id;

                    const folderIndex = folders.findIndex(
                        (folder) => folder && folder.key === parentId
                    );

                    const item = {
                        isButton: action.IsButton,
                        key: action.Id,
                        index: action.ChildIndex,
                        label: action.Name[language as LANGUAGES],
                        // icon: <LazyIcon icon={action.Icon} />,
                        disabled:
                            (!action?.Handler?.IsForNoneObjects && !hasSelected) ||
                            action.IsDisabled ||
                            (action.IsSingle && selectedRowKeys.length > 1),
                        onClick: () =>
                            onClickAction(
                                {
                                    ...action,
                                    Handler_Code: 'RaiseEvent',
                                    Handler: { ...action.Handler, Code: 'RaiseEvent' }
                                },
                                isMobile ? selectedRowKeys : undefined,
                                {
                                    event_type: action.EventType_Code
                                }
                            )
                    };

                    if (isMobile && item.isButton) {
                        folderless.push(item);
                    } else if (folderIndex !== -1) {
                        folders[folderIndex]?.children?.push(item);
                    } else {
                        folderless.push(item);
                    }
                }
            }

            return [
                ...folders.sort((a, b) => Number(a?.index) - Number(b?.index)),
                ...folderless.sort((a, b) => Number(a?.index) - Number(b?.index))
            ];
        }, [
            actions,
            isShowActionButtons,
            currentNavItem?.id,
            uiAllowDelete,
            language,
            hasSelected,
            selectedRowKeys.length,
            onClickAction
        ]);

        const reportsMenuItems = useMemo(() => {
            const reports: MenuItem[] = [];

            for (const action of actions ?? []) {
                if (
                    !action.IsVisibleInList ||
                    !action.IsActive ||
                    (action.AllowedForNavItems?.length &&
                        !action.AllowedForNavItems.includes(currentNavItem?.id as string)) || // ID есть всегда - это я подразумеваю
                    ((action.Handler_Code === 'Delete' || action.Handler_Code === 'SoftDelete') &&
                        !uiAllowDelete)
                ) {
                    continue;
                }

                if (!action.IsFolder && action.Type_Code === 'REPORT') {
                    const item = {
                        key: action.Id,
                        index: action.ChildIndex,
                        icon: <LazyIcon icon={action.Icon} />,
                        label: action.Name[language as LANGUAGES],
                        disabled:
                            (!action?.Handler?.IsForNoneObjects && !hasSelected) ||
                            action.IsDisabled,
                        onClick: async () => {
                            const response = await onClickAction(
                                action,
                                isMobile ? selectedRowKeys : undefined
                            );

                            if (response) {
                                navigateAfterRun(response, action, filters);
                            }
                        }
                    };

                    reports.push(item);
                }

                if (!action.IsFolder && action.Type_Code === 'ANALYTIC') {
                    const item = {
                        key: action.Id,
                        index: action.ChildIndex,
                        icon: <LazyIcon icon={action.Icon} />,
                        label: action.Name[language as LANGUAGES],
                        disabled:
                            (!action?.Handler?.IsForNoneObjects && !hasSelected) ||
                            action.IsDisabled,
                        onClick: async () => {
                            const response = await onClickAction(
                                action,
                                isMobile ? selectedRowKeys : undefined
                            );

                            if (response) {
                                navigateAfterRun(
                                    {
                                        session_id: '',
                                        request_id: '',
                                        status_code: 200,
                                        run: response.run
                                    },
                                    action,
                                    filters
                                );
                            }
                        }
                    };

                    reports.push(item);
                }
            }

            return reports.sort((a, b) => Number(a?.index) - Number(b?.index));
        }, [
            actions,
            currentNavItem?.id,
            uiAllowDelete,
            language,
            hasSelected,
            onClickAction,
            navigateAfterRun,
            filters
        ]);

        const followsMenuItems = useMemo(() => {
            const follows: MenuItem[] = [];

            for (const action of actions ?? []) {
                if (
                    !action.IsVisibleInList ||
                    !action.IsActive ||
                    (action.AllowedForNavItems?.length &&
                        !action.AllowedForNavItems.includes(currentNavItem?.id as string)) || // ID есть всегда - это я подразумеваю
                    ((action.Handler_Code === 'Delete' || action.Handler_Code === 'SoftDelete') &&
                        !uiAllowDelete) ||
                    (!isShowFramedAssocInFollowsMenu && // пропускаем фреймы, если задан флаг это разрешающий
                        action.IsVisibleInFrame)
                ) {
                    continue;
                }

                if (!action.IsFolder && action.Type_Code === 'ASSOTIATION') {
                    const item = {
                        key: action.Id,
                        index: action.ChildIndex,
                        icon: <LazyIcon icon={action.Icon} />,
                        label: action.Name[language as LANGUAGES],
                        disabled:
                            (!action?.Handler?.IsForNoneObjects && !hasSelected) ||
                            action.IsDisabled,
                        onClick: async () => {
                            let filters: Filter[] = [];
                            const metaField = fields?.find((field) => field.FieldName === 'Id');

                            let actionResponse = {
                                session_id: '',
                                request_id: '',
                                status_code: 200
                            };

                            if (action.Association) {
                                const association = await metaStore.getAssociation({
                                    association: action.Association.Code,
                                    ids: selectedRowKeys
                                });

                                filters = [
                                    {
                                        column: 'id',
                                        operator: 'in_list',
                                        value: association?.ids
                                    }
                                ];
                            } else if (action.Handler) {
                                const response = await onClickAction(
                                    action,
                                    isMobile ? selectedRowKeys : undefined
                                );

                                if (action.IsNavigationFirst) return;

                                if (response) {
                                    actionResponse = response;
                                }
                            } else {
                                filters = [
                                    {
                                        column: 'id',
                                        operator: 'in_list',
                                        value: selectedRowKeys
                                    }
                                ];
                            }

                            if (actionResponse) {
                                navigateAfterRun(
                                    actionResponse,
                                    action,
                                    filters,
                                    metaField ? [metaField] : []
                                );
                            }
                        }
                    };

                    follows.push(item);
                }
            }

            return follows.sort((a, b) => Number(a?.index) - Number(b?.index));
        }, [
            onClickAction,
            actions,
            currentNavItem?.id,
            uiAllowDelete,
            language,
            hasSelected,
            fields,
            navigateAfterRun,
            selectedRowKeys,
            isShowFramedAssocInFollowsMenu
        ]);

        if (isMobile) {
            return (
                <MobileCardToolbar
                    actionsMenuItems={actionsMenuItems}
                    reportsMenuItems={reportsMenuItems}
                    followsMenuItems={followsMenuItems}
                    // buttonActions={actionsMenuItems}
                />
            );
        }

        return (
            <SmartTablePageHeaderToolbarDesktop
                meta={meta}
                selectedRowKeys={selectedRowKeys}
                hasSelected={hasSelected}
                filters={filters}
                onClickAction={onClickAction}
                actionsMenuItems={actionsMenuItems}
                reportsMenuItems={reportsMenuItems}
                followsMenuItems={followsMenuItems}
                currentNavItem={currentNavItem}
                {...props}
            />
        );
    }
);
