import { Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonWithTooltips } from 'ui';
import { LogMessage } from 'modules/services/backend-api/generated_smart_context';

import { LogMessagesTable } from '../LogMessagesTable/LogMessagesTable';

export interface ILogMessagesModal {
    open: boolean;
    // logs: LogsResponse;
    logs: { log_messages?: LogMessage[]; created_objects?: LogMessage[] };
    onCancel?: (e?: React.SyntheticEvent) => void;
    hardDuration?: number;
}

export const LogMessagesModal = observer<ILogMessagesModal>(
    ({ logs, onCancel, hardDuration, open }) => {
        // const isMessagesIsset = logMessages.length > 0;

        const { t } = useTranslation();

        return (
            <Modal
                open={open}
                onCancel={onCancel}
                destroyOnClose
                // onClose={onCancel}
                afterClose={onCancel}
                footer={
                    <ButtonWithTooltips id="close" key="close" onClick={onCancel}>
                        {t('close')}
                    </ButtonWithTooltips>
                }
                title={t('messages')}
                width="75%"
                centered
            >
                <LogMessagesTable
                    logMessages={logs?.log_messages}
                    createdObjects={logs?.created_objects}
                    hardDuration={hardDuration}
                />
            </Modal>
        );
    }
);

interface ILogMessagesModalOuter {
    logMessages?: LogMessage[];
    createdObjects?: LogMessage[];
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

export const LogMessagesModalOuter = observer<ILogMessagesModalOuter>(
    ({ logMessages = [], createdObjects, open, setOpen }) => {
        const { t } = useTranslation();

        return (
            <Modal
                open={open}
                onCancel={() => setOpen(false)}
                footer={
                    <ButtonWithTooltips id="close" key="close" onClick={() => setOpen(false)}>
                        {t('close')}
                    </ButtonWithTooltips>
                }
                title={t('messages')}
                width="75%"
                centered
            >
                <LogMessagesTable logMessages={logMessages} createdObjects={createdObjects} />
            </Modal>
        );
    }
);
