import { useTranslation } from 'react-i18next';

import { ReactComponent as ITVecturaTextIcon } from 'assets/icons/logo-text.svg';

export const LeftMenuHeaderLabel: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div id="logo-container" className="logo-container">
            <div className="vertical-line"></div>
            <div className="logo-text">
                <ITVecturaTextIcon id="logo-title" className="logo-title" />
                <span className="logo-description">{t('siteDescription')}</span>
            </div>
        </div>
    );
};
