import { AutoComplete, Button, Flex, Input, SelectProps, Typography } from 'antd';
import { toJS } from 'mobx';
import { memo, MouseEventHandler, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useList, useMedia, useUpdateEffect } from 'react-use';

import { fnv1aHash } from 'smart/utils';
import { useStoreNavigate } from 'utils/store';
import { metaStore } from 'utils/store/MetaStore';
import { StarFilled, StarOutlined } from '@ant-design/icons';
import { PlainObject } from '@gilbarbara/types';
import { useSpringboardFavorites } from '../../../../../../../smart/modules/Springboard/utils/hooks';

const Title: React.FC<Readonly<{ title?: string }>> = (props) => (
    <Flex align="center" justify="space-between">
        {props.title}
        {/* <a
            aria-disabled
            href="https://www.google.com/search?q=antd"
            target="_blank"
            rel="noopener noreferrer"
        >
            more
        </a> */}
    </Flex>
);

const { Text } = Typography;

type AppSearchOptionType = NonNullable<SelectProps['options']>[number] & {
    valueForSearch?: string;
};

interface AppSearchProps {
    style?: React.CSSProperties;
    className?: string;
}

export const AppSearch = memo<AppSearchProps>(({ style, className }) => {
    const {
        i18n: { language }
    } = useTranslation();
    const storeNavigate = useStoreNavigate();
    const isBigMobile = useMedia('(max-width: 470px)');
    const metaMenus = toJS(metaStore.meta.get('all')?.menu);
    const metaMetas = toJS(metaStore.meta.get('InfoMeta')?.select)?.objects;
    const [favorites, setFavorites] = useSpringboardFavorites();
    const [search, setSearch] = useState('');

    const getAddToFavoritesHandler: (m: PlainObject) => MouseEventHandler = useCallback(
        (m) => (e) => {
            e.stopPropagation();
            setFavorites((prev) => {
                const setIds = new Set(prev.map((f) => f.Id));

                if (setIds.has(m.id) || setIds.has(m.Id)) {
                    return prev.filter((f) => f.Id !== m.id && f.Id !== m.Id);
                }
                return [...prev, { ...m, Id: m.id ?? m.Id }];
            });
        },
        []
    );

    const metaOptions: AppSearchOptionType[] =
        metaMetas
            ?.map((m) => ({
                key: m?.Id,
                value: m?.Id,
                valueForSearch: m?.PluralName?.[language] || m?.Code,
                label: (
                    <Flex justify={'space-between'}>
                        <Text
                            style={{ padding: 0 }}
                            onClick={() =>
                                storeNavigate(
                                    { pathname: `/other/${m?.Code}` },
                                    {
                                        state: {
                                            // filterString,
                                            cacheKey: fnv1aHash(
                                                `${m?.Id}_${m?.PluralName?.[language]}`
                                            ),
                                            pageTitle: m?.PluralName?.[language] || m?.Code
                                        }
                                    }
                                )
                            }
                        >
                            {m?.PluralName?.[language] || m?.Code}
                        </Text>

                        <Button size={'small'} type={'text'} onClick={getAddToFavoritesHandler(m)}>
                            {favorites.find((f) => f.Id === m.Id) ? (
                                <StarFilled style={{ color: 'gold' }} />
                            ) : (
                                <StarOutlined />
                            )}
                        </Button>
                    </Flex>
                )
            }))
            .sort(
                (a, b) =>
                    (a.valueForSearch as string)?.localeCompare(
                        b.valueForSearch ?? '',
                        language === 'tech' ? 'en' : language // если tech то падает
                    ) ?? 0
            ) ?? [];

    const menuOptions: AppSearchOptionType[] =
        metaMenus?.items
            .filter((m) => !m.is_hidden && !m.is_folder)
            ?.map((m) => {
                const label = m.name?.[language];
                const [pathname, filterString] = m.path.split('?');
                const cacheKey = fnv1aHash(`${m.id}_${label}`);

                const state = filterString
                    ? {
                          filterString,
                          cacheKey,
                          pageTitle: label
                      }
                    : {
                          cacheKey,
                          pageTitle: label
                      };

                return {
                    key: m.id,
                    value: m.id,
                    valueForSearch: m.name?.[language],
                    label: (
                        <Flex justify={'space-between'}>
                            <Text
                                style={{ padding: 0 }}
                                onClick={() => storeNavigate({ pathname }, { state })}
                            >
                                {label}
                            </Text>

                            <Button
                                size={'small'}
                                type={'text'}
                                onClick={getAddToFavoritesHandler(m as unknown as PlainObject)}
                            >
                                {favorites.find((f) => f.id === m.id) ? (
                                    <StarFilled style={{ color: 'gold' }} />
                                ) : (
                                    <StarOutlined />
                                )}
                            </Button>
                        </Flex>
                    )
                };
            })
            .sort(
                (a, b) =>
                    (a.valueForSearch as string)?.localeCompare(
                        b.valueForSearch ?? '',
                        language === 'tech' ? 'en' : language
                    ) ?? 0
            ) ?? [];

    const initialOptions: AppSearchOptionType[] = [
        {
            label: <Title title="Menu" />,
            options: menuOptions
        },
        {
            label: <Title title="Meta" />,
            options: metaOptions
        }
        // {
        //     label: <Title title="By context" />,
        //     options: []
        // }
    ];

    const [options, optionsMake] = useList<AppSearchOptionType>(initialOptions);
    const [selectedOption, setSelectedOption] = useState<AppSearchOptionType>();

    const handleSearch = (search: string) => {
        setSearch(search);
        if (search) {
            optionsMake.set(() =>
                initialOptions.map((o) => ({
                    ...o,
                    options: o.options?.filter((oc: AppSearchOptionType) =>
                        oc.valueForSearch?.toLowerCase()?.includes(search?.toLowerCase())
                    )
                }))
            );
        } else {
            optionsMake.set(initialOptions);
        }
    };

    useUpdateEffect(() => {
        handleSearch(selectedOption?.valueForSearch ?? search);
    }, [favorites.length]);

    return (
        <AutoComplete
            popupClassName="certain-category-search-dropdown"
            popupMatchSelectWidth={isBigMobile ? undefined : 400}
            virtual={false}
            style={{ width: 250, ...style }}
            className={className}
            options={options}
            value={selectedOption?.valueForSearch}
            onChange={(value, option) => setSelectedOption(option)}
            onSearch={handleSearch}
            size={isBigMobile ? 'large' : 'middle'}
        >
            {language === 'ru' ? (
                <Input.Search
                    size={isBigMobile ? 'large' : 'middle'}
                    placeholder="Поиск по IT Vectura..."
                />
            ) : (
                <Input.Search
                    size={isBigMobile ? 'large' : 'middle'}
                    placeholder="Search in IT Vectura..."
                />
            )}
        </AutoComplete>
    );
});
