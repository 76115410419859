import * as AntdIcons from '@ant-design/icons';
import {
    CaretDownOutlined,
    CloseCircleOutlined,
    DeliveredProcedureOutlined,
    EditOutlined,
    FilePdfOutlined,
    MenuOutlined,
    ReloadOutlined,
    SaveOutlined,
    ShareAltOutlined
} from '@ant-design/icons';
import { PlainObject } from '@gilbarbara/types';
import { Dropdown, Flex, List, MenuProps, Space } from 'antd';
import { toJS } from 'mobx';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAsync, useMedia, useWindowSize } from 'react-use';

import { Action, Handler, MetaField } from 'modules/services/backend-api/generated_info';
import { Response } from 'modules/services/backend-api/generated_api';
// import { LogMessage } from 'modules/services/backend-api/generated_smart_context';
import { Filter } from 'modules/supabase/utils/supabaseClient';
import { FilterField } from 'smart/modules/SmartTablePage/components/SmartTableFilterMenu/types';
import { HelpIcon, LazyIcon } from 'smart/ui';
import { fnv1aHash } from 'smart/utils';
import { useHandlerRun } from 'smart/utils/hooks';
import { ButtonWithTooltips } from 'ui';
// import { useNotifications } from 'utils/hooks';
import { LANGUAGES } from 'utils/i18n/i18n';
import { useStoreNavigate } from 'utils/store';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';
import { useNotifications } from 'utils/hooks';
import { isDefined } from 'is-lite/exports';
import { Toast } from 'antd-mobile';
import { HandlerRunModal } from '../../../../components';

type MenuItem = NonNullable<MenuProps['items']>[number] & { index?: number; children?: MenuItem[] };

type AntdIconsType = Omit<
    typeof AntdIcons,
    'IconProvider' | 'setTwoToneColor' | 'getTwoToneColor' | 'createFromIconfontCN'
>;

interface SmartDetailPageHeaderToolbarProps {
    meta: string;
    id: string;
    data: IObjectWithId;
    mode: string;
    loading?: boolean;
    onSave: (saveAndClose?: boolean) => Promise<any>;
    onCancel: () => any;
    onRefresh: () => any;
    onEdit: () => any;
    isDataChanged?: boolean;
}

export interface IHandlerWithId extends Handler {
    Id: string;
}

// TODO: вынести в components/SmartToolbar для переиспользования
export const SmartDetailPageHeaderToolbar = memo<SmartDetailPageHeaderToolbarProps>(
    ({ meta, id, data, mode, onSave, onCancel, onEdit, onRefresh, loading, isDataChanged }) => {
        const location = useLocation();
        const {
            t,
            i18n: { language }
        } = useTranslation();
        const storeNavigate = useStoreNavigate();
        const isBigMobile = useMedia('(max-width: 480px)');

        const metaData = toJS(metaStore.meta.get(meta));
        const actions = metaData?.info?.Actions?.sort((action) =>
            action.Type_Code === 'FOLDER' ? -1 : 1
        );
        const fields = metaData?.info?.Fields;
        const currentNavItem = toJS(metaStore.meta.get('all')?.routes)?.find(
            (route) =>
                route.path ===
                `${location.pathname}${
                    location.state?.filterString ? `?${location.state?.filterString}` : ''
                }`
        );
        const mandatoryFields = useMemo(
            () =>
                fields?.reduce((acc, f) => {
                    const obj = acc;
                    if (f.IsMandatory) {
                        obj[f.FieldName] = f;
                    }
                    return obj;
                }, {} as PlainObject<MetaField>) ?? {},
            [fields]
        );
        // console.log(mandatoryFields);
        const isMetaReadOnly = metaData?.info?.IsReadOnly || false;
        const uiAllowSave = metaData?.info?.UiAllowSave;
        const uiAllowEdit = metaData?.info?.UiAllowEdit;
        const uiAllowDelete = metaData?.info?.UiAllowDelete;
        const uiAllowSelect = metaData?.info?.UiAllowSelect;
        const metaDescription = metaData?.info?.Description?.[language];

        // REESPONSIVE
        const { width } = useWindowSize();
        const isShowActionButtons = width > 660;
        const isShowActionButtonsLabel = width > 980;
        const isSmallTablet = useMedia('(max-width: 620px)');
        const isMiddleTablet = useMedia('(max-width: 720px)');
        const isSmallMobile = useMedia('(max-width: 390px)');

        const { run } = useHandlerRun();

        const { value: isShowFramedAssocInFollowsMenu } = useAsync(
            async () =>
                metaStore.getParam({
                    param_name: 'SHOW_FRAMED_ASSOC_IN_FOLLOWS_MENU',
                    default_value: false
                }),
            []
        );

        const [handlerModal, setHandlerModal] = useState(false);
        const [handlerModalData, setHandlerModalData] = useState<Action | null>(null);
        const [handlerModalHardIds, setHandlerModalHardIds] = useState<string[] | undefined>();

        const handleNavigateBeforeAction = useCallback(
            ({
                action,
                filters,
                metaFields,
                hardIds,
                hardArgs
            }: {
                action: Action;
                filters: FilterField[] | Filter[];
                metaFields?: MetaField[];
                hardIds?: string[];
                hardArgs?: PlainObject;
            }) => {
                const pathnameLastElem = location.pathname.split('/').at(-1)?.trim();

                let pageTitle = `${location.state?.pageTitle || pathnameLastElem} (${t(
                    'filtered'
                )})`;
                let pathname = location.pathname;
                // используем просутю hash функцию по методу fnv1 для создания уникально ключа кэша страницы
                let cacheKey = fnv1aHash(`${pathnameLastElem}_filtered_${JSON.stringify(filters)}`);

                if (action.NavItem && action.NavItem.Path) {
                    if (action.NavItem.Name) {
                        pageTitle = `${
                            action.NavItem.Name[language] || action.NavItem.Name.en
                        } (${t('filtered')})`;
                    }
                    // pathname = action.NavItem.Path;
                    pathname = action.NavItem.Path.includes('?')
                        ? action.NavItem.Path.split('?')[0]
                        : action.NavItem.Path; // TODO: нужно ли прилетающие тут параметры подмешивать в фильтры ??
                    // используем просутю hash функцию по методу fnv1
                    cacheKey = fnv1aHash(`${action.NavItem.Id}_${JSON.stringify(filters)}`);
                }

                // debugger;
                storeNavigate(
                    { pathname },
                    {
                        state: {
                            filters,
                            pageTitle,
                            cacheKey,
                            fromMeta: action.Meta_Code,
                            associationCode: action.Association_Code,
                            requestData: {
                                action,
                                ids: hardIds ?? [id],
                                args: hardArgs ?? {}
                            },
                            metaFields
                        }
                    }
                );
            },
            [language, location.pathname, location.state?.pageTitle, id, storeNavigate, t]
        );

        const actionClickHandler = useCallback(
            async (
                action: Action,
                hardIds?: string[],
                hardArgs?: PlainObject
            ): Promise<Response | undefined> => {
                if (action.IsNavigationFirst) {
                    handleNavigateBeforeAction({
                        action,
                        hardArgs,
                        hardIds,
                        filters: []
                    });
                } else if (action.Handler_Code) {
                    const isShowModal =
                        action.Handler &&
                        ((action.Handler.ChildParams?.length &&
                            !action.Handler?.ChildParams?.every((param) => !param.IsRequested)) ||
                            action.Handler_Code === 'MassUpdate');
                    if (isShowModal) {
                        setHandlerModal(true);
                        setHandlerModalData({
                            ...action,
                            Handler: { ...action.Handler, Id: action.Id! } as IHandlerWithId
                        });
                        setHandlerModalHardIds(hardIds);
                    } else {
                        const response = await run({
                            Action_Id: action.Id!,
                            meta: action.Meta_Code,
                            ids: hardIds || [id],
                            handler: action.Handler_Code,
                            args: hardArgs || {}
                        });

                        onRefresh();

                        return response;
                    }
                }

                return undefined;
            },
            [handleNavigateBeforeAction, run, id, onRefresh]
        );

        const handleNavigateAfterAction = useCallback(
            (
                actionResponse: Response,
                action: Action,
                filters: FilterField[] | Filter[],
                metaFields?: MetaField[]
            ) => {
                if (!uiAllowSelect) return;

                const pathnameLastElem = location.pathname.split('/').at(-2)?.trim();

                let pageTitle = `${location.state?.pageTitle || pathnameLastElem} (${t(
                    'filtered'
                )})`;
                let pathname = location.pathname.split('/').slice(0, -1).join('/');
                // используем просутю hash функцию по методу fnv1 для создания уникально ключа кэша страницы
                let cacheKey = fnv1aHash(`${pathnameLastElem}_filtered_${JSON.stringify(filters)}`);

                if (action.NavItem && action.NavItem.Path) {
                    if (action.NavItem.Name) {
                        pageTitle = `${
                            action.NavItem.Name[language] || action.NavItem.Name.en
                        } (${t('filtered')})`;
                    }

                    pathname = action.NavItem.Path.includes('?')
                        ? action.NavItem.Path.split('?')[0]
                        : action.NavItem.Path; // TODO: нужно ли прилетающие тут параметры подмешивать в фильтры ??
                    // используем просутю hash функцию по методу fnv1
                    cacheKey = fnv1aHash(`${action.NavItem.Id}_${JSON.stringify(filters)}`);
                }

                // debugger;
                storeNavigate(
                    { pathname },
                    {
                        state: {
                            filters,
                            pageTitle,
                            cacheKey,
                            fromMeta: action.Meta_Code,
                            associationCode: action.Association_Code,
                            responseData: { ...actionResponse.run?.[0]?.Result, ids: [id] },
                            metaFields
                        }
                    }
                );
            },
            [
                id,
                language,
                location.pathname,
                location.state?.pageTitle,
                storeNavigate,
                t,
                uiAllowSelect
            ]
        );

        const actionButtons = useMemo(() => {
            if (actions) {
                return actions
                    .filter((action) => {
                        const cond =
                            action.IsButton &&
                            action.IsVisibleInForm &&
                            action.IsActive &&
                            (!action.AllowedForNavItems?.length ||
                                action.AllowedForNavItems?.includes(currentNavItem?.id as string));

                        if (!uiAllowDelete) {
                            return (
                                cond &&
                                action.Handler_Code !== 'Delete' &&
                                action.Handler_Code !== 'SoftDelete'
                            );
                        }

                        return cond; // ID есть всегда - это я подразумеваю
                    })
                    .map((action) => {
                        return (
                            <ButtonWithTooltips
                                type={'default'}
                                className={''}
                                id={action.Id || ''}
                                onClick={() => actionClickHandler(action)}
                                icon={<LazyIcon icon={action.Icon} />}
                                tooltipTitle={
                                    !isShowActionButtonsLabel ? action.Name?.[language] : undefined
                                }
                            >
                                {isShowActionButtonsLabel ? action.Name?.[language] : null}
                            </ButtonWithTooltips>
                        );
                    });
            }

            return null;
        }, [
            actions,
            currentNavItem?.id,
            uiAllowDelete,
            isShowActionButtonsLabel,
            language,
            actionClickHandler
        ]);

        const actionsMenuItems = useMemo(() => {
            const folders: MenuItem[] = [];
            const folderless: MenuItem[] = [];

            for (const action of actions ?? []) {
                if (
                    !action.IsVisibleInList ||
                    (isShowActionButtons && action.IsButton) ||
                    !action.IsActive ||
                    (action.AllowedForNavItems?.length &&
                        !action.AllowedForNavItems.includes(currentNavItem?.id as string)) || // ID есть всегда - это я подразумеваю
                    ((action.Handler_Code === 'Delete' || action.Handler_Code === 'SoftDelete') &&
                        !uiAllowDelete)
                ) {
                    continue;
                }

                if (action.IsFolder || action.Type_Code === 'FOLDER') {
                    folders.push({
                        key: action.Id!,
                        index: action.ChildIndex,
                        icon: <LazyIcon icon={action.Icon} />,
                        label: action.Name[language as LANGUAGES] || action.Id,
                        disabled: action.IsDisabled,
                        children: []
                    });
                }

                if (!action.IsFolder && action.Type_Code === 'HANDLER') {
                    const parentId = action.Parent_Id;

                    const folderIndex = folders.findIndex(
                        (folder) => folder && folder.key === parentId
                    );

                    const item = {
                        key: action.Name[language as LANGUAGES] || action.Type_Code,
                        index: action.ChildIndex,
                        // icon: <LazyIcon icon={action.Icon} />,
                        label: action.Name[language as LANGUAGES] || action.Id,
                        disabled: action.IsDisabled,
                        onClick: async () => actionClickHandler(action)
                    };

                    if (folderIndex !== -1) {
                        folders[folderIndex]?.children?.push(item);
                    } else {
                        folderless.push(item);
                    }
                }

                if (!action.IsFolder && action.Type_Code === 'EVENT') {
                    const parentId = action.Parent_Id;

                    const folderIndex = folders.findIndex(
                        (folder) => folder && folder.key === parentId
                    );

                    const item = {
                        key: action.Name[language as LANGUAGES] || action.Type_Code,
                        index: action.ChildIndex,
                        // icon: <LazyIcon icon={action.Icon} />,
                        label: action.Name[language as LANGUAGES] || action.Id,
                        disabled: action.IsDisabled,
                        onClick: () =>
                            actionClickHandler(
                                {
                                    ...action,
                                    Handler_Code: 'RaiseEvent',
                                    Handler: { ...action.Handler, Code: 'RaiseEvent' }
                                },
                                undefined,
                                {
                                    event_type: action.EventType_Code
                                }
                            )
                    };

                    if (folderIndex !== -1) {
                        folders[folderIndex]?.children?.push(item);
                    } else {
                        folderless.push(item);
                    }
                }
            }

            return [
                ...folders.sort((a, b) => Number(a?.index) - Number(b?.index)),
                ...folderless.sort((a, b) => Number(a?.index) - Number(b?.index))
            ];
        }, [
            actionClickHandler,
            actions,
            currentNavItem?.id,
            isShowActionButtons,
            language,
            uiAllowDelete
        ]);

        const reportsMenuItems = useMemo(() => {
            const reports: MenuItem[] = [];

            for (const action of actions ?? []) {
                if (
                    !action.IsVisibleInForm ||
                    !action.IsActive ||
                    (action.AllowedForNavItems?.length &&
                        !action.AllowedForNavItems.includes(currentNavItem?.id as string)) || // ID есть всегда - это я подразумеваю
                    ((action.Handler_Code === 'Delete' || action.Handler_Code === 'SoftDelete') &&
                        !uiAllowDelete)
                ) {
                    continue;
                }

                if (!action.IsFolder && action.Type_Code === 'REPORT') {
                    const item = {
                        key: action.Name[language as LANGUAGES] || action.Type_Code,
                        index: action.ChildIndex,
                        icon: <LazyIcon icon={action.Icon} />,
                        label: action.Name[language as LANGUAGES] || action.Id,
                        disabled: action.IsDisabled,
                        onClick: async () => {
                            const response = await actionClickHandler(action);

                            if (response) handleNavigateAfterAction(response, action, []);
                        }
                    };

                    reports.push(item);
                }

                if (!action.IsFolder && action.Type_Code === 'ANALYTIC') {
                    const item = {
                        key: action.Name[language as LANGUAGES] || action.Type_Code,
                        index: action.ChildIndex,
                        icon: <LazyIcon icon={action.Icon} />,
                        label: action.Name[language as LANGUAGES] || action.Id,
                        disabled: action.IsDisabled,
                        onClick: async () => {
                            const response = await actionClickHandler(action);

                            if (response) {
                                handleNavigateAfterAction(
                                    {
                                        session_id: '',
                                        request_id: '',
                                        status_code: 200,
                                        run: response.run
                                    },
                                    action,
                                    []
                                );
                            }
                        }
                    };

                    reports.push(item);
                }
            }

            return reports.sort((a, b) => Number(a?.index) - Number(b?.index));
        }, [
            actionClickHandler,
            actions,
            currentNavItem?.id,
            handleNavigateAfterAction,
            language,
            uiAllowDelete
        ]);

        const followsMenuItems = useMemo(() => {
            const follows: MenuItem[] = [];

            for (const action of actions ?? []) {
                if (
                    !action.IsVisibleInForm ||
                    !action.IsActive ||
                    (action.AllowedForNavItems?.length &&
                        !action.AllowedForNavItems.includes(currentNavItem?.id as string)) || // ID есть всегда - это я подразумеваю
                    ((action.Handler_Code === 'Delete' || action.Handler_Code === 'SoftDelete') &&
                        !uiAllowDelete) ||
                    (!isShowFramedAssocInFollowsMenu && // пропускаем фреймы, если задан флаг это разрешающий
                        action.IsVisibleInFrame)
                ) {
                    continue;
                }

                if (!action.IsFolder && action.Type_Code === 'ASSOTIATION') {
                    const item = {
                        key: action.Name[language as LANGUAGES] || action.Type_Code,
                        index: action.ChildIndex,
                        icon: <LazyIcon icon={action.Icon} />,
                        label: action.Name[language as LANGUAGES] || action.Id,
                        disabled: action.IsDisabled,
                        onClick: async () => {
                            let filters: Filter[] = [];
                            const metaField = fields?.find((field) => field.FieldName === 'Id');

                            let actionResponse = {
                                session_id: '',
                                request_id: '',
                                status_code: 200
                            };

                            if (action.Association) {
                                const association = await metaStore.getAssociation({
                                    association: action.Association.Code,
                                    ids: [`${id}`]
                                });

                                filters = [
                                    {
                                        column: 'id',
                                        operator: 'in_list',
                                        value: association?.ids
                                    }
                                ];
                            } else if (action.Handler) {
                                const response = await actionClickHandler(action);

                                if (action.IsNavigationFirst) return;

                                if (response) {
                                    actionResponse = response;
                                }
                            } else {
                                filters = [
                                    {
                                        column: 'id',
                                        operator: 'in_list',
                                        value: [`${id}`]
                                    }
                                ];
                            }

                            if (actionResponse) {
                                handleNavigateAfterAction(
                                    actionResponse,
                                    action,
                                    filters,
                                    metaField ? [metaField] : []
                                );
                            }
                        }
                    };

                    follows.push(item);
                }
            }

            return follows.sort((a, b) => Number(a?.index) - Number(b?.index));
        }, [
            actionClickHandler,
            actions,
            currentNavItem?.id,
            uiAllowDelete,
            language,
            fields,
            handleNavigateAfterAction,
            id
        ]);

        const { modal } = useNotifications();

        const validate = useCallback(
            async (onSave: (saveAndClose?: boolean) => Promise<any>, saveAndClose?: boolean) => {
                let validated = true;
                const fieldsToFill: MetaField[] = [];
                const mandatoryFieldNames = Object.keys(mandatoryFields);

                // console.log(data, mandatoryFieldNames);

                for (const key of mandatoryFieldNames) {
                    if (!isDefined(data[key])) {
                        validated = false;
                        fieldsToFill.push(mandatoryFields[key]);
                    }
                }

                if (validated) {
                    await onSave(saveAndClose);
                } else {
                    modal.error({
                        centered: true,
                        title: t('is_mandatory_error'),
                        content: (
                            <List
                                bordered={false}
                                dataSource={fieldsToFill}
                                renderItem={(item) => (
                                    <List.Item
                                        style={{ borderBlockEnd: '1px solid rgba(0,0,0,.05)' }}
                                    >
                                        {item.Name?.[language] ?? item.FieldName}
                                    </List.Item>
                                )}
                            />
                        )
                    });
                }
            },
            [data, language, mandatoryFields, modal, t]
        );

        const handleSave = useCallback(async () => {
            try {
                await validate(onSave);
            } catch (error) {
                // TODO: notifications
                console.error(error);
            }
        }, [validate, onSave]);

        const handleSaveAndClose = useCallback(async () => {
            try {
                await validate(onSave, true);
            } catch (error) {
                // TODO: notifications
                console.error(error);
            }
        }, [validate, onSave]);

        const handleEdit = useCallback(() => {
            try {
                onEdit();
            } catch (error) {
                // TODO: notifications
                console.error(error);
            }
        }, [onEdit]);

        const ids = useMemo(() => [id], [id]);

        return (
            <Flex justify="space-between" className="smart_table__header">
                <Space.Compact className="smart_table__header_left">
                    {isBigMobile ? (
                        <></>
                    ) : mode === 'edit' ? (
                        <>
                            {uiAllowSave && (
                                <ButtonWithTooltips
                                    onClick={handleSaveAndClose}
                                    id="save_and_close"
                                    disabled={loading || !isDataChanged}
                                >
                                    {isMiddleTablet ? (
                                        <DeliveredProcedureOutlined />
                                    ) : (
                                        t('save_and_close')
                                    )}
                                </ButtonWithTooltips>
                            )}
                            {uiAllowSave && (
                                <ButtonWithTooltips
                                    onClick={handleSave}
                                    type="default"
                                    className=""
                                    id="save"
                                    disabled={loading || !isDataChanged}
                                >
                                    {isMiddleTablet ? <SaveOutlined /> : t('save')}
                                </ButtonWithTooltips>
                            )}
                            <ButtonWithTooltips
                                onClick={onCancel}
                                type="default"
                                className=""
                                id="cancel"
                                disabled={loading}
                            >
                                {isMiddleTablet ? <CloseCircleOutlined /> : t('cancel')}
                            </ButtonWithTooltips>
                        </>
                    ) : (
                        <>
                            {uiAllowEdit && !isMetaReadOnly ? (
                                <ButtonWithTooltips
                                    onClick={handleEdit}
                                    id="edit"
                                    disabled={loading}
                                >
                                    {isMiddleTablet ? <EditOutlined /> : t('edit')}
                                </ButtonWithTooltips>
                            ) : null}
                        </>
                    )}
                    {/* ### Дейсвия (мета) ### */}
                    {actionsMenuItems?.length ? (
                        <Dropdown
                            menu={{ items: actionsMenuItems }}
                            trigger={['click']}
                            placement="bottomLeft"
                            disabled={mode === 'edit'}
                            destroyPopupOnHide
                        >
                            <ButtonWithTooltips type={'default'} className={''} id="actions">
                                {isSmallTablet ? <MenuOutlined /> : t('actions')}
                                {/* {!isSmallMobile && <CaretDownOutlined />} */}
                                <CaretDownOutlined />
                            </ButtonWithTooltips>
                        </Dropdown>
                    ) : null}

                    {/* ### Отчеты (мета) ### */}
                    {reportsMenuItems?.length ? (
                        <Dropdown
                            menu={{ items: reportsMenuItems }}
                            trigger={['click']}
                            placement="bottomLeft"
                            destroyPopupOnHide
                        >
                            <ButtonWithTooltips type={'default'} className={''} id="reports">
                                {isSmallTablet ? <FilePdfOutlined /> : t('reports')}
                                {/* {!isSmallMobile && <CaretDownOutlined />} */}
                                <CaretDownOutlined />
                            </ButtonWithTooltips>
                        </Dropdown>
                    ) : null}

                    {/* ### Переходы (мета) ### */}
                    {followsMenuItems?.length ? (
                        <Dropdown
                            menu={{ items: followsMenuItems }}
                            trigger={['click']}
                            placement="bottomLeft"
                            destroyPopupOnHide
                        >
                            <ButtonWithTooltips type={'default'} className={''} id="follows">
                                {isSmallTablet ? <ShareAltOutlined /> : t('follows')}
                                {/* {!isSmallMobile && <CaretDownOutlined />} */}
                                <CaretDownOutlined />
                            </ButtonWithTooltips>
                        </Dropdown>
                    ) : null}

                    {/* ### Обновить ### */}
                    <ButtonWithTooltips
                        id="reload"
                        tooltipTitle={t('reload')}
                        tooltipPlacement="top"
                        onClick={onRefresh}
                        icon={<ReloadOutlined />}
                        type={'default'}
                        className={''}
                        disabled={mode === 'edit'}
                    />

                    {/* ### Описание ### */}
                    {metaDescription ? (
                        <HelpIcon
                            text={metaStore.meta.get(meta)?.info?.Description?.[language]}
                            style={{ width: 32 }}
                            iconStyle={{ color: 'rgba(0, 0, 0, 0.88)' }}
                            trigger="click"
                        />
                    ) : null}

                    {actionButtons}
                </Space.Compact>

                <Flex className="smart_table__header_center">
                    {/* TODO: center part of toolbar */}
                    <></>
                </Flex>

                <Space.Compact className="smart_table__header_right">
                    <ButtonWithTooltips
                        tooltipTitle={t('copy_link')}
                        className={''}
                        tooltipPlacement={'bottomLeft'}
                        type={'default'}
                        icon={<LazyIcon icon={'mdiLinkVariant'} />}
                        onClick={() => {
                            navigator.clipboard.writeText(`${window.location.href}`);
                            Toast.show({
                                content: t('copied')
                            });
                        }}
                    />
                </Space.Compact>
                {/* 
                {logs.value.length > 0 && (
                    <LogMessagesModal logMessages={logs.value} onCancel={logs.clear} />
                )} */}

                {handlerModal && (
                    <HandlerRunModal
                        open={handlerModal}
                        onCancel={() => {
                            setHandlerModal(false);
                            setHandlerModalData(null);
                            setHandlerModalHardIds(undefined);
                        }}
                        action={handlerModalData}
                        ids={handlerModalHardIds || ids}
                        onRefresh={onRefresh}
                        metaName={meta}
                        navigateAfterRun={handleNavigateAfterAction}
                        meta={meta}
                        row={data}
                    />
                )}
            </Flex>
        );
    }
);
