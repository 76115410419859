import { memo, useEffect, useState } from 'react';

interface LazyMdiIconProps {
    icon: string;
    size?: number;
    [prop: string]: any;
}

export const LazyMdiIcon = memo<LazyMdiIconProps>(({ icon, size, ...props }) => {
    const [path, setPath] = useState();

    useEffect(() => {
        // import('@mdi/js').then((icons) => {
        //     if (icons[icon]) {
        //         const elem = icons[icon];
        //         setPath(elem.toString());
        //     }
        // });
    }, [icon]);

    if (path) return <MDIcon path={path} size={size || 1} {...props} />;

    return null;
});
