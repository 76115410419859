import { PlainObject } from '@gilbarbara/types';
import dayjs from 'dayjs';
import { isBoolean, isPropertyOf, isString } from 'is-lite/exports';
import { isPlainObject } from 'lodash';
import { MetaField } from 'modules/services/backend-api/generated_info';
import { columnFilterRule } from 'smart/components/SmartTable/SmartTable';
import { UserSpecificFormat } from 'utils/helpers/dates';
import { i18n } from 'utils/i18n/i18n';
import { IObjectWithId } from 'utils/store/MetaStore';
import * as XLSX from 'xlsx';

const renderDate = (date: string) =>
    dayjs(date).locale(i18n.language).local().format(UserSpecificFormat.getDateTimeLocalFormat());

interface IExportDataToExcel {
    metaName: string;
    tableData: IObjectWithId[];
    columns: MetaField[];
}

export const exportDataToExcel = ({ metaName, tableData, columns }: IExportDataToExcel) => {
    const newData: IObjectWithId[] = [];
    const sortedColumns = columns.filter(
        (column) =>
            !column.IsHiddenOnTable &&
            columnFilterRule(
                { dataIndex: column.FieldName },
                0,
                columns.map((column) => ({ ...column, dataIndex: column.FieldName }))
            )
    );

    for (const item of tableData) {
        const filteredItem: Record<string, any> = {};

        for (const column of sortedColumns) {
            const columnTitle = column.Name?.[i18n.language] || i18n.t(column.ColumnName);
            const fieldName = column.FieldName;

            if (isString(column.ValueType) && column.ValueType.includes('coordinate')) {
                const value = item[fieldName];

                filteredItem[columnTitle] =
                    value && value.Latitude && value.Longitude
                        ? `${value.Latitude} ${value.Longitude}`
                        : '';
                continue;
            }

            if (
                fieldName.endsWith('Value') &&
                isPropertyOf(item as PlainObject, fieldName.replace('Value', 'MeasureUnit'))
            ) {
                filteredItem[columnTitle] = `${item[fieldName]} ${
                    item[fieldName.replace('Value', 'MeasureUnit')].Name?.[i18n.language]
                }`;
                continue;
            }

            if (
                fieldName.endsWith('CurrencyValue') &&
                isPropertyOf(item as PlainObject, fieldName.replace('CurrencyValue', 'Currency'))
            ) {
                filteredItem[columnTitle] = `${item[fieldName]} ${
                    item[fieldName.replace('CurrencyValue', 'Currency')].Name?.[i18n.language]
                }`;
                continue;
            }

            // if (
            //     fieldName.endsWith('Value') &&
            //     isPropertyOf(item as PlainObject, fieldName.replace('Value', 'CurrencyValue'))
            // ) {
            //     filteredItem[columnTitle] = `${item[fieldName]} ${
            //         item[fieldName.replace('Value', 'Currency')].Name?.[i18n.language]
            //     }`;
            //     continue;
            // }

            if (
                fieldName.endsWith('Value') &&
                isPropertyOf(item as PlainObject, fieldName.replace('Value', 'Currency'))
            ) {
                filteredItem[columnTitle] = `${item[fieldName]} ${
                    item[fieldName.replace('Value', 'Currency')].Name?.[i18n.language]
                }`;
                continue;
            }

            if (
                fieldName.endsWith('Amount') &&
                isPropertyOf(item as PlainObject, fieldName.replace('Amount', 'Currency'))
            ) {
                filteredItem[columnTitle] = `${item[fieldName]} ${
                    item[fieldName.replace('Amount', 'Currency')].Name?.[i18n.language]
                }`;
                continue;
            }

            if (
                fieldName.endsWith('CostValue') &&
                isPropertyOf(item as PlainObject, fieldName.replace('CostValue', 'Currency'))
            ) {
                filteredItem[columnTitle] = `${item[fieldName]} ${
                    item[fieldName.replace('CostValue', 'Currency')].Name?.[i18n.language]
                }`;
                continue;
            }

            if (fieldName.includes('At')) {
                filteredItem[columnTitle] = renderDate(item[fieldName]);
                continue;
            }

            if (fieldName === 'Name') {
                filteredItem[columnTitle] = item.Name?.[i18n.language];
                continue;
            }

            if (isPlainObject(item[fieldName]) && isPropertyOf(item[fieldName], 'Name')) {
                filteredItem[columnTitle] = item[fieldName]?.Name?.[i18n.language];
                continue;
            }

            if (isBoolean(item[fieldName])) {
                filteredItem[columnTitle] = item[fieldName] ? 'Да' : 'Нет';
                continue;
            }

            filteredItem[columnTitle] = item[fieldName];
        }

        newData.push(filteredItem);
    }

    const dataWS = XLSX.utils.json_to_sheet(newData);

    // A workbook is the name given to an Excel file
    const wb = XLSX.utils.book_new(); // make Workbook of Excel

    // add Worksheet to Workbook
    XLSX.utils.book_append_sheet(wb, dataWS, metaName);

    // export Excel file
    XLSX.writeFile(wb, `${metaName}.xlsx`);
};
