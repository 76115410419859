import { MRT_Localization_AZ } from 'material-react-table/locales/az';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import { MRT_Localization_TR } from 'material-react-table/locales/tr';

import { MRT_Localization_KK } from 'utils/i18n/libs/material-react-table/kk';
import { t } from 'i18next';
import { LANGUAGES, i18n } from '../../i18n';

export const googleTranslateText = async (
    textToTranslate: string,
    fromLanguage: LANGUAGES,
    toLanguage: LANGUAGES
) => {
    const API_KEY = '5eb1a60089msh39649bc46ae963ep181edejsn7f06416dc519';
    const url = `https://deep-translate1.p.rapidapi.com/language/translate/v2`;

    return fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'X-RapidAPI-Key': API_KEY,
            'X-RapidAPI-Host': 'deep-translate1.p.rapidapi.com'
        },
        body: JSON.stringify({
            q: textToTranslate,
            source: fromLanguage,
            target: toLanguage
        })
    })
        .then((res) => res.json())
        .then((res) => res.data.translations.translatedText);
};

export const getMaterialReactTableLocalization = () => {
    switch (i18n.language) {
        case 'en':
            return MRT_Localization_EN;
        case 'tr':
            return MRT_Localization_TR;
        case 'az':
            return MRT_Localization_AZ;
        case 'kk':
            return MRT_Localization_KK;
        default:
            return MRT_Localization_RU;
    }
};
