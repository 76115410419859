import { InfoCircleOutlined } from '@ant-design/icons';
import MarkdownEditor from '@uiw/react-markdown-editor';
import { Flex, Modal, notification } from 'antd';
import { supabaseClient } from 'modules/supabase/contexts/SupabaseContext/SupabaseContext';
import { useSupabaseUser } from 'modules/supabase/utils/hooks/useSupabaseUser';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import rehypeRewrite, { RehypeRewriteOptions } from 'rehype-rewrite';
import { PluggableList } from 'unified';

import { observer } from 'mobx-react-lite';
import { ButtonWithTooltips } from 'ui';
import { i18n } from 'utils/i18n/i18n';
import { modalStores, useStoreNavigate } from 'utils/store';
import { useMedia } from 'react-use';

const handleUpdateChangelog = async (markdown: string) => {
    const { data, error } = await supabaseClient.storage
        .from('public')
        .update('changelog.md', markdown, {
            contentType: 'application/octet-stream',
            upsert: true
        });

    if (error) {
        notification.error({ message: error.message });
        return;
    }

    if (!error) {
        notification.success({ message: i18n.t('saved') });
    }
};

const rehypePlugins: PluggableList = [
    [
        rehypeRewrite,
        {
            rewrite: (node, index, parent) => {
                if (
                    node.type === 'element' &&
                    node.tagName === 'a' &&
                    parent &&
                    parent.type === 'element' &&
                    /^h(1|2|3|4|5|6)/.test(parent.tagName)
                ) {
                    parent.children = [parent.children[1]];
                }
            }
        } as RehypeRewriteOptions
    ]
];

export const Changelog: FC = observer(() => {
    const { t } = useTranslation();
    const [markdown, setMarkdown] = useState<string>();
    const [isEditMode, setIsEditMode] = useState(false);
    const { isPowerUser } = useSupabaseUser();
    const isSmallTablet = useMedia('(max-width: 620px)');
    const isBigMobile = useMedia('(max-width: 480px)');

    useEffect(() => {
        document.documentElement.setAttribute('data-color-mode', 'light');
    }, []);

    useEffect(() => {
        const fetchChangelog = async () => {
            const { data, error } = await supabaseClient.storage
                .from('public')
                .download('changelog.md');

            if (error) {
                notification.error({ message: error.message });
                return;
            }

            const markdown = await data.text();

            setMarkdown(markdown);
        };

        if (modalStores.changelog.isOpen) {
            fetchChangelog();
        }
    }, [modalStores.changelog.isOpen]);

    const handleSave = () => {
        setIsEditMode(false);
        handleUpdateChangelog(markdown!);
    };

    const handleClose = () => {
        modalStores.changelog.close();
        setIsEditMode(false);
        setMarkdown(undefined);
    };

    const handleEdit = () => {
        setIsEditMode(true);
    };

    const handleChange = (value: string) => {
        setMarkdown(value);
    };

    const storeNavigate = useStoreNavigate();

    const handleClick = (to: string) => {
        if (to.includes('http')) {
            window.open(to, '_blank');
        } else {
            storeNavigate(to);
        }
    };

    return (
        <>
            <ButtonWithTooltips
                id="help"
                size={isBigMobile ? 'large' : 'middle'}
                tooltipTitle={t('help')}
                type="text"
                className="header__help"
                icon={<InfoCircleOutlined />}
                onClick={() => modalStores.changelog.open()}
            />
            <Modal
                title={t('changelog')}
                styles={{ body: { overflowY: 'scroll', height: 'calc(100vh - 300px)' } }}
                onCancel={handleClose}
                loading={typeof markdown === undefined}
                open={modalStores.changelog.isOpen}
                width={800}
                footer={
                    <Flex gap={5} justify="flex-end">
                        {isEditMode ? (
                            <ButtonWithTooltips id="save" onClick={handleSave}>
                                {t('save')}
                            </ButtonWithTooltips>
                        ) : (
                            <>
                                {isPowerUser && (
                                    <ButtonWithTooltips id="edit" onClick={handleEdit}>
                                        {t('edit')}
                                    </ButtonWithTooltips>
                                )}
                                <ButtonWithTooltips
                                    className=""
                                    type="default"
                                    id="close"
                                    onClick={handleClose}
                                >
                                    {t('close')}
                                </ButtonWithTooltips>
                            </>
                        )}
                    </Flex>
                }
            >
                {isEditMode ? (
                    <MarkdownEditor value={markdown} onChange={handleChange} />
                ) : (
                    <MarkdownEditor.Markdown
                        components={{
                            a: (props) => {
                                return (
                                    <a
                                        href={props.href}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleClick(props.href);
                                        }}
                                    >
                                        {props.children}
                                    </a>
                                );
                            }
                        }}
                        rehypePlugins={rehypePlugins}
                        source={markdown}
                    />
                )}
            </Modal>
        </>
    );
});
