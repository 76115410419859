// src/loadProdScripts.js

// Check if the current environment is production
if (import.meta.env.PROD) {
    console.log('Production environment detected');
    // Yandex Metrika script
    const metrikaScript = document.createElement('script');
    metrikaScript.type = 'text/javascript';
    metrikaScript.textContent = `
      (function (m, e, t, r, i, k, a) {
        m[i] = m[i] || function () {
          (m[i].a = m[i].a || []).push(arguments);
        };
        m[i].l = 1 * new Date();
        for (var j = 0; j < document.scripts.length; j++) {
          if (document.scripts[j].src === r) {
            return;
          }
        }
        (k = e.createElement(t)),
          (a = e.getElementsByTagName(t)[0]),
          (k.async = 1),
          (k.src = r),
          a.parentNode.insertBefore(k, a);
      })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');
  
      ym(96885786, 'init', {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true
      });
    `;
    document.head.appendChild(metrikaScript);

    // Append noscript block for Yandex Metrika
    const noScriptDiv = document.createElement('div');
    noScriptDiv.innerHTML = `
      <noscript>
        <div>
          <img
            src="https://mc.yandex.ru/watch/96885786"
            style="position: absolute; left: -9999px"
            alt=""
          />
        </div>
      </noscript>
    `;
    document.body.appendChild(noScriptDiv);

    // Google Tag Manager script
    const gtagScript = document.createElement('script');
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-VBS9XKYPJR';
    gtagScript.async = true;
    document.head.appendChild(gtagScript);

    // Google Tag Manager configuration
    const gtagConfigScript = document.createElement('script');
    gtagConfigScript.textContent = `
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-VBS9XKYPJR');
    `;
    document.head.appendChild(gtagConfigScript);
}
