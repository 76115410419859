import { EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Modal, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { Dispatch, memo, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './SmartDurationField.scss';
import { useMedia } from 'react-use';

interface SmartDurationFieldProps {
    value: string | null;
    onChange: (newValue: string | null) => void;
    popoverContainerHtmlId?: string;
    disabled?: boolean;
    style?: React.CSSProperties;
    className?: string;
}

type DurationComponentsType = {
    years: number;
    months: number;
    days: number;
    minutes: number;
    hours: number;
    seconds: number;
};

const parsePostgresIntervalValueToDuration = (value: string): DurationComponentsType => {
    const findYears = /([0-9]*) year/g;
    const yearsResult = findYears.exec(value);

    const findMonths = /([0-9]*) mon/g;
    const monthsResult = findMonths.exec(value);

    const findDays = /([0-9]*) day/g;
    const daysResult = findDays.exec(value);

    const findHoursMinutesSeconds = /([0-9]*):([0-9]*):([0-9]*)/g;
    const hmsResult = findHoursMinutesSeconds.exec(value);

    return {
        years: yearsResult && yearsResult[1] ? parseInt(yearsResult[1], 10) : 0,
        months: monthsResult && monthsResult[1] ? parseInt(monthsResult[1], 10) : 0,
        days: daysResult && daysResult[1] ? parseInt(daysResult[1], 10) : 0,
        hours: hmsResult && hmsResult[1] ? parseInt(hmsResult[1], 10) : 0,
        minutes: hmsResult && hmsResult[2] ? parseInt(hmsResult[2], 10) : 0,
        seconds: hmsResult && hmsResult[3] ? parseInt(hmsResult[3], 10) : 0
    };
};

export const durationPrettyOutput = (value: string): string => {
    const duration = parsePostgresIntervalValueToDuration(value);

    let durationString = '';

    const hmsValue = {
        hours: 0,
        minutes: 0,
        seconds: 0
    };

    for (let i = 0; i < Object.keys(duration).length; i++) {
        const durationComponent = Object.keys(duration)[i];
        const durationValue = (duration as Record<string, number>)[durationComponent];

        let durationComponentPrefix = '';
        switch (durationComponent) {
            case 'years':
                durationComponentPrefix = 'г.';
                if (durationValue > 0) {
                    durationString += `${durationValue} ${durationComponentPrefix}`;
                }
                break;
            case 'months':
                durationComponentPrefix = 'м.';
                if (durationValue > 0) {
                    durationString += `${durationValue} ${durationComponentPrefix}`;
                }
                break;
            case 'days':
                durationComponentPrefix = 'д.';
                if (durationValue > 0) {
                    durationString += `${durationValue} ${durationComponentPrefix}`;
                }
                break;
            case 'hours':
                hmsValue.hours = durationValue;
                break;

            case 'minutes':
                hmsValue.minutes = durationValue;
                break;

            case 'seconds':
                hmsValue.seconds = durationValue;
                break;

            default:
                break;
        }
    }

    if (hmsValue.hours > 0 || hmsValue.minutes > 0 || hmsValue.seconds > 0) {
        durationString = `${durationString} ${hmsValue.hours
            .toString()
            .padStart(2, '0')}:${hmsValue.minutes.toString().padStart(2, '0')}:${hmsValue.seconds
            .toString()
            .padStart(2, '0')}`;
    }

    return durationString;
};

const DurationEditModal = ({
    value,
    setValue,
    open,
    setOpen
}: {
    value: string; // 3 years 2 months 2 days 02:02:02	postgres format
    setValue: Function;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
    const { t } = useTranslation();
    const isBigTablet = useMedia('(max-width: 1080px)');
    const isBigMobile = useMedia('(max-width: 480px)');

    const [form] = useForm();

    const parseDurationToPostgresValue = (dur: DurationComponentsType): string => {
        const durationValues = dur as Record<string, number>;

        const durationComponents = Object.keys(dur);
        let postgresValue = '';

        const hmsValue = {
            hours: 0,
            minutes: 0,
            seconds: 0
        };

        for (let i = 0; i < durationComponents.length; i++) {
            const durationComponent = durationComponents[i];
            const durationValue = durationValues[durationComponent];

            let durationISOUnit = null;

            if (durationComponent) {
                switch (durationComponent) {
                    case 'years':
                        durationISOUnit = 'year';
                        break;
                    case 'months':
                        durationISOUnit = 'mon';
                        break;
                    case 'days':
                        durationISOUnit = 'day';
                        break;
                    case 'hours':
                        hmsValue.hours = durationValue || 0;
                        break;
                    case 'minutes':
                        hmsValue.minutes = durationValue || 0;
                        break;
                    case 'seconds':
                        hmsValue.seconds = durationValue || 0;
                        break;
                    default:
                        continue;
                }

                if (durationISOUnit && durationValue) {
                    postgresValue = `${postgresValue} ${durationValue} ${durationISOUnit}`;
                }
            }
        }

        // FIXME: это плохой формат - надо поменять на ISO: P6Y5M4DT3H2M1S
        postgresValue = `${postgresValue} ${hmsValue.hours
            .toString()
            .padStart(2, '0')}:${hmsValue.minutes.toString().padStart(2, '0')}:${hmsValue.seconds
            .toString()
            .padStart(2, '0')}`;

        return postgresValue === '' ? '00:00:00' : postgresValue.trimStart();
    };

    useEffect(() => {
        if (value) {
            const duration = parsePostgresIntervalValueToDuration(value);
            form.setFieldsValue(duration);
        }
    }, [value]);

    return (
        <Modal
            centered
            title={t('edit_duration_modal_title')}
            open={open}
            width={isBigMobile ? '99vw' : isBigTablet ? '50vw' : '25vw'}
            footer={null}
            onCancel={() => setOpen(false)}
        >
            <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 5 }}
                form={form}
                onFinish={(duration: DurationComponentsType) => {
                    const durationStringValue = parseDurationToPostgresValue(duration);
                    setValue(durationStringValue);
                    setOpen(false);
                }}
            >
                {/* <Form.Item style={{ width: '100%' }} name={'years'} label={t('year')}>
                    <InputNumber
                        style={{ width: '100%' }}
                        step="1"
                        min={0}
                        type="number"
                        // }}
                    />
                </Form.Item>
                <Form.Item style={{ width: '100%' }} name={'months'} label={t('month')}>
                    <InputNumber style={{ width: '100%' }} step="1" min={0} type="number" />
                </Form.Item>
                 */}

                <Form.Item style={{ width: '100%' }} name={'days'} label={t('days')}>
                    <InputNumber style={{ width: '100%' }} step="1" min={0} type="number" />
                </Form.Item>

                <Form.Item style={{ width: '100%' }} name={'hours'} label={t('hour')}>
                    <InputNumber style={{ width: '100%' }} step="1" min={0} type="number" />
                </Form.Item>

                <Form.Item style={{ width: '100%' }} name={'minutes'} label={t('minute')}>
                    <InputNumber style={{ width: '100%' }} step="1" min={0} type="number" />
                </Form.Item>

                <Form.Item style={{ width: '100%' }} name={'seconds'} label={t('second')}>
                    <InputNumber style={{ width: '100%' }} step="1" min={0} type="number" />
                </Form.Item>

                <div style={{ justifyContent: 'end', display: 'flex', marginTop: 5 }}>
                    <Button
                        type="default"
                        style={{ marginRight: '0.2rem' }}
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        {t('cancel')}
                    </Button>
                    <Button type="primary" htmlType="submit">
                        {t('apply')}
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

export const SmartDurationField = memo(
    ({
        value,
        popoverContainerHtmlId,
        onChange,
        disabled,
        style,
        className
    }: SmartDurationFieldProps) => {
        const { t } = useTranslation();

        const [modalOpen, setModalOpen] = useState<boolean>(false);

        const handleClear: React.MouseEventHandler<HTMLSpanElement> = (e) => {
            e.stopPropagation();
            onChange(null);
        };

        const outputValue = useMemo(() => {
            return value ? durationPrettyOutput(value) : '';
        }, [value]);
        return (
            <>
                <DurationEditModal
                    open={modalOpen}
                    setOpen={setModalOpen}
                    value={value || ''}
                    setValue={(isoValue: string) => {
                        onChange(isoValue);
                    }}
                />

                <Space.Compact className="smart_duration_field">
                    <Input
                        placeholder={t('no_value') as string}
                        // variant="borderless"
                        value={outputValue || ''}
                        style={style}
                        className={className}
                        disabled={disabled}
                        // addonAfter={
                        //     <Button
                        //         icon={<EditOutlined />}
                        //         type="text"
                        //         onClick={() => {
                        //             setModalOpen(true);
                        //         }}
                        //     />
                        // }
                        readOnly
                    />
                    <Button
                        className="edit_button"
                        icon={<EditOutlined />}
                        type="default"
                        onClick={() => {
                            setModalOpen(true);
                        }}
                    />
                </Space.Compact>
            </>
        );
    }
);
