export const getProgressColor = (value: number) => {
    if (value >= 90 && value <= 100) {
        return '#388e3c';
    }

    if (value > 100) {
        return '#D71A21';
    }

    // if (value < 90) {
    return '#3185D2';
    // }

    // if (value < 30) {
    //     return '#D71A21';
    // }

    // if (value < 40) {
    //     return '#EBBA3D';
    // }
};
