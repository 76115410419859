import { PlainObject } from '@gilbarbara/types';

export const JSONSafeParse = (str: string): PlainObject | string => {
    let result = '';

    try {
        result = JSON.parse(str);
    } catch (error) {
        result = str;
    }

    return result;
};
