import { PlusOutlined } from '@ant-design/icons';
import { Divider, Drawer, List, Space } from 'antd';
import { isEqual } from 'lodash';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Dispatch, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useEffectOnce, useMedia } from 'react-use';
import { useTranslation } from 'react-i18next';

import { getExcludeFieldsPattern } from 'smart/utils';
import { ButtonWithTooltips } from 'ui';
import { EmptyMarker } from 'ui/EmptyMarker/EmptyMarker';

import { metaStore } from 'utils/store/MetaStore';
import { AllFiltersModal, FilterItem } from './components';
import { FilterField } from './types';

import './TableFilterMenuModal.scss';
import { getInitialActiveFilters } from './utils';

interface ISmartTableFilterMenuModal {
    open?: boolean;
    setOpen?: Dispatch<boolean>;
    filters: FilterField[];
    setFilters: Dispatch<FilterField[]>;
    showInModal?: boolean;
    meta: string;
}

export const SmartTableFilterMenuModal: FC<ISmartTableFilterMenuModal> = observer(
    ({ open, setOpen, filters, setFilters, showInModal = true, meta }) => {
        // const metaFields = metaStore.meta.get(meta)?.info?.Fields;

        const fields = useMemo(
            () =>
                [...(toJS(metaStore.meta.get(meta)?.info?.Fields) || [])].filter(
                    (field, _, fields) =>
                        // !field.IsHiddenOnDetail &&
                        !field.IsHiddenOnTable && getExcludeFieldsPattern(field, fields)
                ),
            [meta, metaStore.meta.get(meta)?.info?.Fields]
        );

        const { t } = useTranslation();
        const isSmallTablet = useMedia('(max-width: 620px)');
        const isMiddleTablet = useMedia('(max-width: 720px)');
        const isBigMobile = useMedia('(max-width: 480px)');
        const isBigTablet = useMedia('(max-width: 1080px)');
        const isSmallDesktop = useMedia('(max-width: 1280px)');

        const [appliedFilterFields, setAppliedFilterFields] = useState<FilterField[]>([]);

        const [openAllFiltersModal, setOpenAllFiltersModal] = useState(false);

        const removeAppliedField = useCallback(
            (columnName: string) => {
                setAppliedFilterFields((prevValue) => {
                    const copy = [...prevValue];
                    const fieldIndex = copy.findIndex(
                        (field) => field.field.ColumnName === columnName
                    );

                    if (fieldIndex >= 0) {
                        copy.splice(fieldIndex, 1);
                    }

                    return copy;
                });
            },
            [setAppliedFilterFields]
        );
        // функция для сброса фильтров при клике на кнопку drop в футере
        const dropAllAppliedField = useCallback(() => {
            if (filters.length > 0) setFilters([]);
            setAppliedFilterFields(getInitialActiveFilters(fields));

            if (setOpen) {
                setOpen(false);
            }
        }, [fields, filters.length, setFilters, setOpen]);

        const changeAppliedFilter = useCallback(
            (changedfield: FilterField) => {
                const copy = [...appliedFilterFields];
                const fieldIndex = copy.findIndex((field) => field.field === changedfield.field);

                if (fieldIndex >= 0) {
                    copy[fieldIndex] = {
                        ...copy[fieldIndex],
                        ...changedfield
                    };
                }

                if (copy[fieldIndex].values.length === 0) {
                    removeAppliedField(copy[fieldIndex].field.ColumnName);
                } else {
                    setAppliedFilterFields(copy);
                }
            },
            [appliedFilterFields, setAppliedFilterFields, removeAppliedField]
        );

        useEffectOnce(() => {
            if (filters.length) setAppliedFilterFields(filters);
        });

        // useUpdateEffect(() => {
        //     if (!isEqual(filters, appliedFilterFields) && filters?.length) {
        //         // console.log('sync active with filters');
        //         setAppliedFilterFields(filters);
        //     }
        // }, [filters, appliedFilterFields]);

        useEffect(() => {
            if (!showInModal) {
                if (!isEqual(filters, appliedFilterFields) && appliedFilterFields.length > 0) {
                    // console.log('sync filters with active');
                    setFilters(appliedFilterFields);
                }
            }
        }, [appliedFilterFields, showInModal, filters, setFilters]);

        // console.log('filters EQ appliedFilters', filters, appliedFilterFields);

        const drawerContent = (
            <List
                className="applied_filters"
                dataSource={appliedFilterFields ?? []}
                locale={{ emptyText: <EmptyMarker size="small" /> }}
                itemLayout="horizontal"
                renderItem={(item) => (
                    <List.Item style={{ padding: 0 }}>
                        <FilterItem
                            item={item}
                            items={appliedFilterFields ?? []}
                            changeItem={changeAppliedFilter}
                            removeAppliedField={removeAppliedField}
                        />
                    </List.Item>
                )}
            ></List>
        );

        return (
            <>
                <Drawer
                    title={isSmallDesktop ? t('filtering') : t('filter_modal_title')}
                    placement="right"
                    width={
                        isBigMobile
                            ? '100%'
                            : isSmallTablet
                              ? '75%'
                              : isMiddleTablet
                                ? '66%'
                                : isBigTablet
                                  ? '50%'
                                  : '33%'
                    }
                    onClose={() => {
                        if (setOpen) {
                            setOpen(false);
                        }
                    }}
                    id="table_filter_menu"
                    open={open}
                    destroyOnClose
                    extra={
                        <Space>
                            <ButtonWithTooltips
                                id="drop"
                                className="btn-red"
                                onClick={dropAllAppliedField}
                            >
                                {t('drop')}
                            </ButtonWithTooltips>

                            <ButtonWithTooltips
                                id="save"
                                onClick={() => {
                                    // console.log('click save');
                                    setFilters(appliedFilterFields);

                                    if (setOpen) {
                                        setOpen(false);
                                    }
                                }}
                            >
                                {t('save')}
                            </ButtonWithTooltips>
                        </Space>
                    }
                >
                    {drawerContent}
                    <Divider style={{ margin: '12px 0' }} />
                    <ButtonWithTooltips
                        id={'all_filters'}
                        type="default"
                        className=""
                        icon={<PlusOutlined />}
                        style={{ marginLeft: 'calc(100% - 92px)' }}
                        onClick={() => setOpenAllFiltersModal(true)}
                    >
                        {t('all_fields')}
                    </ButtonWithTooltips>
                </Drawer>

                <AllFiltersModal
                    meta={meta}
                    setOpen={setOpenAllFiltersModal}
                    open={openAllFiltersModal}
                    activeFilters={appliedFilterFields}
                    setActiveFilters={setAppliedFilterFields}
                    metaFields={fields}
                />
            </>
        );
    }
);
