import { t } from 'i18next';
import {
    Badge,
    Button,
    Divider,
    Dropdown,
    Flex,
    Popover,
    Space,
    TableColumnsType,
    Tooltip,
    Typography
} from 'antd';
import { range } from 'lodash';
import dayjs from 'dayjs';
import { isDefined } from 'is-lite/exports';
import React, { CSSProperties } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { PlainObject } from '@gilbarbara/types';
import { toJS } from 'mobx';
import { calculateFreeHoursForResource } from './calculateFreeHoursForResource';
import { DayDataType, MenuItem, Months, Weeks } from './types';
import { getGetFreeHoursByDate } from './getFreeHoursByDate';
import { ColorMarker, ColorMarkerProps } from '../ui';
import { DATE_FORMAT } from '../../../../utils/helpers/dates';
import {
    RegBookedLocationSlot,
    RegLocationResourceSlot
} from '../../../../modules/services/backend-api/generated_models';
import { IObjectWithId, metaStore } from '../../../../utils/store/MetaStore';
import { fnv1aHash } from '../../../utils';
import { StoreLink } from '../../../../ui';
import { HOURS } from '../constants';

type GetColumnsFunction = (options: {
    daysCount: number;
    selectedDate: dayjs.Dayjs;
    freeSlots: RegLocationResourceSlot[];
    bookedSlots: RegBookedLocationSlot[];
    fetchBookedSlots: () => void;
    language: string;
    // actionsMenuItems?: MenuItem[];
    getTripActionsMenuItems: (ids: string[], row: IObjectWithId, args?: PlainObject) => MenuItem[];
    getSlotActionsMenuItems: (ids: string[], row: IObjectWithId, args?: PlainObject) => MenuItem[];
    selectedTripsIds: string[];
    firstColumnFieldName: string;
    meta: string;
}) => TableColumnsType<DayDataType>;

export const getColumns: GetColumnsFunction = ({
    daysCount,
    selectedDate,
    freeSlots,
    bookedSlots,
    // fetchBookedSlots,
    getTripActionsMenuItems,
    getSlotActionsMenuItems,
    language,
    selectedTripsIds,
    firstColumnFieldName,
    meta
}) => {
    const tripsMeta = 'DocTrips';
    const downtimeEventsMeta = 'DocDowntimeEvents';

    const metaRoutes = metaStore.meta.get('all')?.routes;
    const itemsRoute = metaRoutes?.find((route) => route.meta === meta);
    const tripsRoute = metaRoutes?.find((route) => route.meta === tripsMeta);
    const downtimeEventsRoute = metaRoutes?.find((route) => route.meta === downtimeEventsMeta);

    const itemsMetaInfo = toJS(metaStore.meta.get(meta)?.info);
    const itemsUiAllowView = itemsMetaInfo?.UiAllowView ?? true;

    const tripsMetaInfo = toJS(metaStore.meta.get(tripsMeta)?.info);
    const tripsUiAllowView = tripsMetaInfo?.UiAllowView ?? true;

    const downtimeEventsMetaInfo = toJS(metaStore.meta.get(downtimeEventsMeta)?.info);
    const downtimeEventsUiAllowView = downtimeEventsMetaInfo?.UiAllowView ?? true;

    const title = itemsMetaInfo?.PluralName?.[language] ?? t('items');
    const titleSingular = itemsMetaInfo?.SingularName?.[language] ?? itemsMetaInfo?.Code;
    const tripTitleSingular = tripsMetaInfo?.SingularName?.[language] ?? tripsMetaInfo?.Code;
    const downtimeEventTitleSingular =
        downtimeEventsMetaInfo?.SingularName?.[language] ?? downtimeEventsMetaInfo?.Code;

    return [
        {
            title,
            // dataIndex: 'resource',
            dataIndex: 'Name',
            // key: 'resource',
            key: 'Name',
            fixed: 'left',
            width: 300,
            // responsive,
            ellipsis: true,
            render: (title, { Id, Key, Name, Meta, ItemId, Trip, Item }) => {
                const isTripMeta = Meta === tripsMeta;
                const isDowntimeEventMeta = Meta === downtimeEventsMeta;
                // console.log("[SmartTimelineTable] for slot's count data:", groupedById);
                // считаем количество свободный для букирования дней у ресурса
                const freeSlotsCount = calculateFreeHoursForResource(
                    freeSlots,
                    ItemId,
                    daysCount,
                    selectedDate,
                    firstColumnFieldName
                );

                // считаем количество уже занятых слотов ресурса
                // !CHANGED
                const bookedSlotsCount = bookedSlots.filter(
                    (bs) =>
                        bs[firstColumnFieldName]?.Id === ItemId &&
                        (!isTripMeta || bs.Trip?.Id === Id) &&
                        (!isDowntimeEventMeta || bs.DowntimeEvent?.Id === Id)
                ).length;

                const pathname =
                    isDowntimeEventMeta && downtimeEventsRoute
                        ? `${downtimeEventsRoute.path}/${Id}`
                        : isTripMeta && tripsRoute
                          ? `${tripsRoute.path}/${Id}`
                          : itemsRoute
                            ? `${itemsRoute.path}/${Id}`
                            : undefined;

                const state = {
                    pageTitle: `${Name} (${isDowntimeEventMeta ? downtimeEventTitleSingular : isTripMeta ? tripTitleSingular : titleSingular})`,
                    data: { Id, Key },
                    cacheKey: fnv1aHash(
                        `${isDowntimeEventMeta ? downtimeEventsMeta : isTripMeta ? tripsMeta : meta}_${Id}_view`
                    )
                };

                const link =
                    pathname && itemsUiAllowView ? (
                        <StoreLink style={{ padding: 0 }} to={pathname} state={state}>
                            {title}
                        </StoreLink>
                    ) : (
                        title
                    );

                return (
                    <Dropdown
                        menu={
                            isTripMeta
                                ? {
                                      items: getTripActionsMenuItems([Id], Trip)
                                  }
                                : undefined
                        }
                        trigger={['contextMenu']}
                        placement="bottomLeft"
                        destroyPopupOnHide
                    >
                        <Flex
                            className={'smart_timeline_table__resource_cell'}
                            align="center"
                            justify="space-between"
                        >
                            <span className={'smart_timeline_table__resource_cell_link'}>
                                {link}
                            </span>

                            <Flex gap={5} justify="flex-end" style={{ width: 70 }}>
                                {!isTripMeta && !isDowntimeEventMeta && (
                                    <Flex
                                        gap={3}
                                        align="center"
                                        justify="flex-end"
                                        style={{ width: '50%' }}
                                    >
                                        {freeSlotsCount}
                                        <ColorMarker status="free" />
                                    </Flex>
                                )}
                                <Flex
                                    gap={3}
                                    align="center"
                                    justify="flex-end"
                                    style={{ width: '50%' }}
                                >
                                    {bookedSlotsCount}
                                    <ColorMarker status="filled" />
                                </Flex>
                            </Flex>
                        </Flex>
                    </Dropdown>
                );
            }
        },
        ...range(0, daysCount).map((dayIndex) => {
            const showDate = selectedDate.local().add(dayIndex, 'days');
            const showDateString = showDate.format(DATE_FORMAT);

            return {
                title: `${showDate.date()} ${t(Months[showDate.month()])} ${showDate.year()} (${t(
                    Weeks[showDate.day()]
                )})`,
                children: HOURS.map((hour) => {
                    return {
                        title: hour % 2 === 0 ? `${hour}:00` : '',
                        dataIndex: hour,
                        key: hour,
                        width: 55,
                        align: 'center',
                        render: (
                            hour: number,
                            {
                                Id,
                                CapacityCount,
                                Meta,
                                ItemId,
                                TripId,
                                DowntimeEventId,
                                TripIndex
                            }: DayDataType
                        ) => {
                            const isTripsMeta = Meta === tripsMeta;
                            const isDowntimeEventMeta = Meta === downtimeEventsMeta;

                            const bookedSlotsData = bookedSlots.filter(
                                (slot) =>
                                    slot[firstColumnFieldName]?.Id === ItemId &&
                                    String(slot.SlotDate) === showDateString &&
                                    slot.SlotIndex === hour &&
                                    (!TripId || slot.Trip?.Id === TripId) && // если строка размернута и относится к рейсу
                                    (!DowntimeEventId || slot.DowntimeEvent?.Id === DowntimeEventId) // если строка размернута и относится к евенту
                            );

                            const slotFillPercent = Math.max(
                                ...bookedSlotsData.map((s) => s.SlotPercent)
                            );
                            const isSlotStart = bookedSlotsData?.[0]?.IsSlotStart;
                            const isSlotEnd = bookedSlotsData?.[0]?.IsSlotEnd;

                            const bookedCapacity = bookedSlotsData.reduce((acc, bs) => {
                                if (isDefined(bs.CapacityCount))
                                    return acc + (bs.CapacityCount ?? 0);

                                return 1;
                            }, 0);

                            const cellColor = bookedSlotsData?.[0]?.Status?.Color; // цвет первого рейса
                            const border: CSSProperties['border'] = '2px solid red';

                            let showSelectedTripBorder = false;

                            let totalWeightValue = 0;

                            const tripLinks = bookedSlotsData
                                .filter((bs) => bs.Trip)
                                .map((bs) => {
                                    const tripId = bs.Trip?.Id;
                                    showSelectedTripBorder = selectedTripsIds.includes(
                                        tripId ?? ''
                                    ); // выделяем рамкой если рейс выбран в отборе ИЛИ если это развернутая строка с рейсом
                                    const tripLabel = bs.Trip?.Key;
                                    const color = bs.Status?.Color ?? 'lightgray';
                                    const statusName = bs.Status?.Name?.[language];

                                    totalWeightValue += bs.Trip?.TotalWeightValue ?? 0;

                                    return (
                                        <Space style={{ padding: '5px 11px' }}>
                                            <Tooltip title={statusName}>
                                                <Badge color={color} />
                                            </Tooltip>
                                            {tripsRoute && tripsUiAllowView ? (
                                                <StoreLink
                                                    key={tripId}
                                                    style={{ padding: 0 }}
                                                    to={`${tripsRoute.path}/${tripId}`}
                                                    state={{ data: bs?.Trip }}
                                                >
                                                    {tripLabel}
                                                </StoreLink>
                                            ) : (
                                                tripLabel
                                            )}
                                            <Dropdown
                                                menu={{
                                                    items: getTripActionsMenuItems(
                                                        [tripId],
                                                        bs.Trip,
                                                        {
                                                            trip_operation_id:
                                                                bs.LocationResource?.Operation,
                                                            location_resource_id:
                                                                bs.LocationResource,
                                                            trip_stop_id: bs.Location,
                                                            service_id: bs.LocationResource?.Service
                                                        }
                                                    )
                                                }}
                                                trigger={['click']}
                                                placement="bottomLeft"
                                                destroyPopupOnHide
                                            >
                                                <Button type="text">
                                                    <MoreOutlined />
                                                </Button>
                                            </Dropdown>
                                        </Space>
                                    );
                                });

                            const downtimeEventLinks = bookedSlotsData
                                .filter((bs) => bs.DowntimeEvent)
                                .map((bs) => {
                                    const downtimeEventId = bs.DowntimeEvent?.Id;
                                    showSelectedTripBorder = selectedTripsIds.includes(
                                        downtimeEventId ?? ''
                                    ); // выделям рамкой если рейс выбран в отборе ИЛИ если это развернутая строка с рейсом
                                    const downtimeEventLabel = bs.DowntimeEvent?.Key;
                                    const color = bs.Status?.Color ?? 'lightgray';
                                    const statusName = bs.Status?.Name?.[language];

                                    // totalWeightValue += bs.DowntimeEvent?.TotalWeightValue ?? 0;

                                    return (
                                        <Space style={{ padding: '5px 11px' }}>
                                            <Tooltip title={statusName}>
                                                <Badge color={color} />
                                            </Tooltip>
                                            {downtimeEventsRoute && downtimeEventsUiAllowView ? (
                                                <StoreLink
                                                    key={downtimeEventId}
                                                    style={{ padding: 0 }}
                                                    to={`${downtimeEventsRoute.path}/${downtimeEventId}`}
                                                    state={{ data: bs?.Trip }}
                                                >
                                                    {downtimeEventLabel}
                                                </StoreLink>
                                            ) : (
                                                downtimeEventLabel
                                            )}
                                            {/* <Dropdown */}
                                            {/*    menu={{ */}
                                            {/*        items: getTripActionsMenuItems( */}
                                            {/*            [downtimeEventId], */}
                                            {/*            bs.DowntimeEvent */}
                                            {/*        ) */}
                                            {/*    }} */}
                                            {/*    trigger={['click']} */}
                                            {/*    placement="bottomLeft" */}
                                            {/*    destroyPopupOnHide */}
                                            {/* > */}
                                            {/*    <Button type="text"> */}
                                            {/*        <MoreOutlined /> */}
                                            {/*    </Button> */}
                                            {/* </Dropdown> */}
                                        </Space>
                                    );
                                });

                            // если мы смотрим на рейс, то пропускаем вычисление всех слотов
                            let status: ColorMarkerProps['status'] =
                                isTripsMeta || isDowntimeEventMeta
                                    ? 'closed'
                                    : getGetFreeHoursByDate()(
                                            freeSlots,
                                            ItemId,
                                            firstColumnFieldName
                                        )
                                            .get(showDateString)
                                            ?.includes(hour)
                                      ? 'free'
                                      : 'closed';

                            if (bookedCapacity > 0) status = 'filled';

                            // const capacityDiff = CapacityCount - bookedCapacity;
                            const slotInfo = `${t('slot')} ${hour + 1} (${hour}:00-${hour + 1}:00)`;
                            const bottomText = `${t('busy')} ${bookedCapacity}/${CapacityCount}`;
                            const hasText =
                                bookedCapacity > 1 || (isTripsMeta && status === 'filled');
                            const slotStartTime = showDate
                                .set('hours', hour)
                                .set('minutes', 0)
                                .set('seconds', 0)
                                .set('milliseconds', 0)
                                .format();
                            const slotEndTime = showDate
                                .set('hours', hour + 1)
                                .set('minutes', 0)
                                .set('seconds', 0)
                                .set('milliseconds', 0)
                                .format();

                            // const blockHandler = async (capacity_count: 1 | -1) => {
                            //     await metaStore.makeRun({
                            //         Action_Id: '',
                            //         meta: 'CatLocationResources',
                            //         ids: [ResourceId],
                            //         handler: 'CatLocationResources.BookSlot',
                            //         args: {
                            //             planning_date: selectedDate.format(DATE_FORMAT),
                            //             slot_index: hour,
                            //             capacity_count
                            //         }
                            //     });
                            //
                            //     fetchBookedSlots();
                            // };
                            //
                            // const menuItems: MenuProps['items'] = [
                            //     {
                            //         key: 0,
                            //         label: t('block_slot'),
                            //         onClick: () => blockHandler(1),
                            //         disabled: status === 'closed'
                            //     },
                            //     {
                            //         key: 1,
                            //         label: t('unblock_slot'),
                            //         onClick: () => blockHandler(-1),
                            //         disabled: status === 'closed' || status === 'free'
                            //     }
                            // ];

                            const driverInfos = [];

                            for (const bs of bookedSlotsData) {
                                const driver = bs.Trip?.Driver?.Name?.[language];
                                const vehicle = bs.Trip?.FirstVehicle?.Name?.[language];

                                const info = `${driver ?? ''}${vehicle ? ` / ${vehicle}` : ''}`;

                                if (info) driverInfos.push(info);
                            }

                            const hasDriverInfos = !!driverInfos?.length;

                            return (
                                <Dropdown
                                    dropdownRender={(menu) => {
                                        return (
                                            <div className={'smart_timeline_table__slot_context'}>
                                                <Typography.Text strong>{slotInfo}</Typography.Text>
                                                <Divider style={{ margin: '5px 0' }} />
                                                {/* {tripLink} */}
                                                {status === 'filled' ||
                                                status === 'partial_filled' ? (
                                                    <>
                                                        <Flex vertical>
                                                            {!!tripLinks.length && tripLinks}
                                                            {!!downtimeEventLinks.length &&
                                                                downtimeEventLinks}
                                                        </Flex>
                                                        <Divider style={{ margin: '5px 0' }} />
                                                    </>
                                                ) : null}
                                                {React.cloneElement(menu as React.ReactElement, {
                                                    style: {
                                                        backgroundColor: 'transparent',
                                                        boxShadow: 'none',
                                                        padding: 0
                                                    }
                                                })}
                                                {status !== 'closed' ? (
                                                    <>
                                                        <Divider style={{ margin: '5px 0' }} />
                                                        <Typography.Text>
                                                            {bottomText}
                                                        </Typography.Text>
                                                    </>
                                                ) : null}
                                            </div>
                                        );
                                    }}
                                    menu={{
                                        items: getSlotActionsMenuItems(
                                            [ItemId],
                                            bookedSlotsData?.[0],
                                            {
                                                start_time: slotStartTime,
                                                end_time: slotEndTime
                                            }
                                        )
                                    }}
                                    trigger={['contextMenu']}
                                    destroyPopupOnHide
                                    getPopupContainer={() =>
                                        document.querySelector(
                                            '.smart_timeline_table'
                                        ) as HTMLElement
                                    }
                                >
                                    <Popover
                                        trigger={'click'}
                                        content={
                                            hasDriverInfos ? (
                                                <Flex>{driverInfos.join(', ')}</Flex>
                                            ) : undefined
                                        }
                                    >
                                        <div>
                                            <ColorMarker
                                                border={showSelectedTripBorder ? border : undefined}
                                                status={status}
                                                color={cellColor}
                                                width={`${slotFillPercent}%`}
                                                text={
                                                    hasText
                                                        ? totalWeightValue > 999
                                                            ? `${Math.round(totalWeightValue / 1000)} т`
                                                            : `${Math.round(totalWeightValue)} кг`
                                                        : ''
                                                }
                                                isContentStart={isSlotStart}
                                                isContentEnd={isSlotEnd}
                                                // text={hasText ? capacityDiff : ''}
                                            />
                                        </div>
                                    </Popover>
                                </Dropdown>
                            );
                        }
                    };
                })
            };
        })
    ];
};
