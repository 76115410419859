import { TableName } from 'modules/supabase/types/Dataset';

export const getTableNameByPath = (pathname: string) => {
    const route_path = pathname.substring(0, pathname.lastIndexOf('/')).substring(1);
    // replace "/" and "-" with "_" and
    // replace "master-data" with "docs" : "master-data/products" -> "docs_products",
    // replace "transactional-data" with "docs" : "transactional-data/transportation-order" -> "transportation_order"
    // replace "settings" with "settings" : "settings/registry-group-day-limits" -> "settings_registry_group_day_limits"

    const table_name = route_path
        .replace(/\//g, '_')
        .replace(/-/g, '_')
        .replace('master_data_organization', 'docs')
        .replace('master_data', 'docs')
        .replace('dashboards_folder', 'docs')
        .replace('etran_rails', 'docs')
        .replace('warehouse_docs', 'docs')
        .replace('warehouse_reports', 'docs')
        .replace('warehouse_master_data', 'docs')
        .replace('vehicle_monitoring', 'docs_vehicle_monitoring')
        .replace('warehouse_settings', 'settings')
        .replace('execution', 'docs')
        .replace('mutual_settlements', 'docs')
        .replace('transactional_data', 'docs')
        .replace('orders_management', 'docs')
        .replace('settings_async_tasks', 'misc_async_tasks') as TableName;

    return table_name;
};
