import { TooltipProps, Typography } from 'antd';
import { useMedia } from 'react-use';

export const SmartTooltip: React.FC<
    React.PropsWithChildren<{
        style?: React.CSSProperties;
        lineClamp?: number;
        // trigger?: TooltipProps['trigger'];
        title?: TooltipProps['title'];
        open?: boolean;
    }>
> = ({ children, style, title, open, lineClamp }) => {
    const isTouchScreen = useMedia('(hover: none)');

    return (
        <Typography.Paragraph
            title={''}
            style={{ margin: 0, ...style }}
            ellipsis={{
                rows: lineClamp,
                tooltip: !isTouchScreen
                    ? {
                          open,
                          title: title ?? children,
                          overlayStyle: { maxWidth: '500px' },
                          destroyTooltipOnHide: true,
                          trigger: 'hover',
                          mouseEnterDelay: 0.5
                      }
                    : false
            }}
        >
            {children}
        </Typography.Paragraph>
    );
};
