import { toJS } from 'mobx';
import { Meta } from 'modules/services/backend-api/generated_info';
import { toPascalCase } from 'utils/helpers/toPascalCase';
import { i18n } from 'utils/i18n/i18n';
import { metaStore } from 'utils/store/MetaStore';

interface GetDetailPageTitleProps {
    meta: string;
    data: any;
    pathname: string;
    state?: any;
    metaData?: Meta;
    hardName?: string;
}

export const getDetailPageTitle = ({
    meta,
    data,
    pathname,
    metaData,
    state
}: GetDetailPageTitleProps) => {
    const pathnameLastElem = pathname.split('/').at(-1)?.trim() as string;
    const isCreate = pathnameLastElem === 'new';
    const isCopy = pathnameLastElem === 'copy';

    const { t, language } = i18n;

    const metaRoute = metaStore.meta.get('all')?.routes?.find((route) => {
        return route.path === `${pathname}${state?.filterString ? `?${state?.filterString}` : ''}`;
    });

    const metaMenuItem = metaStore.meta.get('all')?.menu?.items.find((item) => {
        // return item.path === `${pathname}${state?.filterString ? `?${state?.filterString}` : ''}`;
        return item.path === `${pathname}${state?.filterString ? `?${state?.filterString}` : ''}`;
    });

    const singularName =
        metaRoute?.layout?.Info?.Info?.SingularName ||
        metaData?.SingularName ||
        toJS(metaStore.meta.get(meta)?.info)?.SingularName;

    const metaMenuName = metaMenuItem?.name?.[language];

    const metaInfo = metaData || metaStore.meta.get(meta)?.info;

    const metaType = metaInfo?.Type;

    const fromTypeKey = metaType?.ViewFieldName ? data[metaType?.ViewFieldName] : undefined;

    const key =
        fromTypeKey && typeof fromTypeKey === 'object'
            ? fromTypeKey[language]
            : fromTypeKey || data.Key || data.Code;

    const name = singularName?.[language] || metaMenuName || (t(pathnameLastElem) as string);
    const pageTitle = key || isCopy || isCreate ? `${t(key || 'new')} (${name})` : name;

    return pageTitle;
};
