import { VALUE_TYPE } from 'modules/services/backend-api/generated_info';

export const isArrayType = (type: VALUE_TYPE | undefined): boolean => {
    if (!type) return false;

    if (type === 'array_id') {
        return true;
    }
    return false;
};
