import { MetaField } from 'modules/services/backend-api/generated_info';

import { FilterField } from '../types';
import { getDefaultFilterOperator } from './getDefaultFilterOperator';

export const getInitialActiveFilters = (fields: MetaField[]) => {
    return fields
        .filter((field) => field.FilterIndex && field.FilterIndex > 0)
        .map((field) => {
            const filterField: FilterField = {
                field,
                values: []
            };

            filterField.values.push({
                operator: getDefaultFilterOperator(filterField),
                column: filterField.field.ColumnName
            });

            return filterField;
        });
};
