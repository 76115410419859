import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useAsync, useUpdateEffect } from 'react-use';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { isEmpty, isPlainObject, isPropertyOf, isString } from 'is-lite/exports';
import { v4 } from 'uuid';

import { IObjectWithId, metaStore } from '../../../utils/store/MetaStore';
import { RefField } from '../RefField/RefField';
import { JSONSafeParse, parseValueType } from '../../utils';

interface DynamicTypeFieldProps {
    objectMeta: string;
    onChange: (newValue: string | IObjectWithId | undefined) => void;
    value: IObjectWithId | undefined;
}

export const DynamicTypeField = observer<DynamicTypeFieldProps>(
    ({ objectMeta, onChange, value }) => {
        const {
            i18n: { language }
        } = useTranslation();

        const [refValue, setRefValue] = useState<IObjectWithId | string>();

        const valueType = useAsync(async () => {
            const meta =
                metaStore.meta.get(objectMeta)?.info ?? (await metaStore.getInfo(objectMeta));

            const valueType =
                meta?.Fields.find((f) => f.FieldName === 'Type')?.ValueType ?? '*code';

            return parseValueType(valueType, language);
        }, [objectMeta]);

        // обнуляем значение селекта, если перевыбрали мету
        useUpdateEffect(() => {
            setRefValue(undefined);
        }, [objectMeta]);

        // console.log(valueType, refValue);

        // если Строка на вход пришла пустая - защищаемся и делаем ее undefined для отправки на сервер
        useEffect(() => {
            if (isEmpty(value)) onChange(undefined);
        }, [onChange, value]);

        // делаем динамический лукап по Коду (строка), прилетевшему с сервера, при условии что ДО этого выбранное значение не было разлукаплено И мы не выбирали значение из списка
        useEffect(() => {
            const typesMeta = valueType.value?.options?.ref?.meta ?? '';
            if (
                isString(value) &&
                !isEmpty(value) &&
                typesMeta &&
                !isString(refValue) &&
                !isPropertyOf(refValue ?? {}, 'Name')
            ) {
                // console.log('effect');

                const lookup = { Code: value, Id: v4() };

                if (typesMeta) {
                    (async () => {
                        const [neadableValue] =
                            (await metaStore.meta
                                .get(typesMeta)
                                ?.select?.objects?.filter((o) => o.Code === value)) ??
                            (
                                await metaStore.makeSelect({
                                    meta: typesMeta,
                                    filters: `Code=eq.${value}`
                                })
                            )?.objects ??
                            [];

                        // console.log(toJS(neadableValue));

                        if (neadableValue) setRefValue(toJS(neadableValue));
                    })();
                } else {
                    setRefValue(lookup);
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [value, valueType.value?.options?.ref?.meta]);

        // достаем из выбранного объекта КОд для отправки на сервер текстом
        useUpdateEffect(() => {
            if (isPlainObject(refValue)) {
                onChange(refValue.Code);
            } else {
                onChange(refValue);
            }
        }, [refValue]);

        return (
            <RefField
                meta={valueType.value?.options?.ref?.meta ?? ''}
                onChange={setRefValue}
                parentFieldName={valueType.value?.options?.group}
                value={refValue as IObjectWithId}
                optionsValue={
                    JSONSafeParse(valueType.value?.options?.value || '') as IObjectWithId | string
                }
                filters={valueType.value?.options?.filters}
            />
        );
    }
);
