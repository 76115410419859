import merge from 'deepmerge';
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { GetParamRequestWithDefault, ServerApi } from 'modules/services/backend-api/api';
import {
    AcquireLockRequest,
    AssociationRequest,
    ExtendLockRequest,
    GetParamResponse,
    GetRequest,
    LogsRequest,
    LogsResponse,
    ReleaseLockRequest,
    Response,
    RunRequest,
    SaveRequest,
    SelectRequest
} from 'modules/services/backend-api/generated_api';
import { removeUndefinedFields } from 'smart/utils';
import { CODE } from 'modules/services/backend-api/generated_types';
import { PlainObject } from '@gilbarbara/types';

// import * as merge from 'deepmerge';

export interface IObjectWithId {
    Id: string;
    Parent?: IObjectWithId;
    [key: string]: any;
}

interface IGetResponse {
    object: IObjectWithId;
}

interface ISelectResponse {
    objects: IObjectWithId[];
    filters: string;
}

const initialStore = new Map<
    string,
    Omit<
        Response,
        | 'request_id'
        | 'session_id'
        | 'status_code'
        | 'load_meta'
        | 'load_object'
        | 'get'
        | 'get_param'
        | 'select'
        | 'logs'
    > & {
        get?: { [key: string | number]: IGetResponse };
        params?: { [param_name: string]: GetParamResponse };
        logs?: { [request_id: string]: LogsResponse };
        select?: ISelectResponse;
        [key: string]: unknown | unknown[];
    }
>();

const sortByChildIndexRule = (a: PlainObject<any>, b: PlainObject<any>) => {
    // Если ChildIndex отсутствует, приравниваем его к 0
    const indexA = a.ChildIndex ?? 0;
    const indexB = b.ChildIndex ?? 0;

    return indexA - indexB;
};

export class MetaStore {
    private api;

    meta = initialStore;

    constructor() {
        console.log('[Meta Store] created');
        makeAutoObservable(this);
        // makePersistable(this, {
        //     name: 'MetaStore',
        //     properties: ['meta'],
        //     storage: window.sessionStorage
        // });

        this.api = new ServerApi(window.env.BACKEND_API_URL);

        window.api = this.api;
    }

    clear = () => {
        console.log('[Meta Store] clear');
        this.meta.clear();
    };

    delete = (meta: string, category: string) => {
        const prevMeta = this.meta.get(meta);
        this.meta.set(meta, { ...prevMeta, [category]: undefined });
    };

    add = (object: IObjectWithId, meta: string, category: string) => {
        const prevMeta = toJS(this.meta.get(meta));
        if (!prevMeta?.[category]) return;

        // console.log('category', category);
        // console.log('meta', meta);
        // console.log('object', object);
        console.log('[Meta Store] old meta, before adding:', prevMeta?.[category]);

        if (Array.isArray(prevMeta?.[category])) {
            const notChangedData = prevMeta?.[category].filter((item) => item?.Id !== object?.Id);

            // const changedData = prevMeta?.[category]?.find((item) => item.Id === object?.Id);

            this.meta.set(meta, {
                ...prevMeta,
                [category]: [
                    ...notChangedData,
                    object
                    // {
                    //     ...changedData,
                    //     ...object
                    // }
                ]
            });
        } else if (Object.hasOwn(prevMeta?.[category], 'object')) {
            this.meta.set(meta, {
                ...prevMeta,
                [category]: { object: { ...prevMeta?.[category]?.object, ...object } }
            });
        } else if (Object.hasOwn(prevMeta?.[category], 'objects')) {
            const notChangedData = prevMeta?.[category]?.objects?.filter(
                (item) => item?.Id !== object?.Id
            );
            // const changedData = prevMeta?.[category]?.objects?.find(
            //     (item) => item.Id === object?.Id
            // );

            this.meta.set(meta, {
                ...prevMeta,
                [category]: {
                    ...prevMeta?.[category],
                    objects: [
                        ...notChangedData,
                        object
                        // {
                        //     ...changedData,
                        //     ...object
                        // }
                    ]
                }
            });
        } else {
            this.meta.set(meta, {
                ...prevMeta,
                [category]: { ...prevMeta?.[category], ...object }
            });
        }

        console.log('[Meta Store] new meta, after adding:', toJS(this.meta.get(meta)?.[category]));

        // this.meta.set(meta, { ...prevMeta, [category]: prevMeta[category] });
    };

    getInfo = async (meta: string, currentPath?: string) => {
        const res = await this.api.info({ meta });

        const prevMeta = this.meta.get(meta);
        const commonMeta = this.meta.get('all');

        const layout = toJS(
            commonMeta?.routes?.find(
                (route) => route.meta === meta && route.layout && currentPath === route.path
            )?.layout
        );

        const layoutInfo = layout?.Info?.Info || layout?.Info;

        // console.log(meta, layout);

        if (res) {
            let mergedRes = res;

            if (layoutInfo) {
                // layoutInfo.Fields = undefined;
                // const mergedInfo = _.merge(res, layoutInfo);
                const combineMerge = (
                    target: any[],
                    source: any[],
                    options: merge.ArrayMergeOptions
                ) => {
                    const destination = target.slice();

                    source.forEach((item, index) => {
                        const destIndex = destination.findIndex((destItem) => {
                            if (destItem.FieldName) return destItem.FieldName === item.FieldName;
                            if (destItem.Code) return destItem.Code === item.Code;
                            return destItem.Id === item.Id;
                        });
                        if (destIndex !== -1) {
                            // console.log(
                            //     destination[destIndex],
                            //     merge(destination[destIndex], item, options)
                            // );
                            destination[destIndex] = merge(destination[destIndex], item, options);
                        } else {
                            destination.push(item);
                        }
                    });

                    if (destination.length === 0 && source.length !== 0) return source;

                    return destination;
                };

                mergedRes = merge(res, removeUndefinedFields(layoutInfo), {
                    arrayMerge: combineMerge
                });

                mergedRes = { ...mergedRes, Fields: mergedRes.Fields.sort(sortByChildIndexRule) };
                // console.log('MERGED', mergedRes);
                // const mergedInfoFields = _.merge(mergedInfo.Fields, layoutInfo.Fields);
                // console.log(mergedInfoFields);
            }

            runInAction(() => {
                this.meta.set(meta, { ...prevMeta, info: { ...prevMeta?.info, ...mergedRes } });
            });

            // console.log({ ...prevMeta?.info, ...mergedRes });
        }

        return res;
    };

    makeRun = async (options: RunRequest) => {
        const res = await this.api.run(options);

        const prevMeta = this.meta.get(options.meta);

        if (res) {
            this.meta.set(options.meta, { ...prevMeta, run: { ...prevMeta?.run, ...res.run! } });
        }

        return res;
    };

    getRoutes = async () => {
        const res = await this.api.routes();

        const prevMeta = this.meta.get('all');

        if (res) {
            runInAction(() => {
                this.meta.set('all', { ...prevMeta, routes: res });
            });
        }

        return res;
    };

    getMenu = async () => {
        const res = await this.api.menu();

        const prevMeta = this.meta.get('all');

        if (res) {
            runInAction(() => {
                this.meta.set('all', { ...prevMeta, menu: { ...prevMeta?.menu, ...res } });
            });
        }

        return res;
    };

    getAssociation = async (options: AssociationRequest) => {
        const res = await this.api.association(options);

        const prevMeta = this.meta.get('all');

        if (res) {
            this.meta.set('all', {
                ...prevMeta,
                association: { ...prevMeta?.association, ...res }
            });
        }

        return res;
    };

    makeSelect = async (options: { meta: CODE } & Partial<Omit<SelectRequest, 'meta'>>) => {
        const res = await (this.api.select(options) as Promise<ISelectResponse>);

        const prevMeta = this.meta.get(options.meta);

        if (res) {
            runInAction(() => {
                this.meta.set(options.meta, {
                    ...prevMeta,
                    select: { ...prevMeta?.select, ...res, filters: options.filters }
                });
            });
        }

        return res;
    };

    makeGet = async (options: GetRequest) => {
        const res = await this.api.get(options);

        const prevMeta = this.meta.get(options.meta);

        if (res) {
            runInAction(() => {
                this.meta.set(options.meta, {
                    ...prevMeta,
                    get: { ...prevMeta?.get, [options.id]: res }
                });
            });
        }

        return res;
    };

    acquireLock = async (options: AcquireLockRequest) => {
        const res = await this.api.acquireLock(options);

        const prevMeta = this.meta.get(options.meta);

        if (res) {
            runInAction(() => {
                this.meta.set(options.meta, {
                    ...prevMeta,
                    acquire_lock: { ...prevMeta?.acquire_lock, ...res }
                });
            });
        }

        return res;
    };

    releaseLock = async (options: ReleaseLockRequest) => {
        const res = await this.api.releaseLock(options);

        return res;
    };

    extendLock = async (options: ExtendLockRequest) => {
        const res = await this.api.extendLock(options);

        return res;
    };

    makeSave = async (options: SaveRequest) => {
        const res = await this.api.save(options);

        const prevMeta = this.meta.get(options.meta);

        if (res) {
            runInAction(() => {
                this.meta.set(options.meta, {
                    ...prevMeta,
                    save: { ...prevMeta?.save, ...res }
                });
            });
        }

        return res;
    };

    getParam = async (options: GetParamRequestWithDefault) => {
        const res = await this.api.get_param(options);

        const prevMeta = this.meta.get('all');

        if (res) {
            runInAction(() => {
                this.meta.set('all', {
                    ...prevMeta,
                    params: { ...prevMeta?.params, [options.param_name]: res }
                });
            });
        }

        return res;
    };

    getLogs = async (options: LogsRequest) => {
        const res = await this.api.logs(options);

        const prevMeta = this.meta.get('all');

        if (res) {
            runInAction(() => {
                this.meta.set('all', {
                    ...prevMeta,
                    logs: { ...prevMeta?.logs, [options.request_id]: res }
                });
            });
        }

        return res;
    };

    // getAllInfos = async () => {
    //     const res = await this.api.select({ meta: 'InfoMeta' });
    //
    //     if (res) {
    //         res.objects?.forEach((meta) => {
    //             this.meta.set(meta.Code, {});
    //         });
    //     }
    // };
}

export const metaStore = new MetaStore();
