import * as AntdIcons from '@ant-design/icons';
import {
    CaretDownOutlined,
    CopyOutlined,
    DeleteOutlined,
    FilePdfOutlined,
    MenuOutlined,
    PlusOutlined,
    ReloadOutlined,
    ShareAltOutlined
} from '@ant-design/icons';
import { PlainObject } from '@gilbarbara/types';
import { Dropdown, Flex, MenuProps, Space } from 'antd';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useMedia, useWindowSize } from 'react-use';

import { Filter } from 'modules/supabase/utils/supabaseClient';
import { ButtonWithTooltips } from 'ui';
import { LANGUAGES } from 'utils/i18n/i18n';
import { metaStore } from 'utils/store/MetaStore';

import { Response } from 'modules/services/backend-api/generated_api';
import { Action, MetaField } from 'modules/services/backend-api/generated_info';
import { HelpIcon } from 'smart/ui';

import { LocalSearchComponent } from '../LocalSearchComponent/LocalSearchComponent';
import { SmartTableFilterMenuToolbarButton } from '../SmartTableFilterMenu/SmartTableFilterMenuToolbarButton';
import { FilterField } from '../SmartTableFilterMenu/types';

type MenuItem = NonNullable<MenuProps['items']>[number] & { index?: number; children?: MenuItem[] };

type AntdIconsType = Omit<
    typeof AntdIcons,
    'IconProvider' | 'setTwoToneColor' | 'getTwoToneColor' | 'createFromIconfontCN'
>;

interface SmartTablePageToolbarProps {
    meta: string;
    selectedRowKeys: string[];
    hasSelected: boolean;
    searchFilter?: string;
    setSearchFilter: (filter: string) => void;
    filters: FilterField[];
    setFilters: Dispatch<SetStateAction<FilterField[]>>;
    onRefresh: () => any;
    onCreate: () => void | Promise<void>;
    onCopy: () => void | Promise<void>;
    onDelete: () => void | Promise<void>;
    navigateAfterRun: (
        actionResponse: Response,
        action: Action,
        filters: FilterField[] | Filter[],
        metaFields?: MetaField[]
    ) => void;
    onClickAction: (
        action: Action,
        hardIds?: string[],
        hardArgs?: PlainObject
    ) => Promise<Response | undefined>;

    detailsRenderer?: () => React.ReactNode;

    // setContextMenuActions?: Dispatch<SetStateAction<MenuProps['items']>>;
    showComponents?: {
        isReload?: boolean;
        isSearch?: boolean;
        isFilter?: boolean;
        isRightPart?: boolean;
        isDisplayViewPreferences?: boolean;
        isCreate?: boolean;
        isDelete?: boolean;
        isDescription?: boolean;
        isActions?: boolean;
        isFollows?: boolean;
        isReports?: boolean;
        isActionButtons?: boolean;
    };
}

const initComponentsDisplay = {
    isReload: true,
    isSearch: true,
    isFilter: true,
    isRightPart: true,
    isDisplayViewPreferences: true,
    isCreate: true,
    isDelete: true,
    isDescription: true,
    isActions: true,
    isFollows: true,
    isReports: true,
    isActionButtons: true,
    isDetails: true
};

export const SmartTablePageHeaderToolbar = observer<SmartTablePageToolbarProps>((props) => {
    const {
        meta,
        selectedRowKeys,
        hasSelected,
        searchFilter,
        setSearchFilter,
        filters,
        setFilters,
        onRefresh,
        onCreate,
        onCopy,
        onDelete,
        showComponents: showComponentsLocal,
        onClickAction,
        navigateAfterRun,

        detailsRenderer
        // setContextMenuActions
    } = props;

    const location = useLocation();
    const {
        t,
        i18n: { language }
    } = useTranslation();

    const metaData = toJS(metaStore.meta.get(meta));
    const actions = metaData?.info?.Actions?.sort((action) =>
        action.Type_Code === 'FOLDER' ? -1 : 1
    );
    const fields = metaData?.info?.Fields;
    // console.log(toJS(metaStore.meta.get('all')?.menu));
    const currentNavItem = toJS(metaStore.meta.get('all')?.routes)?.find(
        (route) =>
            route.path ===
            `${location.pathname}${
                location.state?.filterString ? `?${location.state?.filterString}` : ''
            }`
    );
    // console.log(currentNavItem);
    const isMetaReadOnly = metaData?.info?.IsReadOnly || false;
    const uiAllowCreate = metaData?.info?.UiAllowCreate;
    const uiAllowDelete = metaData?.info?.UiAllowDelete;
    const metaDescription = metaData?.info?.Description?.[language];

    // REESPONSIVE
    const { width } = useWindowSize();
    const isShowActionButtons = width > 660;
    const isShowActionButtonsLabel = width > 980;
    const isSmallTablet = useMedia('(max-width: 620px)');
    const isSmallMobile = useMedia('(max-width: 390px)');

    const showComponents = useMemo(
        () => ({ ...initComponentsDisplay, ...showComponentsLocal }),
        [showComponentsLocal]
    );

    const actionsMenuItems = useMemo(() => {
        const folders: MenuItem[] = [];
        const folderless: MenuItem[] = [];

        for (const action of actions ?? []) {
            if (
                !action.IsVisibleInList ||
                (isShowActionButtons && action.IsButton) ||
                !action.IsActive ||
                (action.AllowedForNavItems?.length &&
                    !action.AllowedForNavItems.includes(currentNavItem?.id as string)) || // ID есть всегда - это я подразумеваю
                ((action.Handler_Code === 'Delete' || action.Handler_Code === 'SoftDelete') &&
                    !uiAllowDelete)
            ) {
                continue;
            }

            if (action.IsFolder || action.Type_Code === 'FOLDER') {
                folders.push({
                    key: action.Id!,
                    index: action.ChildIndex,
                    label: action.Name[language as LANGUAGES],
                    disabled: action.IsDisabled,
                    children: []
                });
            }

            if (!action.IsFolder && action.Type_Code === 'HANDLER') {
                const parentId = action.Parent_Id;

                const folderIndex = folders.findIndex(
                    (folder) => folder && folder.key === parentId
                );

                const item = {
                    key: action.Id,
                    label: action.Name[language as LANGUAGES],
                    disabled:
                        (!action?.Handler?.IsForNoneObjects && !hasSelected) ||
                        action.IsDisabled ||
                        (action.IsSingle && selectedRowKeys.length > 1),
                    onClick: () => onClickAction(action)
                };

                if (folderIndex !== -1) {
                    folders[folderIndex]?.children?.push(item);
                } else {
                    folderless.push(item);
                }
            }

            if (!action.IsFolder && action.Type_Code === 'EVENT') {
                const parentId = action.Parent_Id;

                const folderIndex = folders.findIndex(
                    (folder) => folder && folder.key === parentId
                );

                const item = {
                    key: action.Id,
                    label: action.Name[language as LANGUAGES],
                    disabled:
                        (!action?.Handler?.IsForNoneObjects && !hasSelected) ||
                        action.IsDisabled ||
                        (action.IsSingle && selectedRowKeys.length > 1),
                    onClick: () =>
                        onClickAction(
                            {
                                ...action,
                                Handler_Code: 'RaiseEvent',
                                Handler: { ...action.Handler, Code: 'RaiseEvent' }
                            },
                            undefined,
                            {
                                event_type: action.EventType_Code
                            }
                        )
                };

                if (folderIndex !== -1) {
                    folders[folderIndex]?.children?.push(item);
                } else {
                    folderless.push(item);
                }
            }
        }

        return [...folders.sort((a, b) => Number(a?.index) - Number(b?.index)), ...folderless];
    }, [
        actions,
        isShowActionButtons,
        currentNavItem?.id,
        uiAllowDelete,
        language,
        hasSelected,
        selectedRowKeys.length,
        onClickAction
    ]);

    const reportsMenuItems = useMemo(() => {
        const reports: MenuItem[] = [];

        for (const action of actions ?? []) {
            if (
                !action.IsVisibleInList ||
                !action.IsActive ||
                (action.AllowedForNavItems?.length &&
                    !action.AllowedForNavItems.includes(currentNavItem?.id as string)) || // ID есть всегда - это я подразумеваю
                ((action.Handler_Code === 'Delete' || action.Handler_Code === 'SoftDelete') &&
                    !uiAllowDelete)
            ) {
                continue;
            }

            if (!action.IsFolder && action.Type_Code === 'REPORT') {
                const item = {
                    key: action.Id,
                    label: action.Name[language as LANGUAGES],
                    disabled:
                        (!action?.Handler?.IsForNoneObjects && !hasSelected) || action.IsDisabled,
                    onClick: async () => {
                        const response = await onClickAction(action);

                        if (response) {
                            navigateAfterRun(response, action, filters);
                        }
                    }
                };

                reports.push(item);
            }

            if (!action.IsFolder && action.Type_Code === 'ANALYTIC') {
                const item = {
                    key: action.Id,
                    label: action.Name[language as LANGUAGES],
                    disabled:
                        (!action?.Handler?.IsForNoneObjects && !hasSelected) || action.IsDisabled,
                    onClick: async () => {
                        const response = await onClickAction(action);

                        if (response) {
                            navigateAfterRun(
                                {
                                    session_id: '',
                                    request_id: '',
                                    status_code: 200,
                                    run: response.run
                                },
                                action,
                                filters
                            );
                        }
                    }
                };

                reports.push(item);
            }
        }

        return reports;
    }, [
        actions,
        currentNavItem?.id,
        uiAllowDelete,
        language,
        hasSelected,
        onClickAction,
        navigateAfterRun,
        filters
    ]);

    const followsMenuItems = useMemo(() => {
        const follows: MenuItem[] = [];

        for (const action of actions ?? []) {
            if (
                !action.IsVisibleInList ||
                !action.IsActive ||
                (action.AllowedForNavItems?.length &&
                    !action.AllowedForNavItems.includes(currentNavItem?.id as string)) || // ID есть всегда - это я подразумеваю
                ((action.Handler_Code === 'Delete' || action.Handler_Code === 'SoftDelete') &&
                    !uiAllowDelete)
            ) {
                continue;
            }

            if (!action.IsFolder && action.Type_Code === 'ASSOTIATION') {
                const item = {
                    key: action.Id,
                    label: action.Name[language as LANGUAGES],
                    disabled:
                        (!action?.Handler?.IsForNoneObjects && !hasSelected) || action.IsDisabled,
                    onClick: async () => {
                        let filters: Filter[] = [];
                        const metaField = fields?.find((field) => field.FieldName === 'Id');

                        let actionResponse = { session_id: '', request_id: '', status_code: 200 };

                        if (action.Association) {
                            const association = await metaStore.getAssociation({
                                association: action.Association.Code,
                                ids: selectedRowKeys
                            });

                            filters = [
                                {
                                    column: 'id',
                                    operator: 'in_list',
                                    value: association?.ids
                                }
                            ];
                        } else if (action.Handler) {
                            const response = await onClickAction(action);

                            if (action.IsNavigationFirst) return;

                            if (response) {
                                actionResponse = response;
                            }
                        } else {
                            filters = [
                                {
                                    column: 'id',
                                    operator: 'in_list',
                                    value: selectedRowKeys
                                }
                            ];
                        }

                        if (actionResponse) {
                            navigateAfterRun(
                                actionResponse,
                                action,
                                filters,
                                metaField ? [metaField] : []
                            );
                        }
                    }
                };

                follows.push(item);
            }
        }

        return follows;
    }, [
        onClickAction,
        actions,
        currentNavItem?.id,
        uiAllowDelete,
        language,
        hasSelected,
        fields,
        navigateAfterRun,
        selectedRowKeys
    ]);

    // const handleOpenDpModal = async () => {
    //     setDpModal(true);
    // };

    const actionButtons = useMemo(() => {
        if (actions) {
            return actions
                .filter((action) => {
                    const cond =
                        action.IsButton &&
                        action.IsVisibleInList &&
                        action.IsActive &&
                        (!action.AllowedForNavItems?.length ||
                            action.AllowedForNavItems?.includes(currentNavItem?.id as string));

                    if (!uiAllowDelete) {
                        return (
                            cond &&
                            action.Handler_Code !== 'Delete' &&
                            action.Handler_Code !== 'SoftDelete'
                        );
                    }

                    return cond; // ID есть всегда - это я подразумеваю
                })
                .map((action) => {
                    const isDisabled =
                        (!action?.Handler?.IsForNoneObjects && !hasSelected) ||
                        (action.IsSingle && selectedRowKeys?.length > 1);

                    const Icon = action.Icon
                        ? AntdIcons[action.Icon as keyof AntdIconsType]
                        : () => <></>;

                    return (
                        <ButtonWithTooltips
                            type={'default'}
                            className={''}
                            disabled={isDisabled}
                            tooltipTitle={
                                isShowActionButtonsLabel ? action.Name?.[language] : undefined
                            }
                            id={action.Id || ''}
                            onClick={() => onClickAction(action)}
                            icon={<Icon />}
                        >
                            {isShowActionButtonsLabel ? action.Name?.[language] : null}
                        </ButtonWithTooltips>
                    );
                });
        }

        return null;
    }, [
        actions,
        currentNavItem?.id,
        uiAllowDelete,
        hasSelected,
        selectedRowKeys?.length,
        isShowActionButtonsLabel,
        language,
        onClickAction
    ]);

    return (
        <Flex justify="space-between" className="smart_table__header">
            {/* ### Дейсвия (мета) ### */}
            <Space.Compact className="smart_table__header_left">
                {actionsMenuItems.length && showComponents.isActions ? (
                    <Dropdown
                        menu={{ items: actionsMenuItems }}
                        trigger={['click']}
                        placement="bottomLeft"
                        destroyPopupOnHide
                    >
                        <ButtonWithTooltips type={'default'} className={''} id="actions">
                            {isSmallTablet ? <MenuOutlined /> : t('actions')}
                            {!isSmallMobile && <CaretDownOutlined />}
                        </ButtonWithTooltips>
                    </Dropdown>
                ) : null}

                {/* ### Отчеты (мета) ### */}
                {reportsMenuItems.length && showComponents.isReports ? (
                    <Dropdown
                        menu={{ items: reportsMenuItems }}
                        trigger={['click']}
                        placement="bottomLeft"
                        destroyPopupOnHide
                    >
                        <ButtonWithTooltips type={'default'} className={''} id="reports">
                            {isSmallTablet ? <FilePdfOutlined /> : t('reports')}
                            {!isSmallMobile && <CaretDownOutlined />}
                        </ButtonWithTooltips>
                    </Dropdown>
                ) : null}

                {/* ### Переходы (мета) ### */}
                {followsMenuItems.length && showComponents.isFollows ? (
                    <Dropdown
                        menu={{ items: followsMenuItems }}
                        trigger={['click']}
                        placement="bottomLeft"
                        destroyPopupOnHide
                    >
                        <ButtonWithTooltips type={'default'} className={''} id="follows">
                            {isSmallTablet ? <ShareAltOutlined /> : t('follows')}
                            {!isSmallMobile && <CaretDownOutlined />}
                        </ButtonWithTooltips>
                    </Dropdown>
                ) : null}

                {!!detailsRenderer && detailsRenderer()}

                {/* ### Обновить ### */}
                {showComponents.isReload ? (
                    <ButtonWithTooltips
                        id="reload"
                        tooltipTitle={t('reload')}
                        tooltipPlacement="top"
                        onClick={onRefresh}
                        icon={<ReloadOutlined />}
                        type={'default'}
                        className={''}
                    />
                ) : null}

                {/* ### Удалить ### */}
                {uiAllowDelete && showComponents.isDelete ? (
                    <ButtonWithTooltips
                        id="delete"
                        tooltipTitle={t('delete')}
                        tooltipPlacement="top"
                        onClick={onDelete}
                        icon={<DeleteOutlined />}
                        disabled={!hasSelected || isMetaReadOnly}
                        type={'default'}
                        className={''}
                    />
                ) : null}

                {/* ### Создать ### */}
                {uiAllowCreate && showComponents.isCreate ? (
                    <ButtonWithTooltips
                        id="create"
                        tooltipTitle={t('create')}
                        onClick={onCreate}
                        icon={<PlusOutlined />}
                        type={'default'}
                        className={''}
                        disabled={isMetaReadOnly}
                    />
                ) : null}

                {/* ### Скопировать ### */}
                {uiAllowCreate && showComponents.isCreate ? (
                    <ButtonWithTooltips
                        id="copy"
                        tooltipTitle={t('copy')}
                        onClick={onCopy}
                        disabled={!hasSelected || isMetaReadOnly}
                        icon={<CopyOutlined />}
                        type={'default'}
                        className={''}
                    />
                ) : null}

                {/* ### Описание ### */}
                {showComponents.isDescription && metaDescription ? (
                    <HelpIcon
                        text={metaDescription}
                        style={{ width: 32 }}
                        iconStyle={{ color: 'rgba(0, 0, 0, 0.88)' }}
                        trigger="click"
                    />
                ) : null}

                {isShowActionButtons ? actionButtons : null}
            </Space.Compact>

            <Flex className="smart_table__header_center">
                {/* TODO: center part of toolbar */}
                <></>
            </Flex>

            {showComponents.isRightPart && (
                <Space.Compact className="smart_table__header_right">
                    {/* {showComponents.isDisplayViewPreferences ? (
                        <ButtonWithTooltips
                            id="view_display_preferences"
                            type="default"
                            className=""
                            onClick={handleOpenDpModal}
                            icon={<SettingOutlined />}
                        />
                    ) : null} */}
                    {showComponents.isSearch ? (
                        <LocalSearchComponent
                            searchFilter={searchFilter}
                            setFilter={setSearchFilter}
                            placeholder={`${t('search')}...`}
                        />
                    ) : null}
                    {showComponents.isFilter ? (
                        <SmartTableFilterMenuToolbarButton
                            meta={meta}
                            filters={filters}
                            setFilters={setFilters}
                        />
                    ) : null}
                </Space.Compact>
            )}

            {/* {showComponents.isDisplayViewPreferences && (
                <ViewDisplayPreferencesModal
                    open={dpModal}
                    onCancel={() => setDpModal(false)}
                    onOk={() => setDpModal(false)}
                />
            )} */}
        </Flex>
    );
});
