import { MRT_FilterOption } from 'material-react-table';

import { MetaField } from 'modules/services/backend-api/generated_info';

export type SupabaseFilterOperator =
    | 'eq'
    | 'neq'
    | 'gt'
    | 'gte'
    | 'lt'
    | 'lte'
    | 'like'
    | 'ilike'
    | 'is'
    | 'in'
    | 'cs'
    | 'cd'
    | 'sl'
    | 'sr'
    | 'nxl'
    | 'nxr'
    | 'adj'
    | 'ov'
    | 'fts'
    | 'plfts'
    | 'phfts'
    | 'wfts'
    | 'not_in'
    | MRT_FilterOption;

export type FilterOption = MRT_FilterOption | 'in_list' | 'not_in_list' | 'dynamic_range';

export const AVAILABLE_FILTER_OPERATORS: MRT_FilterOption[] = ['contains', 'equals', 'notEquals'];
export const DEFAULT_FILTER_OPERTAION: MRT_FilterOption = 'equals';

export type FilterFieldValue = {
    operator?: SupabaseFilterOperator;
    value?: any;
    column?: string;
};

export type InitialFilterFieldValue = {
    field?: string;
    values: FilterFieldValue[];
};

export type ValuesWithErrorType = {
    field?: string;
    operator?: string;
    error?: string;
};

export type FilterField = {
    field: MetaField;
    values: FilterFieldValue[];
};

export type LocalFilterValueType = FilterFieldValue & {
    index: number;
    lastValue: boolean;
    field: MetaField;
};

export type ConvertFilterFunctionType = {
    complexFilter: FilterField;
    complexFilterValue: FilterFieldValue;
    language: string;
};
