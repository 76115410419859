import React, { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { LANGUAGES } from 'utils/i18n/i18n';

import { googleTranslateText } from 'utils/i18n/libs/material-react-table/translateUtils';

import { Flex, Input, Modal } from 'antd';
import { ButtonWithTooltips } from 'ui';
import { MultilanguageValueType } from '../../types';

interface LanguageTranslateDialogProps {
    value: MultilanguageValueType;
    setValue: (newValue: MultilanguageValueType) => void;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    fieldName?: string;
}

export const LanguageTranslateDialog = ({
    value,
    setValue,
    open,
    setOpen,
    fieldName = ''
}: LanguageTranslateDialogProps) => {
    const {
        t,
        i18n: { language: currentLanguage }
    } = useTranslation();

    const [multilanguageValue, setMultilanguageValue] = useState<MultilanguageValueType>(value);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleApply = useCallback(() => {
        const withoutEmptyValues: Record<string, string> = {};

        for (let lang in multilanguageValue) {
            if (multilanguageValue[lang]) {
                withoutEmptyValues[lang] = multilanguageValue[lang];
            }
        }

        setValue(withoutEmptyValues);
        setOpen(false);
    }, [setValue, setOpen, multilanguageValue]);

    const translateText = useCallback(
        async (textToTranslate: string, fromLanguage: LANGUAGES, toLanguage: LANGUAGES) => {
            const translationText = await googleTranslateText(
                textToTranslate,
                fromLanguage,
                toLanguage
            );

            const copyVal: MultilanguageValueType = { ...multilanguageValue };
            copyVal[toLanguage] = translationText;

            setMultilanguageValue(copyVal);
        },
        [setMultilanguageValue, multilanguageValue]
    );

    const handleValueChange = useCallback(
        (newValue: string, language: LANGUAGES) => {
            const copyVal: MultilanguageValueType = { ...multilanguageValue };

            copyVal[language] = newValue || undefined;

            setMultilanguageValue(copyVal);
        },
        [setMultilanguageValue, multilanguageValue]
    );

    return (
        <Modal
            open={open}
            onCancel={handleClose}
            onOk={handleApply}
            title={fieldName}
            cancelText={t('cancel')}
            okText={t('apply')}
            cancelButtonProps={{ type: 'text', className: 'btn btn-red' }}
            okButtonProps={{ className: 'btn btn-blue' }}
            centered
            destroyOnClose
        >
            <Flex gap={5} vertical>
                {multilanguageValue &&
                    Object.keys(LANGUAGES)
                        .sort((language) => (language === currentLanguage ? -1 : 1))
                        .map((language) =>
                            language !== 'tech' ? (
                                <Flex gap={5} key={language}>
                                    <Input
                                        value={
                                            multilanguageValue
                                                ? multilanguageValue[language as LANGUAGES] || ''
                                                : ''
                                        }
                                        onChange={(e) => {
                                            handleValueChange(
                                                e.target.value,
                                                language as LANGUAGES
                                            );
                                        }}
                                    />

                                    <ButtonWithTooltips
                                        type="link"
                                        id="languageTranslate"
                                        className="languageTranslate"
                                        style={{
                                            minWidth: '35%',
                                            textAlign: 'left'
                                        }}
                                        onClick={() => {
                                            translateText(
                                                multilanguageValue[currentLanguage as LANGUAGES] ||
                                                    '',
                                                currentLanguage as LANGUAGES,
                                                language as LANGUAGES
                                            );
                                        }}
                                        disabled={language === currentLanguage}
                                    >
                                        {t(language)}
                                    </ButtonWithTooltips>
                                </Flex>
                            ) : null
                        )}
            </Flex>
        </Modal>
    );
};
