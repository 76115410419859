import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { TableName, ViewName } from 'modules/supabase/types/Dataset';

import { Modal } from 'antd';
// import { PrintingFormTab } from 'components/Tabs/PrintingFormTab';
import { ButtonWithTooltips } from 'ui';

export const SelectPrintingFormModal = ({
    open,
    setOpen,
    documentIds,
    tableName,
    viewName
}: {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    documentIds?: number[];
    tableName: TableName;
    viewName: ViewName;
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            centered
            title={t('select_printing_from')}
            open={open}
            onCancel={() => setOpen(false)}
            footer={[
                <ButtonWithTooltips
                    id="apply"
                    tooltipTitle={t('apply')}
                    key="submit"
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    {t('apply')}
                </ButtonWithTooltips>
            ]}
        >
            {/* <PrintingFormTab
                tableName={tableName}
                viewName={viewName}
                massPrintOption={{
                    enabled: true,
                    ids: documentIds
                }}
            /> */}
        </Modal>
    );
};
