import { DevSupport } from '@react-buddy/ide-toolbox';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';

import { createRoot } from 'react-dom/client';
import { App } from 'App';
import { SupabaseProvider } from './modules/supabase/contexts/SupabaseContext/SupabaseContext';

import { reportWebVitals } from './reportWebVitals';
import './index.css';
import './utils/i18n/i18n';
import { ComponentPreviews, useInitial } from './dev';

const rootElement = document.getElementById('root');

// чтобы у всех запросов тансктака были общие настройки
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            retry: false,
            staleTime: 0,
            gcTime: 0
        }
    }
});

const ITVecturaApp: React.FC = () => (
    <SupabaseProvider>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <DevSupport ComponentPreviews={ComponentPreviews} useInitialHook={useInitial}>
                    <App />
                </DevSupport>
            </BrowserRouter>
        </QueryClientProvider>
    </SupabaseProvider>
);

if (rootElement) {
    const container = document.getElementById('root');
    const root = createRoot(container!); // createRoot(container!) if you use TypeScript
    root.render(<ITVecturaApp />);
} else {
    // eslint-disable-next-line no-console
    console.error('Root element not found!');
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export default ITVecturaApp;
