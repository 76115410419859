import { useEffect, useState } from 'react';
import { metaStore } from 'utils/store/MetaStore';

export const useAppVersion = () => {
    const [version, setVersion] = useState('');

    useEffect(() => {
        const setParam = async () => {
            const response = await metaStore.getParam({
                param_name: 'APP_VERSION'
            });

            setVersion(response);
        };

        setParam();
    }, []);

    return version ? `v. ${version}` : '';
};
