import { DescriptionOutlined } from '@mui/icons-material';
import { lazy } from 'react';
import { Routes } from 'routing/route';
import { RouteTypeMassive } from 'routing/routesTypes';

const BookingSlotsPage = lazy(() =>
    import('smart/pages/BookingSlotsPage').then((module) => ({
        default: module.BookingSlotsPage
    }))
);

const transactionalDataApps: RouteTypeMassive[] = [
    {
        path: 'resource-planner',
        tablePage: BookingSlotsPage,
        config: {
            icon: <DescriptionOutlined />
        }
    }
];
export const transactionalDataRoutes = new Routes('/transactional-data', transactionalDataApps);
