import { MenuTheme } from 'antd';

export const ASIDE_OPEN_WIDTH = 320;
export const ASIDE_CLOSE_WIDTH = 50;
export const ASIDE_CLOSE_WIDTH_MOBILE = 0;

export const THEME: MenuTheme = 'dark';

export const EXCLUDE_ROUTINGS = [
    '/dashboards-folder',
    '/master-data',
    '/etran-rails',
    '/settings',
    '/warehouse-master-data',
    '/warehouse-docs',
    '/warehouse-reports',
    '/warehouse-settings',
    '/vehicle-monitoring',
    '/transactional-data',
    '/mutual-settlements',
    '/execution',
    '/tms-reports',
    '/orders-management'
];

export const USER_ID_REGX = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;
export const ID_REGX = /^\d+$/;
