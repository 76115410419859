import { PlusOutlined } from '@ant-design/icons';
import { Checkbox, DescriptionsProps, Input, InputNumber, Modal } from 'antd';
import { isDefined, isEmpty, isNumericString } from 'is-lite/exports';
import { isEqual } from 'lodash';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffectOnce, useMountedState } from 'react-use';

import {
    Action,
    HandlerParam,
    HandlerRun,
    MetaField
} from 'modules/services/backend-api/generated_info';
import { ButtonWithTooltips } from 'ui';
import { camelize } from 'utils';
import { useNotifications } from 'utils/hooks';
import { metaStore } from 'utils/store/MetaStore';
import { FilterField } from 'smart/modules/SmartTablePage/components/SmartTableFilterMenu/types';
import { Response } from 'modules/services/backend-api/generated_api';
import { ANY_DATA } from 'modules/services/backend-api/generated_types';
import { Filter } from 'modules/supabase/utils/supabaseClient';
import { IHandlerWithId } from 'smart/modules/SmartDetailPage/components/SmartDetailPageHeaderToolbar/SmartDetailPageHeaderToolbar';
import {
    getExcludeFieldsPattern,
    getNestedValueFromObject,
    JSONSafeParse,
    parseValueType
} from 'smart/utils';
import { useHandlerRun } from 'smart/utils/hooks';
import { toPascalCase } from 'utils/helpers/toPascalCase';

import {
    FilePickerField,
    JsonField,
    PasswordField,
    SmartDateRangeField,
    SmartMultilanguageField
} from '..';
import { SmartDateField } from '../SmartDateField/SmartDateField';
import { SmartDatetimeRangeField } from '../SmartDatetimeRangeField/SmartDatetimeRangeField';
import { SmartDurationField } from '../SmartDurationField/SmartDurationField';
import { SmartMultiSelectField } from '../SmartMultiSelectField/SmartMultiSelectField';
import { SmartSelectField } from '../SmartSelectField/SmartSelectField';
import { useChooseFieldsModal } from './ui/useChooseFieldsModal';
import { FieldsBox } from '../../modules/SmartDetailPage/ui';

export interface HandlerRunModalProps {
    open: boolean;
    onCancel: () => void;
    action: Action | null;
    ids: string[];
    metaName: string;
    onRefresh: () => void;
    onResponseCallback?: (response: (HandlerRun | undefined)[]) => void;
    setSelectedRows?: (rows: any[]) => void;
    // filters?: FilterField[] | Filter[]; // filters for navigate if exists
    filters?: FilterField[]; // filters for navigate if exists
    // navigate function if need after run
    navigateAfterRun?: (
        actionResponse: Response,
        action: Action,
        filters: FilterField[] | Filter[],
        metaFields?: MetaField[]
    ) => void;
    row?: any;
    meta?: string;
    disableNotify?: boolean;
}

const getParamCode = (param: HandlerParam) => {
    const paramCode = param?.ParamName || camelize(param.Name?.en) || `p${param.ChildIndex}`;

    return paramCode;
};

const DATE_FIELD_WIDTH = 170;
const NUMBER_FIELD_WIDTH = 170;
const DATE_RANGE_FIELD_WIDTH = 400;

export const HandlerRunModal = observer<HandlerRunModalProps>(
    ({
        open,
        onCancel,
        action,
        ids,
        metaName,
        onResponseCallback,
        setSelectedRows,
        onRefresh,
        filters,
        navigateAfterRun,
        row,
        meta,
        disableNotify = false
    }) => {
        const data = action?.Handler as IHandlerWithId;

        const [choosenFields, setChoosenFields] = useState<HandlerParam[]>([]);
        const { chooseFieldsModal, openModal } = useChooseFieldsModal({
            meta: metaName,
            setData: setChoosenFields,
            selectedIds: ids
        });

        useEffect(() => {
            setChoosenFields([]);
        }, [ids]);

        const isMassUpdate = data?.Code === 'MassUpdate';

        const params = useMemo(() => {
            if (choosenFields?.length > 0) return choosenFields;

            if (data) return data?.ChildParams;

            return [];
        }, [data, choosenFields]);

        const [confirmLoading, setConfirmLoading] = useState(false);

        const { notification } = useNotifications();
        const { run } = useHandlerRun();

        const {
            t,
            i18n: { language }
        } = useTranslation();

        const modalTitle = data && data?.Name ? data.Name[language] : t(data?.Code || 'handler');

        const [args, setArgs] = useState<ANY_DATA>({});

        // console.log(args);

        const metaInfo = useMemo(() => {
            if (meta) return toJS(metaStore.meta.get(meta)?.info);
            return undefined;
        }, [meta]);

        const isMounted = useMountedState();

        useEffectOnce(() => {
            const defaultArgsData: ANY_DATA = {};

            for (const param of params ?? []) {
                const paramCode = getParamCode(param);

                if (param.DefaultValue) {
                    // для реф объектов
                    if (param.ValueType.includes('ref:')) {
                        try {
                            defaultArgsData[paramCode] = JSON.parse(param.DefaultValue);
                        } catch (error) {
                            notification.error({
                                description: param.ParamName,
                                message: t('parse_default_value_fail')
                            });
                        }
                    } else {
                        // для остальных
                        defaultArgsData[paramCode] = JSONSafeParse(param.DefaultValue);
                    }
                    // if (param.DefaultValue.includes('false')) {
                    //     defaultArgsData[paramCode] = false;
                    // } else if (param.DefaultValue.includes('true')) {
                    //     defaultArgsData[paramCode] = true;
                    // } else defaultArgsData[paramCode] = param.DefaultValue.replaceAll('"', '');
                }
            }

            // if (
            //     (!isMounted() && isEmpty(args)) ||
            //     !isEqual(args, { ...args, ...defaultArgsData })
            // ) {
            setArgs({ ...args, ...defaultArgsData });
            // }
        });

        useEffect(() => {
            const defaultArgsData: ANY_DATA = {};

            for (let i = 0; i < params?.length; i++) {
                const param = params[i];
                const paramCode = getParamCode(param);

                const { type: fieldType, options } = parseValueType(param.ValueType, language, {
                    root: { ...row, ids },
                    current: { ...args, ids },
                    info: metaInfo,
                    self: args[paramCode]
                });

                if (options?.value) {
                    if (isNumericString(options?.value)) {
                        if (fieldType.includes('int'))
                            defaultArgsData[paramCode] = parseInt(options?.value, 10);
                        else defaultArgsData[paramCode] = parseFloat(options?.value);
                    } else if (options?.value === 'undefined') {
                        defaultArgsData[paramCode] = undefined;
                    } else {
                        defaultArgsData[paramCode] = options?.value;
                    }
                }
            }

            if (
                (!isMounted() && isEmpty(args)) ||
                !isEqual(args, { ...args, ...defaultArgsData })
            ) {
                setArgs({ ...args, ...defaultArgsData });
            }
        }, [args, ids, isMounted, language, metaInfo, params, row]);

        const handleRun = useCallback(async () => {
            const withEmptyArgs = { ...args };

            // только для массового редактирования
            if (choosenFields.length > 0) {
                choosenFields.forEach((field) => {
                    if (!withEmptyArgs[field.ParamName]) {
                        withEmptyArgs[field.ParamName] = null;
                    }
                });
            }

            setConfirmLoading(true);
            if (data) {
                const response = await run(
                    {
                        Action_Id: data.Id,
                        meta: metaName,
                        ids: ids.map((id) => id.toString()),
                        handler: data.Code,
                        args: withEmptyArgs
                    },
                    disableNotify
                );

                if (response?.run && onResponseCallback) {
                    onResponseCallback(response.run);
                }

                setConfirmLoading(false);

                // После выполнения экшна надо сделать обязательно рефреш данных!!
                onRefresh();

                if (navigateAfterRun && response && action && action.NavItem) {
                    navigateAfterRun(response, action, filters || []);
                }
                if (setSelectedRows) setSelectedRows([]);
            }

            setChoosenFields([]);
            onCancel();
        }, [
            args,
            choosenFields,
            data,
            run,
            metaName,
            ids,
            onResponseCallback,
            onRefresh,
            navigateAfterRun,
            action,
            filters,
            setSelectedRows,
            disableNotify,
            onCancel
        ]);

        const fields = useMemo(() => {
            const fields: DescriptionsProps['items'] = [];

            // console.log(params);

            const filteredSortedParams = params
                ?.filter((param, index, params) =>
                    getExcludeFieldsPattern(param, params, {
                        replaceFieldNameKey: 'ParamName',
                        isSnakeCaseValue: true,
                        diableCodeSuffix: true
                    })
                )
                .sort((a, b) => (a.ChildIndex ?? 0) - (b.ChildIndex ?? 0));

            // console.log(filteredSortedParams);

            const paramRender = (param: HandlerParam) => {
                // TODO: вынести в отдельную рендер функцию
                const paramCode = getParamCode(param);

                const { type: fieldType, options } = parseValueType(param.ValueType, language, {
                    root: { ...row, ids },
                    current: { ...args, ids },
                    info: metaInfo,
                    self: args[paramCode]
                });

                // console.log(param, options);

                const label = param.Name ? param.Name[language] : paramCode;

                if (fieldType.substring(0, 2) === '[]') {
                    let metaParam = metaName;

                    if (options && options.ref) {
                        metaParam = options.ref.meta;
                    }

                    fields.push({
                        key: paramCode,
                        label,
                        children: (
                            <SmartMultiSelectField
                                value={args[paramCode]}
                                metaName={metaParam}
                                onChange={(value) =>
                                    setArgs((prev) => ({
                                        ...prev,
                                        [paramCode]: value
                                    }))
                                }
                                filters={options?.filters}
                                treeOptions={{
                                    parentFieldName: options?.group
                                }}
                            />
                        )
                    });

                    // continue;
                    return;
                }

                switch (fieldType.replaceAll('*', '')) {
                    case 'jsonb': {
                        const data =
                            !args[paramCode] && typeof args[paramCode] === 'object'
                                ? JSON.stringify(args[paramCode])
                                : args[paramCode];

                        if (options?.json_type) {
                            paramRender({
                                ...param,
                                ValueType: `${options.json_type};ref:${options.ref?.meta || ''}.${
                                    options.ref?.fieldName || ''
                                }${options.filters ? `;filters:${options.filters}` : ''}${
                                    options.group ? `;group:${options.group}` : ''
                                }${options.display ? `;display:${options.display}` : ''}`
                            });

                            return;
                        }

                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <JsonField
                                    jsonValue={typeof data === 'string' ? JSON.parse(data) : data}
                                    readOnly={false}
                                    onChange={(value) =>
                                        setArgs((prev) => ({
                                            ...prev,
                                            [paramCode]: value
                                        }))
                                    }
                                    displayMode={options?.display}
                                    scriptLanguage="json"
                                />
                            )
                        });

                        break;
                    }
                    case 'password': {
                        const confirmPassParamName = 'confirm_password';

                        // if (paramCode === confirmPassParamName) continue;
                        if (paramCode === confirmPassParamName) return;

                        const confirmPassParam = params.find(
                            ({ ParamName }) => ParamName === confirmPassParamName
                        );

                        fields.push({
                            key: paramCode,
                            label: undefined,
                            children: (
                                <PasswordField
                                    confirmValue={
                                        confirmPassParam ? args[confirmPassParamName] : undefined
                                    }
                                    onConfirmChange={
                                        confirmPassParam
                                            ? (value) =>
                                                  setArgs((prev) => ({
                                                      ...prev,
                                                      [confirmPassParamName]: value
                                                  }))
                                            : undefined
                                    }
                                    value={args[paramCode]}
                                    onChange={(value) =>
                                        setArgs((prev) => ({
                                            ...prev,
                                            [paramCode]: value
                                        }))
                                    }
                                    labels={{
                                        newPassword: label,
                                        confirmPassword: confirmPassParam
                                            ? confirmPassParam?.Name?.[language] ||
                                              getParamCode(confirmPassParam)
                                            : undefined
                                    }}
                                />
                            )
                        });

                        break;
                    }
                    case 'script': {
                        const data = String(args[paramCode]);

                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <JsonField
                                    jsonValue={data}
                                    displayMode={options?.display}
                                    onChange={(v) => {
                                        setArgs((prev) => ({
                                            ...prev,
                                            [paramCode]: v
                                        }));
                                    }}
                                    scriptLanguage={options?.language || 'text'}
                                    readOnly={false}
                                />
                            )
                        });

                        break;
                    }
                    case 'multilang_text': {
                        const fieldName =
                            param.Name[language || 'en'] ||
                            t((param.ParamName || '')?.toLowerCase());

                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <SmartMultilanguageField
                                    fieldName={fieldName}
                                    value={args[paramCode]}
                                    onChange={(value) =>
                                        setArgs((prev) => ({
                                            ...prev,
                                            [paramCode]: value
                                        }))
                                    }
                                />
                            )
                        });

                        break;
                    }
                    case 'file': {
                        const isAttachments = options?.bucket === 'attachments';

                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <FilePickerField
                                    // root_id={ids.join('_')}
                                    // meta={meta || ''}
                                    path={
                                        isAttachments
                                            ? `${meta}/${ids.join('_')}`
                                            : options?.path || ''
                                    }
                                    value={{
                                        file_path: args[paramCode],
                                        file_name: args.file_name,
                                        file_size: args.file_size
                                    }}
                                    onChange={(value) => {
                                        setArgs((prevArgs) => ({
                                            ...prevArgs,
                                            file_path: value.file_path,
                                            file_size: value.file_size
                                        }));
                                    }}
                                    onChangeExternalFileName={(value) => {
                                        setArgs((prevArgs) => ({
                                            ...prevArgs,
                                            file_name: value
                                        }));
                                    }}
                                    bucket={options?.bucket}
                                />
                            )
                        });

                        break;
                    }
                    case 'date': {
                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <SmartDateField
                                    style={{ width: DATE_FIELD_WIDTH }}
                                    value={args[paramCode]}
                                    onChange={(value) =>
                                        setArgs((prev) => ({
                                            ...prev,
                                            [paramCode]: value
                                        }))
                                    }
                                    utc={false}
                                />
                            )
                        });
                        break;
                    }
                    case 'datetime': {
                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <SmartDateField
                                    style={{ width: DATE_FIELD_WIDTH + 20 }}
                                    value={args[paramCode]}
                                    onChange={(value) =>
                                        setArgs((prev) => ({
                                            ...prev,
                                            [paramCode]: value
                                        }))
                                    }
                                    utc={false}
                                    showTime
                                />
                            )
                        });
                        break;
                    }
                    case 'text': {
                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <Input
                                    placeholder={t('no_value') as string}
                                    value={args[paramCode]}
                                    onChange={(e) => {
                                        setArgs((prev) => ({
                                            ...prev,
                                            [paramCode]: e.target.value
                                        }));
                                    }}
                                />
                            )
                        });
                        break;
                    }
                    case 'datetimerange': {
                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <SmartDatetimeRangeField
                                    style={{ maxWidth: DATE_RANGE_FIELD_WIDTH }}
                                    value={args[paramCode]}
                                    onChange={(dynamicDateRange) => {
                                        setArgs((prev) => ({
                                            ...prev,
                                            [paramCode]: dynamicDateRange
                                        }));
                                    }}
                                />
                            )
                        });
                        break;
                    }
                    case 'daterange': {
                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <SmartDateRangeField
                                    style={{ maxWidth: DATE_RANGE_FIELD_WIDTH }}
                                    value={args[paramCode]}
                                    onChange={(dynamicDateRange) => {
                                        setArgs((prev) => ({
                                            ...prev,
                                            [paramCode]: dynamicDateRange
                                        }));
                                    }}
                                />
                            )
                        });
                        break;
                    }
                    case 'string': {
                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <Input
                                    placeholder={t('no_value') as string}
                                    value={args[paramCode]}
                                    onChange={(e) => {
                                        setArgs((prev) => ({
                                            ...prev,
                                            [paramCode]: e.target.value
                                        }));
                                    }}
                                />
                            )
                        });
                        break;
                    }
                    case 'decimal': {
                        let selectAfter;
                        if (Array.isArray(params)) {
                            const measureUnitParam = params.find((param) => {
                                const pascalParamName = toPascalCase(getParamCode(param)).replace(
                                    'Code',
                                    ''
                                );

                                const pascalParamCode = toPascalCase(paramCode).replace('Code', '');

                                // composite measure value
                                return (
                                    pascalParamName ===
                                        `${pascalParamCode.split('Value').join('')}MeasureUnit` ||
                                    // composite curreny value
                                    pascalParamName ===
                                        `${pascalParamCode
                                            .split('CurrencyValue')
                                            .join('')}Currency` ||
                                    pascalParamName ===
                                        `${pascalParamCode.split('Amount').join('')}Currency` ||
                                    pascalParamName ===
                                        `${pascalParamCode.split('Value').join('')}Currency`
                                );
                            });

                            if (measureUnitParam) {
                                const measureUnitParamCode = getParamCode(measureUnitParam);

                                const measureUnitValue = args?.[measureUnitParamCode];
                                const { options } = parseValueType(
                                    measureUnitParam.ValueType || '',
                                    language,
                                    {
                                        root: { ...row, ids },
                                        current: { ...args, ids },
                                        self: measureUnitValue,
                                        info: metaInfo
                                    }
                                );

                                if (options && options.ref) {
                                    const objectPath = options.filters
                                        ?.split('{{')[1]
                                        ?.replace('Root.', '')
                                        ?.replace('Context.', '')
                                        ?.replace('Info.', '')
                                        ?.replace('}}', '');

                                    let nestedValue;

                                    if (objectPath) {
                                        nestedValue = getNestedValueFromObject(
                                            { ...args, ids },
                                            objectPath,
                                            language
                                        );
                                    }

                                    // if (
                                    //     args &&
                                    //     objectPath &&
                                    //     cachedPrevMeasureUnitNestedValue?.[dataSource.Id] !==
                                    //         nestedValue
                                    // ) {
                                    //     cachedPrevMeasureUnitNestedValue[dataSource.Id] =
                                    //         nestedValue;
                                    //     onChange(measureUnitField?.FieldName, undefined);
                                    // }
                                    // const filters =
                                    //     // options.filters?.includes('{{') && options.filters?.includes('Root')
                                    //     objectPath
                                    //         ? `${options.filters?.split('{{')[0]}${nestedValue}`
                                    //         : options.filters;

                                    selectAfter = (
                                        <SmartSelectField
                                            className="measure_value__select_addon"
                                            value={measureUnitValue}
                                            meta={options?.ref?.meta}
                                            onChange={(newValue) => {
                                                setArgs((prevArgs) => ({
                                                    ...prevArgs,
                                                    [measureUnitParamCode]: newValue
                                                }));
                                            }}
                                            style={{ width: 75 }}
                                            // filters={filters}
                                            filters={options.filters}
                                            simpleMode
                                            treeOptions={{ parentFieldName: options?.group }}
                                        />
                                    );
                                }
                            }
                        }

                        // TODO: вынести в компонент
                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <InputNumber
                                    placeholder={t('no_value') as string}
                                    style={{ width: '100%', maxWidth: 170 }}
                                    defaultValue={options ? options.default === 'true' : undefined}
                                    value={args[paramCode]}
                                    step="0.1"
                                    onChange={(newValue) =>
                                        setArgs((prevArgs) => ({
                                            ...prevArgs,
                                            [paramCode]: newValue
                                        }))
                                    }
                                    addonAfter={selectAfter}
                                />
                            )
                        });
                        break;
                    }
                    case 'int': {
                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <InputNumber
                                    placeholder={t('no_value') as string}
                                    style={{ width: '100%', maxWidth: NUMBER_FIELD_WIDTH }}
                                    defaultValue={options ? Number(options.default) : undefined}
                                    value={args[paramCode]}
                                    onChange={(value) =>
                                        setArgs((prev) => ({
                                            ...prev,
                                            [paramCode]: value
                                        }))
                                    }
                                    readOnly={isDefined(options?.value)} // если определен параметр типа "value:", то поле readOnly
                                />
                            )
                        });
                        break;
                    }
                    case 'number': {
                        const hasOptionsValue = isNumericString(options?.value);

                        if (
                            hasOptionsValue &&
                            args[paramCode] !== parseFloat(options?.value as string)
                        ) {
                            setArgs((prev) => ({
                                ...prev,
                                [paramCode]: parseFloat(options?.value as string)
                            }));
                        }

                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <InputNumber
                                    placeholder={t('no_value') as string}
                                    style={{ width: '100%', maxWidth: NUMBER_FIELD_WIDTH }}
                                    defaultValue={options ? Number(options.default) : undefined}
                                    value={args[paramCode]}
                                    step="0.05"
                                    onChange={(value) =>
                                        setArgs((prev) => ({
                                            ...prev,
                                            [paramCode]: value
                                        }))
                                    }
                                    readOnly={hasOptionsValue} // если определен параметр типа "value:", то поле readOnly
                                />
                            )
                        });
                        break;
                    }
                    case 'bool': {
                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <Checkbox
                                    defaultChecked={!!(options && options.default === 'true')}
                                    checked={args[paramCode]}
                                    onChange={(e) =>
                                        setArgs((prev) => ({
                                            ...prev,
                                            [paramCode]: e.target.checked
                                        }))
                                    }
                                ></Checkbox>
                            )
                        });
                        break;
                    }
                    case 'boolean': {
                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <Checkbox
                                    defaultChecked={!!(options && options.default === 'true')}
                                    checked={args[paramCode]}
                                    onChange={(e) =>
                                        setArgs((prev) => ({
                                            ...prev,
                                            [paramCode]: e.target.checked
                                        }))
                                    }
                                ></Checkbox>
                            )
                        });
                        break;
                    }
                    case 'duration': {
                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <SmartDurationField
                                    value={args[paramCode]}
                                    onChange={(value) =>
                                        setArgs((prev) => ({
                                            ...prev,
                                            [paramCode]: value
                                        }))
                                    }
                                />
                            )
                        });
                        break;
                    }
                    case 'key':
                    case 'id':
                    case 'object_id':
                    case 'code': {
                        if (options && options.ref) {
                            const { meta } = options.ref;

                            fields.push({
                                key: paramCode,
                                label,
                                children: (
                                    <SmartSelectField
                                        value={args[paramCode]}
                                        meta={meta}
                                        filters={options.filters}
                                        onChange={(value) =>
                                            setArgs((prev) => ({
                                                ...prev,
                                                [paramCode]: value
                                            }))
                                        }
                                        treeOptions={{ parentFieldName: options?.group }}
                                        navigateMetaOptions={{ metaName: options.ref.meta }}
                                    />
                                )
                            });
                        } else {
                            fields.push({
                                key: paramCode,
                                label,
                                children: (
                                    <Input
                                        value={args[paramCode]}
                                        onChange={(value) =>
                                            setArgs((prev) => ({
                                                ...prev,
                                                [paramCode]: value
                                            }))
                                        }
                                    />
                                )
                            });
                        }
                        break;
                    }
                    default: {
                        fields.push({
                            key: paramCode,
                            label,
                            children: (
                                <Input
                                    value={args[paramCode]}
                                    onChange={(value) =>
                                        setArgs((prev) => ({
                                            ...prev,
                                            [paramCode]: value
                                        }))
                                    }
                                />
                            )
                        });

                        break;
                    }
                }
            };

            for (const param of filteredSortedParams ?? []) {
                if (param.IsRequested) {
                    paramRender(param);
                }
            }

            return fields;
        }, [args, ids, language, meta, metaInfo, metaName, params, row, t]);

        const fieldsWithLabel = useMemo(() => fields.filter((f) => !!f.label), [fields]);
        const fieldsWithoutLabel = useMemo(() => fields.filter((f) => !f.label), [fields]);

        return (
            <>
                <Modal
                    open={open}
                    onCancel={() => {
                        onCancel();
                        setChoosenFields([]);
                    }}
                    onOk={handleRun}
                    destroyOnClose
                    okText={navigateAfterRun && action?.NavItem ? t('follow') : t('run')}
                    confirmLoading={confirmLoading}
                    cancelText={t('cancel')}
                    title={modalTitle}
                    width="75%"
                    zIndex={2000}
                    centered
                >
                    {isMassUpdate && (
                        <ButtonWithTooltips
                            id="all_fields"
                            type="default"
                            className=""
                            icon={<PlusOutlined />}
                            style={{ marginRight: 'auto', marginBottom: 10 }}
                            onClick={openModal}
                        >
                            {t('all_fields')}
                        </ButtonWithTooltips>
                    )}
                    <>
                        {fieldsWithLabel?.length ? (
                            <FieldsBox
                                bordered
                                items={fields.filter((f) => !!f.label)}
                                size="small"
                                column={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 2 }}
                                labelStyle={{
                                    display: 'table-cell',
                                    backgroundColor: undefined,
                                    padding: undefined
                                }}
                                contentStyle={{ width: '70%', padding: '8px 16px' }}
                                helpIconPlacement="end"
                            />
                        ) : null}
                        {fieldsWithoutLabel.map((f) => f.children)}
                    </>
                </Modal>
                {chooseFieldsModal}
            </>
        );
    }
);
