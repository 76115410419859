import { memo, useEffect, useRef, useState } from 'react';
import AceEditor from 'react-ace';

import * as beautiful from 'ace-builds/src-noconflict/ext-beautify';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/mode-markdown';
import 'ace-builds/src-noconflict/mode-text';
import 'ace-builds/src-noconflict/mode-jsx';
import 'ace-builds/src-noconflict/mode-tsx';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-dockerfile';
import 'ace-builds/src-noconflict/mode-xml';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-typescript';
import 'ace-builds/src-noconflict/mode-golang';
import 'ace-builds/src-noconflict/mode-gitignore';
import 'ace-builds/src-noconflict/mode-abap';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/mode-pgsql';
import 'ace-builds/src-noconflict/snippets/json';
import 'ace-builds/src-noconflict/snippets/markdown';
import 'ace-builds/src-noconflict/snippets/sql';
import 'ace-builds/src-noconflict/snippets/pgsql';
import 'ace-builds/src-noconflict/snippets/html';
import 'ace-builds/src-noconflict/snippets/xml';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';
import { Button, Modal, Typography } from 'antd';
import { useBoolean, useUpdateEffect } from 'react-use';

import './JsonField.scss';
import { EditOutlined } from '@ant-design/icons';
import { JSONSafeParse } from 'smart/utils';
import { isDefined } from 'is-lite/exports';

interface JsonFieldType {
    jsonValue: any | undefined;
    onChange: (newFieldValue: any) => void;
    readOnly: boolean;
    scriptLanguage?: string;
    displayMode?: 'inline' | 'dialog';
}

const unbeautify = (value: string, scriptLanguage: string) => {
    if (scriptLanguage === 'json') {
        return JSON.stringify(JSONSafeParse(value));
    }

    return value;
};

export const JsonField = memo<JsonFieldType>(
    ({ jsonValue, readOnly, onChange, scriptLanguage = 'text', displayMode = 'modal' }) => {
        const mode = scriptLanguage.toLowerCase();

        const editorRef = useRef<AceEditor | null>(null);

        const [openModal, setOpenModal] = useBoolean(false);

        const stringifiedValue = jsonValue
            ? mode === 'json'
                ? JSON.stringify(jsonValue)
                : jsonValue
            : undefined;

        const [value, setValue] = useState(stringifiedValue);

        const [modalValue, setModalValue] = useState(stringifiedValue);

        useUpdateEffect(() => {
            if (isDefined(value) && mode === 'json') onChange(JSONSafeParse(value as string));
            else onChange(value);
        }, [value]);

        const beautify = () => {
            if (mode === 'json' && editorRef.current?.editor.getSession())
                beautiful.beautify(editorRef.current.editor.getSession());
        };

        useEffect(() => {
            setTimeout(() => {
                if (mode === 'json' && editorRef.current?.editor.getSession() && openModal)
                    beautify();
            }, 1);
        }, [openModal, mode]);

        if (displayMode === 'inline') {
            return (
                <AceEditor
                    ref={editorRef}
                    mode={mode}
                    value={value}
                    name="json-editor"
                    editorProps={{ $blockScrolling: true }}
                    setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: true
                    }}
                    onBlur={beautify}
                    fontSize="1em"
                    width="100%"
                    readOnly={readOnly}
                    // Enable live editing for syntax highlighting and error detection
                    onChange={setValue}
                />
            );
        }

        return (
            <>
                <Typography.Link
                    ellipsis
                    className="json_field"
                    style={{
                        color: 'rgba(0,0,0,.88)',
                        padding: 0,
                        marginBottom: 0
                    }}
                    onClick={() => setOpenModal(true)}
                >
                    {stringifiedValue || (
                        <Button
                            disabled={readOnly}
                            icon={<EditOutlined />}
                            onClick={() => setOpenModal(true)}
                        >
                            {mode.toUpperCase()}
                        </Button>
                    )}
                </Typography.Link>
                <Modal
                    open={openModal}
                    onOk={() => {
                        beautify();
                        setValue(modalValue ? unbeautify(modalValue, mode) : stringifiedValue);
                        setOpenModal(false);
                    }}
                    onCancel={() => {
                        setOpenModal(false);
                        setModalValue(undefined);
                    }}
                    centered
                    width="75vw"
                    height="75vh"
                    title={<> </>}
                    style={{ padding: 20 }}
                    destroyOnClose
                >
                    <AceEditor
                        ref={editorRef}
                        mode={mode}
                        value={isDefined(modalValue) ? modalValue : stringifiedValue}
                        onBlur={beautify}
                        name="json-editor"
                        editorProps={{ $blockScrolling: true }}
                        setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: true,
                            enableSnippets: true
                        }}
                        fontSize="1em"
                        width="100%"
                        readOnly={readOnly}
                        // Enable live editing for syntax highlighting and error detection
                        onChange={setModalValue}
                    />
                </Modal>
            </>
        );
    }
);
