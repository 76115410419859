import { forwardRef, useEffect, useState } from 'react';
import Spreadsheet from 'react-spreadsheet';
import * as XLSX from 'xlsx';
import { isArray } from 'is-lite/exports';
import { Flex, Typography } from 'antd';

import './ExcelField.scss';

interface ExcelCellType {
    value: string | number | null | undefined;
}

interface ExcelFileProps {
    file: ArrayBuffer | Array<ExcelCellType[]>;
    uiTableTitle?: string;
    className?: string;
    hideIndicators?: boolean;
}

export const ExcelField = forwardRef<HTMLDivElement, ExcelFileProps>(
    ({ file, uiTableTitle, className, hideIndicators }, ref) => {
        const [data, setData] = useState<ExcelCellType[][]>([]);
        const type = isArray(file) ? 'array' : 'buffer';

        useEffect(() => {
            if (type === 'array') {
                // console.log('sheet data', file);

                setData(file as Array<ExcelCellType[]>);
            } else {
                const workbook = XLSX.read(file, { type });
                const worksheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[worksheetName];
                const data = XLSX.utils.sheet_to_json(worksheet);

                const maxFields = Math.max(...data.map((obj) => Object.keys(obj).length));

                const transformedData = data.map((item) => {
                    const entries = Object.entries(item);
                    const transformedEntries = entries.map(
                        ([key, value]) => ({ value }) as ExcelCellType
                    );

                    // Заполняем недостающие позиции объектами с пустым значением
                    while (transformedEntries.length < maxFields) {
                        transformedEntries.push({ value: '' });
                    }

                    return transformedEntries;
                });

                // console.log('sheet data', transformedData);

                setData(transformedData);
            }
        }, [file, type]);

        return (
            <div ref={ref} className={`excel_field ${className}`}>
                <Flex vertical justify={'center'}>
                    {uiTableTitle && (
                        <Typography.Text
                            strong
                            style={{
                                padding: 5,
                                marginTop: 1,
                                display: 'block',
                                width: '100%',
                                backgroundColor: 'lightgray'
                            }}
                        >
                            {uiTableTitle}
                        </Typography.Text>
                    )}
                    <Spreadsheet
                        hideColumnIndicators={hideIndicators}
                        hideRowIndicators={hideIndicators}
                        className={`excel_field__inner`}
                        data={data}
                    />
                </Flex>
            </div>
        );
    }
);
