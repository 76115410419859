import { DevSupport } from '@react-buddy/ide-toolbox';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import posthog from 'posthog-js';

import { createRoot } from 'react-dom/client';
import { App } from 'App';
import { SupabaseProvider } from './modules/supabase/contexts/SupabaseContext/SupabaseContext';

import { reportWebVitals } from './reportWebVitals';
import './index.css';
import './utils/i18n/i18n';
import { ComponentPreviews, useInitial } from './dev';

Sentry.init({
    dsn: 'https://0f01a5c773fd566a04de7fd762cc8cba@o1342839.ingest.us.sentry.io/4508028065808384',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/itvectura\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

posthog.init('phc_m0gzzqpsg0Wr5KLqkkR8i6l88Ko0ODGumI2UVP6bNyD', {
    api_host: 'https://eu.i.posthog.com',
    person_profiles: 'always' // or 'always' to create profiles for anonymous users as well
});

const rootElement = document.getElementById('root');

// чтобы у всех запросов тансктака были общие настройки
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            retry: false,
            staleTime: 0,
            gcTime: 0
        }
    }
});

const ITVecturaApp: React.FC = () => (
    <SupabaseProvider>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <DevSupport ComponentPreviews={ComponentPreviews} useInitialHook={useInitial}>
                    <App />
                </DevSupport>
            </BrowserRouter>
        </QueryClientProvider>
    </SupabaseProvider>
);

if (rootElement) {
    const container = document.getElementById('root');
    const root = createRoot(container!); // createRoot(container!) if you use TypeScript
    root.render(<ITVecturaApp />);
} else {
    // eslint-disable-next-line no-console
    console.error('Root element not found!');
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export default ITVecturaApp;
