import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Card, Flex, List, Modal } from 'antd';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MetaField } from 'modules/services/backend-api/generated_info';

import { FilterField } from '../../types';
import { getDefaultFilterOperator, getInitialActiveFilters } from '../../utils';

interface AllFiltersModalProps {
    open: boolean;
    meta: string;
    setOpen: (open: boolean) => void;
    setActiveFilters: (filters: FilterField[]) => void;
    activeFilters: FilterField[];
    metaFields: MetaField[];
}

export const AllFiltersModal = memo<AllFiltersModalProps>(
    ({ open, activeFilters, setActiveFilters, setOpen, meta, metaFields }) => {
        const {
            t,
            i18n: { language }
        } = useTranslation();

        const [activeData, setActiveData] = useState<FilterField[]>([]);

        useEffect(() => {
            setActiveData(activeFilters);
        }, [activeFilters]);

        useEffect(() => {
            const initialActiveFilters = getInitialActiveFilters(metaFields);

            setActiveData((prev) => {
                if (prev.length > 0) {
                    return [...prev];
                }

                return initialActiveFilters;
            });
            setActiveFilters((prev) => {
                if (prev.length > 0) {
                    return [...prev];
                }

                return initialActiveFilters;
            });
        }, [metaFields, setActiveFilters]);

        const allFilters = useMemo(() => {
            return metaFields.filter(
                (field) => !activeData.find((active) => field.FieldName === active.field.FieldName)
            );
        }, [metaFields, activeData]);

        const addAppliedField = useCallback((field: FilterField) => {
            setActiveData((prevValue) => {
                return [...prevValue, field];
            });
        }, []);

        const removeAppliedField = useCallback((columnName: string) => {
            setActiveData((prevValue) => {
                const copy = [...prevValue];
                const fieldIndex = copy.findIndex((field) => field.field.ColumnName === columnName);

                if (fieldIndex >= 0) {
                    copy.splice(fieldIndex, 1);
                }

                return copy;
            });
        }, []);

        const handleCancel = () => {
            setActiveData(activeFilters);
            setOpen(false);
        };

        const handleOk = () => {
            setActiveFilters(activeData);
            setOpen(false);
        };

        return (
            <Modal
                open={open}
                onCancel={handleCancel}
                onOk={handleOk}
                width="70%"
                destroyOnClose
                title={t('choose_fields')}
            >
                <Flex gap={5}>
                    <Card title={`${t('all_fields')}:`} style={{ width: '50%' }}>
                        <List
                            className="all_filters"
                            dataSource={allFilters || []}
                            itemLayout="horizontal"
                            style={{
                                height: 'calc(100vh - 24rem)',
                                paddingRight: 5,
                                overflow: 'auto'
                            }}
                            renderItem={(item) => (
                                <List.Item
                                    onClick={() => {
                                        const filterField: FilterField = {
                                            field: item,
                                            values: []
                                        };

                                        filterField.values.push({
                                            operator: getDefaultFilterOperator(filterField),
                                            column: filterField.field.ColumnName
                                        });

                                        addAppliedField(filterField);
                                    }}
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                >
                                    <Flex gap={15}>
                                        <PlusOutlined
                                            style={{
                                                cursor: 'pointer',
                                                color: '#3287d3'
                                            }}
                                        />
                                        {item.Name[language] || t(item.ColumnName)}
                                    </Flex>
                                </List.Item>
                            )}
                        ></List>
                    </Card>
                    <Flex
                        style={{ fontSize: 20, color: 'rgba(0, 0, 0, 0.25)' }}
                        align="center"
                        justify="center"
                        vertical
                    >
                        <span>{'>'}</span>
                        <span>{'<'}</span>
                    </Flex>
                    <Card title={`${t('active_fields')}:`} style={{ width: '50%' }}>
                        <List
                            className="active_filters"
                            dataSource={activeData || []}
                            itemLayout="horizontal"
                            style={{
                                height: 'calc(100vh - 24rem)',
                                paddingRight: 5,
                                overflow: 'auto'
                            }}
                            renderItem={(item) => (
                                <List.Item
                                    onClick={() => {
                                        removeAppliedField(item.field.ColumnName);
                                    }}
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                >
                                    <Flex gap={15}>
                                        {!item.field.FilterIndex ? (
                                            <MinusOutlined
                                                style={{
                                                    cursor: 'pointer',
                                                    color: '#fc5e53'
                                                }}
                                            />
                                        ) : undefined}
                                        {item.field.Name[language] || t(item.field.ColumnName)}
                                    </Flex>
                                </List.Item>
                            )}
                        ></List>
                    </Card>
                </Flex>
            </Modal>
        );
    }
);
