import { memo, useState } from 'react';
import { InputNumber, InputNumberProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';

interface NumberFieldProps extends Omit<InputNumberProps, 'onChange'> {
    onChange?: (value: string | number | null | undefined) => void;
}

export const NumberField = memo<NumberFieldProps>(({ value, onChange, ...props }) => {
    const { t } = useTranslation();

    const [innerValue, setInnerValue] = useState(value);

    useDebounce(
        () => {
            if (onChange) onChange(innerValue);
        },
        400,
        [innerValue]
    );

    return (
        <InputNumber
            {...props}
            placeholder={t('no_value') as string}
            value={value}
            onChange={setInnerValue}
        />
    );
});
