import React, { useCallback } from 'react';

import { Checkbox, Col, Flex, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { WeekDay } from '../../ui';

import './PatternWeek.scss';

interface PatternWeekPropsType {
    value: boolean[];
    onChange: Function;
    mode: 'edit' | 'view';
}

export const PatternWeek: React.FC<PatternWeekPropsType> = ({ value, onChange, mode }) => {
    const { t } = useTranslation();

    const getInitialWeek = useCallback(() => {
        const week = [];

        for (let i = 0; i <= 6; i++) {
            week.push(false);
        }

        return week;
    }, []);

    const getData = useCallback(() => {
        return !value ? getInitialWeek() : value;
    }, [value, getInitialWeek]);

    const setDaysByIndex = useCallback(
        (indexArray: number[], value: boolean) => {
            const DaysPattern = getData();

            indexArray.forEach((index) => {
                DaysPattern[index] = value;
            });

            onChange(DaysPattern);
        },
        [getData, onChange]
    );

    const daySelectHandle = useCallback(
        (index: number) => {
            const DaysPattern = getData();
            DaysPattern[index] = !DaysPattern[index];

            onChange(DaysPattern);
        },
        [getData, onChange]
    );

    // console.log('DATS PATTERN:', item.days_pattern);

    return (
        <div>
            <Row className="week justify-content-center" gutter={5}>
                <Col sm="auto">
                    <WeekDay
                        mode={mode}
                        weekDay={t('scheduleItemDayPatternMonday')}
                        selected={value ? value[0] : false}
                        onDaySelect={() => {
                            daySelectHandle(0);
                        }}
                    />
                </Col>
                <Col sm="auto">
                    <WeekDay
                        mode={mode}
                        weekDay={t('scheduleItemDayPatternTuesday')}
                        selected={value ? value[1] : false}
                        onDaySelect={() => {
                            daySelectHandle(1);
                        }}
                    />
                </Col>
                <Col sm="auto">
                    <WeekDay
                        mode={mode}
                        weekDay={t('scheduleItemDayPatternWednesday')}
                        selected={value ? value[2] : false}
                        onDaySelect={() => {
                            daySelectHandle(2);
                        }}
                    />
                </Col>
                <Col sm="auto">
                    <WeekDay
                        mode={mode}
                        weekDay={t('scheduleItemDayPatternThursday')}
                        selected={value ? value[3] : false}
                        onDaySelect={() => {
                            daySelectHandle(3);
                        }}
                    />
                </Col>
                <Col sm="auto">
                    <WeekDay
                        mode={mode}
                        weekDay={t('scheduleItemDayPatternFriday')}
                        selected={value ? value[4] : false}
                        onDaySelect={() => {
                            daySelectHandle(4);
                        }}
                    />
                </Col>
                <Col sm="auto">
                    <WeekDay
                        mode={mode}
                        weekDay={t('scheduleItemDayPatternSaturday')}
                        selected={value ? value[5] : false}
                        onDaySelect={() => {
                            daySelectHandle(5);
                        }}
                    />
                </Col>
                <Col sm="auto">
                    <WeekDay
                        mode={mode}
                        weekDay={t('scheduleItemDayPatternSunday')}
                        selected={value ? value[6] : false}
                        onDaySelect={() => {
                            daySelectHandle(6);
                        }}
                    />
                </Col>
            </Row>

            <Flex justify="space-between" style={{ width: 250, paddingLeft: '8px' }}>
                <Checkbox
                    style={{ width: '50%' }}
                    disabled={mode !== 'edit'}
                    onChange={(e) => {
                        setDaysByIndex([1, 3, 5], e.target.checked);
                    }}
                >
                    {t('scheduleItemDayPatternEven')}
                </Checkbox>

                <Checkbox
                    style={{ width: '50%' }}
                    disabled={mode !== 'edit'}
                    onChange={(e) => {
                        setDaysByIndex([0, 2, 4, 6], e.target.checked);
                    }}
                >
                    {t('scheduleItemDayPatternOdd')}
                </Checkbox>
            </Flex>

            <Flex justify="space-between" style={{ width: 250, paddingLeft: '8px' }}>
                <Checkbox
                    style={{ width: '50%' }}
                    disabled={mode !== 'edit'}
                    onChange={(e) => {
                        setDaysByIndex([0, 1, 2, 3, 4], e.target.checked);
                    }}
                >
                    {t('scheduleItemDayPatternWorkDay')}
                </Checkbox>

                <Checkbox
                    style={{ width: '50%' }}
                    disabled={mode !== 'edit'}
                    onChange={(e) => {
                        setDaysByIndex([5, 6], e.target.checked);
                    }}
                >
                    {t('scheduleItemDayPatternHolidays')}
                </Checkbox>
            </Flex>
        </div>
    );
};
