import { Flex, Progress, Typography } from 'antd';
import { memo } from 'react';
// import { useTranslation } from 'react-i18next';
import { isNumber } from 'is-lite/exports';

import { getPercentFormat, getProgressColor } from './utils';

interface ProgressBarFieldProps {
    value: number;
}

export const ProgressBarField = memo<ProgressBarFieldProps>(({ value }) => {
    // const { t } = useTranslation();

    // console.log(value);

    return (
        <Progress
            style={{ width: '100%' }} // Примерное соотношение ширины текста к прогрессбару
            format={() => getPercentFormat(isNumber(value) ? value : 0)}
            percent={value}
            strokeColor={getProgressColor(value)}
            trailColor="#D9D9D9"
            // size="small"
        />
    );
});
