export function removeUndefinedFields<T extends object>(obj: T): T {
    // Создаем копию объекта, чтобы не изменять исходный объект
    const newObj = { ...obj };

    // Проходим по всем ключам объекта
    for (const key in newObj) {
        // Проверяем, является ли значение undefined
        if (newObj[key] === undefined) {
            // Удаляем поле, если его значение undefined
            delete newObj[key];
        }
    }

    return newObj;
}
