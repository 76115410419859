import { PlainObject } from '@gilbarbara/types';
import dayjs from 'dayjs';
import { v4 } from 'uuid';

export const transformSlots = (
    intervals: PlainObject[],
    propertyNames: { StartDateAt: string; EndDateAt: string } = {
        StartDateAt: 'StartDateAt',
        EndDateAt: 'EndDateAt'
    }
) => {
    const slots: PlainObject[] = [];

    intervals.forEach((interval) => {
        const start = dayjs(
            (interval.StartAt as string) ?? (interval[propertyNames.StartDateAt] as string)
        );
        const startPercent = ((start.get('minutes') || 60) / 60) * 100;
        const end = dayjs(
            (interval.EndAt as string) ?? (interval[propertyNames.EndDateAt] as string)
        );
        const endPercent = ((end.get('minutes') || 60) / 60) * 100;

        const startHour = start.get('hours');
        const endHour = end.get('hours') - 1;

        for (let hour = start.get('hours'); hour <= endHour; hour++) {
            const isStartHour = hour === startHour;
            const isEndHour = hour === endHour;

            slots.push({
                ...interval,
                Id: v4(),
                SlotIndex: hour,
                SlotDate: interval.SlotDate ?? interval.Date,
                StartDateAt: start.set('hours', hour).format('YYYY-MM-DDTHH:mm:ssZ'),
                EndDateAt: start.set('hours', hour + 1).format('YYYY-MM-DDTHH:mm:ssZ'),
                SlotPercent: isStartHour ? startPercent : isEndHour ? endPercent : 100,
                IsSlotStart: isStartHour,
                IsSlotEnd: isEndHour
            });
        }
    });

    return slots;
};
