import { User } from '@supabase/supabase-js';
import { useEffect, useState } from 'react';
import { useBoolean, useEffectOnce } from 'react-use';
import { IHookStateSetAction } from 'react-use/lib/misc/hookState';

import { InfoUser } from 'modules/services/backend-api/generated_models';
import { supabaseClient } from '../../contexts/SupabaseContext/SupabaseContext';
import { metaStore } from '../../../../utils/store/MetaStore';
import { Meta } from '../../../services/backend-api/generated_info';

type UseSupabaseUser = {
    user: User | null;
    loading: boolean;
    isPowerUser: boolean;
    setUser: (state: IHookStateSetAction<User | null>) => void;
};

export const isRoleExists = async (userUuid: string, role = 'Admin') => {
    let userId = '';

    let response = await window.api.select({
        meta: 'InfoUsers',
        filters: `UserUuid=eq.${userUuid}`
    });

    if (response && response.objects.length > 0) {
        userId = response.objects[0].Id;
    }

    if (!userId) return false;

    response = await window.api.select({
        meta: 'InfoUserRoles',
        filters: `Role=eq.${role}&User=eq.${userId}`
    });

    return response && response.objects.length > 0;
};

export const useSupabaseUser = (): UseSupabaseUser => {
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useBoolean(true);
    const [isPowerUser, setIsPowerUser] = useBoolean(false);

    useEffectOnce(() => {
        const init = async () => {
            const { data } = await supabaseClient.auth.getSession();

            setUser(data.session?.user ?? null);
            setLoading(false);

            if (!data.session) return;

            if (await isRoleExists(data.session?.user.id, 'Admin')) setIsPowerUser(true);
        };

        init();
    });

    return { user, loading, isPowerUser, setUser };
};

export const useUserData = () => {
    const [data, setData] = useState<InfoUser | null>(null);
    const [info, setInfo] = useState<Meta | null>(null);
    const { user } = useSupabaseUser();

    useEffect(() => {
        const init = async () => {
            const info = await metaStore.getInfo('InfoUsers');

            const data = await window.api.select({
                meta: 'InfoUsers',
                filters: `UserUuid=eq.${user?.id}`
            });

            if (info) setInfo(info);
            if (data) setData(data?.objects[0] as InfoUser);
        };

        if (user) init();
    }, [user]);

    return { data, info };
};
