import { User } from '@supabase/supabase-js';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { supabaseClient } from 'modules/supabase/contexts/SupabaseContext/SupabaseContext';

type AccessLevel = 'no_access' | 'read_only' | 'read_write';

export interface Permissions {
    components: Record<string, AccessLevel>;
    routes: Record<string, boolean>;
}

interface PermissionsContextData {
    permissions: Permissions;
    loading: boolean;
}

const PermissionsContext = createContext<PermissionsContextData>({
    permissions: {
        components: {},
        routes: {}
    },
    loading: true
});

// это используем там где хотим проверять права доступа
export const usePermissions = () => {
    const context = useContext(PermissionsContext);
    if (!context) {
        throw new Error('usePermissions must be used within a PermissionsProvider');
    }
    return context;
};

interface PermissionsProviderProps {
    user: User | null;
    children: React.ReactNode;
}

export const PermissionsProvider: React.FC<PermissionsProviderProps> = ({ user, children }) => {
    const [permissions, setPermissions] = useState<Permissions>({
        components: {},
        routes: {}
    });

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const controller = new AbortController()
        if (user) {
            const { roles } = user.user_metadata; // тут заголовки ролей а не их id. может быть надо и ID тоже передавать?

            // console.log('ROLES:', roles);
            // console.log('user:', user);

            const fetchPermissions = async () => {
                const { data: roleComponents } = await supabaseClient
                    .from('vsettings_role_components')
                    .select('*')
                    .in('role_title', roles).abortSignal(controller.signal);

                const { data: roleRoutes } = await supabaseClient
                    .from('vsettings_role_routes')
                    .select('*')
                    .in('role_title', roles).abortSignal(controller.signal);

                const permissions: Permissions = {
                    components: {},
                    routes: {}
                };

                roleComponents?.forEach((item) => {
                    permissions.components[item.component_name!] = item.access_level as AccessLevel;
                });

                roleRoutes?.forEach((item) => {
                    permissions.routes[item.route_path!] = item.is_allowed!;
                });

                setPermissions(permissions);
            };

            // TODO: make it working
            // done: тут нужно сделать подписку на таблицы и в случае правок делать перезагрузку view тк. только там есть названия ролей. Или все-таки передавать на фронтенд ID ролей - тогда нам имена ролей не нужны и можно запросы делать к таблице а не к view
            // const permissionsListener = supabase
            //   .from('settings_role_components, settings_role_routes')
            //   .on('*', () => {
            //     fetchPermissions();
            //   })
            //   .subscribe();

            if (roles) {
                fetchPermissions();
            }
        }

            setLoading(false);

        return () => {
            controller.abort()
        };
    }, [user]);

    const value = useMemo(() => ({ permissions, loading }), [loading, permissions]);

    if (!user) {
        return children;
    }

    return <PermissionsContext.Provider value={value}>{children}</PermissionsContext.Provider>;
};
