import { ColorPicker } from 'antd';
import { memo, useCallback } from 'react';

interface ColorFieldProps {
    value: string | null; // Color string format, e.g. '#ff0000'
    onChange: (newValue: string) => void;
}

export const ColorField = memo<ColorFieldProps>(({ value, onChange }) => {
    // const renderView = useCallback(
    //     (viewValue: string) => (
    //         <div
    //             style={{
    //                 backgroundColor: viewValue,
    //                 width: '50px',
    //                 height: '20px'
    //             }}
    //         />
    //     ),
    //     []
    // );

    return (
        <ColorPicker
            size="large"
            value={value || ''}
            onChange={(value) => {
                onChange(value.toHexString());
            }}
        />
    );
});
