import { SearchOutlined } from '@ant-design/icons';
import { Button, Flex, Input, Popover } from 'antd';
import { memo, useState } from 'react';
import { useMedia } from 'react-use';
import { ButtonWithTooltips } from 'ui';

interface LocalSearchComponentProps {
    searchFilter?: string;
    setFilter: (filter: string) => void;
    placeholder: string;
}

export const LocalSearchComponent = memo<LocalSearchComponentProps>(
    ({ searchFilter, setFilter, placeholder }) => {
        const [isLocalFilter, setIsLocalFilter] = useState(!!searchFilter);
        const [localFilterChange, setLocalFilterChange] = useState(searchFilter || '');
        // const [isMobileSearchOpen, setIsMobileSearchOpen] = useBoolean(false);

        const isMiddleTablet = useMedia('(max-width: 720px)');

        // useEffectOnce(() => {
        //     setLocalFilterChange(searchFilter || '');
        //     setIsLocalFilter(!!searchFilter);
        // });

        return isMiddleTablet ? (
            <>
                <Popover
                    placement={'bottomLeft'}
                    destroyTooltipOnHide
                    content={
                        <Input.Search
                            size={isMiddleTablet ? 'large' : 'middle'}
                            allowClear
                            enterButton
                            placeholder={placeholder}
                            value={localFilterChange}
                            onChange={({ target }) => {
                                setLocalFilterChange(target.value);
                            }}
                            onSearch={setFilter}
                            onBlur={() => setFilter(localFilterChange)}
                        />
                    }
                >
                    <Button
                        icon={<SearchOutlined />}
                        // onClick={() => setIsMobileSearchOpen(true)}
                    />
                </Popover>

                {/* <Modal */}
                {/*    centered */}
                {/*    onClose={() => setIsMobileSearchOpen(false)} */}
                {/*    onCancel={() => setIsMobileSearchOpen(false)} */}
                {/* > */}
                {/*    <Input.Search */}
                {/*        allowClear */}
                {/*        enterButton */}
                {/*        placeholder={placeholder} */}
                {/*        value={localFilterChange} */}
                {/*        onChange={({ target }) => { */}
                {/*            setLocalFilterChange(target.value); */}
                {/*        }} */}
                {/*        onSearch={setFilter} */}
                {/*        onBlur={() => setFilter(localFilterChange)} */}
                {/*    /> */}
                {/* </Modal> */}
            </>
        ) : (
            <Flex>
                {isLocalFilter ? (
                    <Input.Search
                        allowClear
                        enterButton
                        placeholder={placeholder}
                        value={localFilterChange}
                        onChange={({ target }) => {
                            setLocalFilterChange(target.value);
                        }}
                        onSearch={setFilter}
                        onBlur={() => setFilter(localFilterChange)}
                    />
                ) : (
                    <ButtonWithTooltips
                        id="filter-local"
                        // icon={isLocalFilter ? <CloseOutlined /> : <SearchOutlined />}
                        icon={<SearchOutlined />}
                        type="default"
                        className="filter-local"
                        onClick={() => setIsLocalFilter((prev) => !prev)}
                    />
                )}
            </Flex>
        );
    }
);
