import { CopyOutlined } from '@ant-design/icons';
import { LabelValue } from '@gilbarbara/types';
import { LinkOutlined } from '@mui/icons-material';
import { Button, Space, Typography } from 'antd';
import { toJS } from 'mobx';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useBoolean } from 'react-use';

import { Route } from 'modules/services/backend-api/generated_api';
import { checkAndMakeTreeData, fnv1aHash } from 'smart/utils';
import { Loader } from 'ui/Loader/Loader';
import { i18n } from 'utils/i18n/i18n';
import { emitter } from 'utils/emitter';
import { toPascalCase } from 'utils/helpers/toPascalCase';
import { useStoreNavigate } from 'utils/store';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';

import { SmartSearchList } from './components';
import { SmartSelectInner } from './ui';
import { findRoute, renderOptionLabel, transformDataToOptions, useSelectData } from './utils';

import './SmartSelectField.scss';

export const MAX_VISIBLE_OPTIONS = 10;

interface SmartSelectFieldProps {
    value: any | null;
    meta: string;
    onChange: (newValue: any) => void;
    popoverContainerHtmlId?: string;
    simpleMode?: boolean;
    label?: string;
    loading?: boolean;
    disabled?: boolean;
    filters?: string;
    style?: React.CSSProperties;
    className?: string;
    navigateMetaOptions?: {
        metaName: string;
    };
    variant?: 'filled' | 'outlined' | 'borderless';
    myViewFieldName?: string;
    treeOptions?: {
        forceDisable?: boolean;
        parentFieldName?: string;
    };
}

export const SmartSelectField = memo<SmartSelectFieldProps>(
    ({
        value,
        popoverContainerHtmlId,
        meta,
        onChange,
        label,
        loading,
        disabled,
        style,
        navigateMetaOptions,
        variant,
        treeOptions,
        className = '',
        filters = '',
        simpleMode = false,
        myViewFieldName = ''
    }) => {
        const location = useLocation();
        const {
            t,
            i18n: { language }
        } = useTranslation();
        const navigate = useStoreNavigate();
        const {
            fetchData,
            dataSource,
            insertAtDataSource,
            metaSource,
            modalFields,
            modalTitle,
            isLoading
        } = useSelectData({
            meta,
            filters
        });

        const isMetaReadOnly = metaSource?.IsReadOnly || false;
        const uiAllowView = metaSource?.UiAllowView;
        const uiAllowCreate = metaSource?.UiAllowView;
        const uiAllowSelect = metaSource?.UiAllowSelect;
        const viewFieldName = toPascalCase(metaSource?.Type?.ViewFieldName || '');
        const refViewTemplate = metaSource?.RefViewTemplate;

        const [isOpenMore, setOpenMore] = useBoolean(false);
        // const [searchValue, setSearchValue] = useState<string>();

        useEffect(() => {
            const cb = (e: unknown) => {
                onChange(e);
                insertAtDataSource(0, e as IObjectWithId);
            };

            if (!simpleMode && !isLoading) {
                emitter.on(`create_${meta}`, cb);
            }

            return () => emitter.off(`create_${meta}`, cb);
        }, [isLoading, meta, onChange, simpleMode]);

        const isWithMetaNavParams =
            uiAllowView &&
            navigateMetaOptions &&
            value &&
            !!findRoute(navigateMetaOptions.metaName);

        const outputOptions = useMemo<LabelValue[]>(() => {
            const treeData = checkAndMakeTreeData(dataSource, treeOptions?.parentFieldName, false);

            const options = transformDataToOptions(
                treeData,
                myViewFieldName || viewFieldName,
                language,
                refViewTemplate
            );

            // const options = dataSource.map((item) => ({
            //     value: item.Code || item.Id,
            //     label: renderOptionLabel(item, myViewFieldName || viewFieldName)
            // }));
            // console.log('[SmartSelectField] options:', options);

            return options;
        }, [
            dataSource,
            myViewFieldName,
            refViewTemplate,
            treeOptions?.parentFieldName,
            viewFieldName,
            language
        ]);

        const displayValue = useMemo(() => {
            const selectedOption = outputOptions.find(
                (opt) => value && opt.value === (value.Code || value.Id)
            );

            return selectedOption
                ? selectedOption.label
                : renderOptionLabel(value, 'ShortTitle') || renderOptionLabel(value, 'Name');
        }, [outputOptions, value]);

        const handleNavigate = useCallback(
            async (event: React.MouseEvent) => {
                event.stopPropagation();

                if (!navigateMetaOptions) {
                    console.warn('[SmartSelectField.handleNavigate] navigateMetaOptions is empty.');
                    return;
                }

                const needle = findRoute(navigateMetaOptions.metaName);

                if (!needle) {
                    console.warn(
                        `[SmartSelectField.handleNavigate] ${navigateMetaOptions.metaName} no found.`
                    );
                    return;
                }

                const navId = needle.detail_route_id || value.Id;

                navigate(`${needle.path}/${navId}`);
            },
            [navigate, navigateMetaOptions, value?.Id]
        );

        const handleCopy = useCallback(
            async (event: React.MouseEvent) => {
                event.stopPropagation();

                window.navigator.clipboard.writeText(displayValue);
            },
            [displayValue]
        );

        const handleCloseMore = useCallback(() => {
            setOpenMore(false);
        }, []);

        const handleOpenMore = useCallback(() => {
            setOpenMore(true);
        }, []);

        const handleCreate = useCallback(() => {
            const metaRoutes = toJS(metaStore.meta.get('all')?.routes);

            let needle = metaRoutes?.find((route) => route.meta === meta);

            if (!needle) needle = { path: `/other/${meta}` } as Route;

            let pathname = `${needle?.path}/new`;

            if (location.pathname.endsWith('/')) {
                pathname = `${needle.path}new`;
            }

            const singularName =
                metaSource?.SingularName?.[i18n.language] || t(pathname.split('/').at(-2) || ''); // get pre last pathname element for correctly displaying object context

            navigate(
                { pathname },
                {
                    state: {
                        ...location.state,
                        cacheKey: fnv1aHash(`${meta}_new_edit`),
                        pageTitle: `${t('new')} (${singularName})`
                    }
                }
            );
        }, [location.pathname, location.state, meta, metaSource?.SingularName, navigate, t]);

        return (
            <div style={{ width: '100%' }}>
                <SmartSearchList
                    fields={modalFields}
                    title={modalTitle}
                    meta={meta}
                    open={isOpenMore}
                    onCancel={handleCloseMore}
                    data={dataSource}
                    initialSelectedRows={value ? [value] : []}
                    onOk={(selectedRows) => {
                        onChange(selectedRows[0]);
                        handleCloseMore();
                    }}
                />
                {label && <Typography.Text type="secondary">{label}</Typography.Text>}
                <Space.Compact className="smart_select_field">
                    <SmartSelectInner
                        // searchValue={searchValue}
                        // onSearch={setSearchValue}
                        style={{
                            width:
                                isWithMetaNavParams && !simpleMode
                                    ? 'calc(100% - 31px - 31px)'
                                    : value && !simpleMode
                                      ? 'calc(100% - 31px)'
                                      : '100%',

                            ...style
                        }}
                        disableTree={treeOptions?.forceDisable}
                        onFocus={fetchData}
                        variant={variant}
                        loading={loading}
                        disabled={disabled}
                        showSearch={!simpleMode}
                        className={className}
                        value={
                            typeof displayValue === 'number' ? String(displayValue) : displayValue
                        }
                        allowClear
                        placeholder={!simpleMode ? t('no_value') : undefined}
                        options={outputOptions}
                        popupMatchSelectWidth={false}
                        onChange={(value) => {
                            onChange(dataSource.find((item) => item.Id === value));
                        }}
                        notFoundContent={isLoading ? <Loader size="small" /> : undefined}
                        dropdownRender={
                            !simpleMode && !isLoading
                                ? (menu) => (
                                      <>
                                          {menu}
                                          {outputOptions.length > 0 && uiAllowSelect && (
                                              <Button type="link" onClick={handleOpenMore}>
                                                  {t('show_more')}
                                              </Button>
                                          )}
                                          <br />
                                          {uiAllowCreate && (
                                              <Button
                                                  disabled={isMetaReadOnly}
                                                  type="link"
                                                  onClick={handleCreate}
                                              >
                                                  {t('create')}
                                              </Button>
                                          )}
                                      </>
                                  )
                                : undefined
                        }
                        getPopupContainer={
                            popoverContainerHtmlId
                                ? () =>
                                      document.getElementById(popoverContainerHtmlId) as HTMLElement
                                : undefined
                        }
                    />
                    {isWithMetaNavParams ? (
                        <Button
                            type="default"
                            className="meta_nav_button"
                            onClick={handleNavigate}
                            icon={<LinkOutlined />}
                        />
                    ) : null}
                    {value && !simpleMode ? (
                        <Button
                            type="default"
                            className="meta_copy_button"
                            onClick={handleCopy}
                            icon={<CopyOutlined />}
                        />
                    ) : null}
                </Space.Compact>
            </div>
        );
    }
);
