import { CloseCircleFilled } from '@ant-design/icons';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { localeFormats, REGEX_REPLACE_GMT } from 'utils/helpers/dates';
import { i18n, LANGUAGES } from 'utils/i18n/i18n';
import { langMasks } from '../SmartDateField/SmartDateField';

type AntdRangeValue<T> = [T | null, T | null];

type DateTimeRangeValue = {
    FromDatetime: string | null;
    ToDatetime: string | null;
};

interface SmartDatetimeRangeFieldProps {
    value: DateTimeRangeValue | null; // ISO string (from Supabase DB) aka DbDateTime
    onChange: (newValue: DateTimeRangeValue | null) => void; // ISO string (from Supabase DB) aka DbDateTime
    popoverContainerHtmlId?: string;
    disabled?: boolean;
    showTime?: boolean;
    utc?: boolean; //  если utc=true Сохраняем в UTC, отображаем как есть, т.е. учитывается часовой пояс
    style?: { [key: string]: any };
    className?: string;
}
export const SmartDatetimeRangeField = memo<SmartDatetimeRangeFieldProps>(
    ({
        value,
        onChange,
        disabled,
        popoverContainerHtmlId,
        className,
        style,
        showTime = true,
        utc
    }) => {
        const { t } = useTranslation();

        const format = showTime
            ? langMasks[i18n.language || 'en'].datetime
            : langMasks[i18n.language || 'en'].date;

        // Определение функции для рендеринга компонента выбора даты и времени
        const handleClear: React.MouseEventHandler<HTMLSpanElement> = (e) => {
            e.stopPropagation();
            onChange(null);
        };

        const handleChange = useCallback(
            (date: AntdRangeValue<Dayjs> | null) => {
                onChange({
                    FromDatetime: date
                        ? dayjs(date[0]).format(showTime ? 'YYYY-MM-DDTHH:mm:ssZ' : 'YYYY-MM-DD')
                        : null,
                    ToDatetime: date
                        ? dayjs(date[1]).format(showTime ? 'YYYY-MM-DDTHH:mm:ssZ' : 'YYYY-MM-DD')
                        : null
                });
            },
            [onChange, utc]
        );

        const antdValue: AntdRangeValue<Dayjs> = [
            value?.FromDatetime
                ? dayjs(value.FromDatetime.replace(REGEX_REPLACE_GMT, '').replace('Z', ''))
                : null,
            value?.ToDatetime
                ? dayjs(value.ToDatetime.replace(REGEX_REPLACE_GMT, '').replace('Z', ''))
                : null
        ];
        // The component is used to display and edit date and time values using user's preferred format!
        const handleKeyPress: (event: React.KeyboardEvent<HTMLElement>) => void = (e) => {
            if (['Enter', 'Tab'].includes(e.key)) {
                if (!e.target.value) {
                    onChange(null);
                    return;
                }

                const date = dayjs(
                    e.target.value,
                    localeFormats[(i18n.language as Exclude<LANGUAGES, 'tech'>) || 'en'].formats.L,
                    true
                ).locale(i18n.language);

                if (date.isValid()) {
                    handleChange(date);
                }
            }
        };

        // Рендеринг базового компонента поля ввода с переданными пропсами и определенными функциями рендеринга компонентов выбора и просмотра даты и времени
        return (
            <DatePicker.RangePicker
                placeholder={[t('no_value') as string, t('no_value') as string]}
                value={antdValue}
                onChange={handleChange}
                format={{ format, type: 'mask' }}
                allowClear={{ clearIcon: <CloseCircleFilled onClick={handleClear} /> }}
                showTime={showTime}
                className={className}
                style={style}
                onBlur={(e) =>
                    handleKeyPress({
                        target: { value: e.target.value || setLocalValue },
                        key: 'Enter'
                    })
                }
                onKeyDown={handleKeyPress}
                disabled={disabled}
                getPopupContainer={
                    popoverContainerHtmlId
                        ? () => document.getElementById(popoverContainerHtmlId) as HTMLElement
                        : undefined
                }
            />
        );
    }
);
