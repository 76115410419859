import {
    CloudDownloadOutlined,
    CloudUploadOutlined,
    DeliveredProcedureOutlined,
    PrinterOutlined
} from '@ant-design/icons';
import { PlainObject, StringOrNull } from '@gilbarbara/types';
import { Flex, Space, Typography } from 'antd';
import { toJS } from 'mobx';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { Action, MetaField } from 'modules/services/backend-api/generated_info';
import { ButtonWithTooltips } from 'ui';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';
import { Response } from 'modules/services/backend-api/generated_api';
import { exportDataToExcel } from 'smart/utils/exportTable';
import { makeErrorReadable } from 'utils/helpers/makeErrorReadable';
import { useNotifications } from 'utils/hooks';
import { useReactToPrint } from 'react-to-print';
import { isArray, isBoolean, isPlainObject, isPropertyOf } from 'is-lite/exports';
import { observer } from 'mobx-react-lite';
import { useMap, useMedia } from 'react-use';
import { getMockActionDataByGetPartially } from '../../utils';
import { UserSpecificFormat } from '../../../../../utils/helpers/dates';
import { i18n } from '../../../../../utils/i18n/i18n';
import { ExcelField } from '../../../../components';

import './SmartTablePageFooterToolbar.scss';

interface SmartTablePageToolbarProps {
    meta: string;
    selectedRowKeys: string[];
    selectedRows: IObjectWithId[];
    hasSelected: boolean;
    totalDataCount: number;
    fieldsSource: MetaField[];
    onClickAction: (
        action: Action,
        hardIds?: string[],
        hardArgs?: PlainObject
    ) => Promise<Response | undefined>;
}

interface RenderOptions {
    value: any;
    dataSource: IObjectWithId;
    language: string;
    field?: MetaField;
}

type PrintDataArray = [{ value: string }, { value: string | null | undefined }][];
const TECH_FIELDS = ['Id', 'LastUpdatedAt', 'LastUpdatedBy', 'Ver', 'DeletedAt'];

const renderExcelToPrintCells = ({
    value,
    dataSource,
    language,
    field
}: RenderOptions): string | null | undefined => {
    const fieldName = field?.FieldName ?? '';

    if (field?.ValueType?.includes('coordinate')) {
        return value && value.Latitude && value.Longitude
            ? `${value.Latitude} ${value.Longitude}`
            : '';
    }

    if (
        fieldName.endsWith('Value') &&
        isPropertyOf(dataSource as PlainObject, fieldName.replace('Value', 'MeasureUnit'))
    ) {
        return `${dataSource[fieldName]} ${
            dataSource[fieldName?.replace('Value', 'MeasureUnit')].Name?.[i18n.language]
        }`;
    }

    if (
        fieldName.endsWith('CurrencyValue') &&
        isPropertyOf(dataSource as PlainObject, fieldName.replace('CurrencyValue', 'Currency'))
    ) {
        return `${dataSource[fieldName]} ${
            dataSource[fieldName.replace('CurrencyValue', 'Currency')].Name?.[i18n.language]
        }`;
    }

    if (
        fieldName.endsWith('Value') &&
        isPropertyOf(dataSource as PlainObject, fieldName.replace('Value', 'Currency'))
    ) {
        return `${dataSource[fieldName]} ${
            dataSource[fieldName.replace('Value', 'Currency')].Name?.[i18n.language]
        }`;
    }

    if (
        fieldName.endsWith('Amount') &&
        isPropertyOf(dataSource as PlainObject, fieldName.replace('Amount', 'Currency'))
    ) {
        return `${dataSource[fieldName]} ${
            dataSource[fieldName.replace('Amount', 'Currency')].Name?.[i18n.language]
        }`;
    }

    if (
        fieldName.endsWith('CostValue') &&
        isPropertyOf(dataSource as PlainObject, fieldName.replace('CostValue', 'Currency'))
    ) {
        return `${dataSource[fieldName]} ${
            dataSource[fieldName.replace('CostValue', 'Currency')].Name?.[i18n.language]
        }`;
    }

    if (isPlainObject(value)) {
        if (isPropertyOf(value, language)) return value?.[language] as StringOrNull;
        if (isPropertyOf(value, 'Name') && isPlainObject(value.Name))
            return value.Name[language] as StringOrNull;
        if (isPropertyOf(value, 'ShortTitle') && isPlainObject(value.ShortTitle))
            return value.ShortTitle[language] as StringOrNull;
        if (isPropertyOf(value, 'PluralName') && isPlainObject(value.PluralName))
            return value.PluralName[language] as StringOrNull;

        if (isPropertyOf(value, 'Key')) return value.Key as StringOrNull;
        if (isPropertyOf(value, 'Code')) return value.Code as StringOrNull;

        return '';
    }

    if (isArray(value)) {
        const result = [];

        for (const valueElement of value) {
            result.push(
                renderExcelToPrintCells({
                    value: valueElement,
                    language,
                    field: {
                        ...field,
                        ValueType: field?.ValueType?.replace('[]', '')
                    } as MetaField,
                    dataSource
                })
            );
        }

        return result.join(',');
    }

    if (isBoolean(value)) {
        return value ? 'Да' : 'Нет';
    }

    if (
        value?.FromDatetime &&
        value?.ToDatetime &&
        (field?.ValueType?.includes('datetime_range') ||
            field?.ValueType?.includes('datetimerange'))
    ) {
        return `${UserSpecificFormat.convertFromDbDateTimeLocalToUiDateTime(
            value.FromDatetime,
            language
        )} - ${UserSpecificFormat.convertFromDbDateTimeLocalToUiDateTime(
            value.ToDatetime,
            language
        )}`;
    }

    if (value?.FromTime && value?.ToTime && field?.ValueType?.includes('time_range')) {
        return `${value.FromTime} - ${value.ToTime}`;
    }

    if (value && field?.ValueType?.includes('datetime')) {
        return UserSpecificFormat.convertFromDbDateTimeLocalToUiDateTime(value, language);
    }

    if (value && field?.ValueType?.includes('time')) {
        return UserSpecificFormat.convertFromDbTimeToUiTime(value, language);
    }

    if (value && field?.ValueType?.includes('date')) {
        return UserSpecificFormat.convertFromDbDateToUiDate(value, language);
    }

    return value?.toString();
};

export const SmartTablePageFooterToolbar = observer<SmartTablePageToolbarProps>(
    ({
        meta,
        selectedRowKeys,
        hasSelected,
        totalDataCount,
        selectedRows,
        fieldsSource,
        onClickAction
    }) => {
        const {
            t,
            i18n: { language }
        } = useTranslation();
        const isBigMobile = useMedia('(max-width: 480px)');

        const actions = useMemo(() => toJS(metaStore.meta.get(meta)?.info?.Actions), [meta]);

        const uiExcelTableTitle = useMemo(() => {
            const title = renderExcelToPrintCells({
                value: selectedRows.at(-1),
                language,
                dataSource: selectedRows.at(-1) ?? { Id: '' }
            });

            return `${toJS(metaStore.meta.get(meta)?.info?.SingularName?.[language])}${title ? ` "${title}"` : ''}`;
        }, [language, meta, selectedRows]);

        const { notification } = useNotifications();

        const toPrintRef = useRef<HTMLDivElement | null>(null);
        const handlePrint = useReactToPrint({
            content: () => toPrintRef.current
        });

        const [printMap, printMapActions] = useMap<{ [k: string]: PrintDataArray }>();

        useEffect(() => {
            const lastSelectedItem = selectedRows.at(-1);
            if (lastSelectedItem) {
                const printData: PrintDataArray = [];
                const layoutGroups: { [k: string]: MetaField[] } = {};

                // Группируем поля по LayoutArea
                (toJS(metaStore.meta.get(meta)?.info?.Fields) ?? [])?.forEach((field) => {
                    const area = field.LayoutArea ?? '';
                    if (!layoutGroups[area]) {
                        layoutGroups[area] = [];
                    }
                    layoutGroups[area].push(field);
                });

                // Проходим по каждой LayoutArea
                for (const area of Object.keys(layoutGroups)) {
                    const isChildArea = area.includes('/') && area !== 'Page/Header';
                    const areaKey = isChildArea ? area.split('/')[1] : area;
                    // Добавляем [{ value: LayoutArea }]

                    const title =
                        areaKey === 'Main' || areaKey === 'Page/Header'
                            ? t('common_info')
                            : areaKey === 'Header'
                              ? `${t('common_info')} / ${t('header')}`
                              : areaKey === 'Footer'
                                ? `${t('common_info')} / ${t('footer')}`
                                : t(areaKey.toLowerCase());

                    printMapActions.set(
                        title,
                        layoutGroups[area].map((field) => {
                            const row = [
                                { value: field.Name?.[language] ?? field.FieldName },
                                {
                                    value: renderExcelToPrintCells({
                                        value:
                                            lastSelectedItem[field.FieldName] ??
                                            lastSelectedItem.VirtualFields?.[field.FieldName],
                                        language,
                                        field,
                                        dataSource: lastSelectedItem
                                    })
                                }
                            ];
                            printData.push(row);

                            return row;
                        })
                    );
                }
            }
        }, [metaStore.meta.get(meta)?.info?.Fields, selectedRows.at(-1)]);

        const handleExportToExcel = async () => {
            const error: null | Error = null;

            if (error) {
                notification.error({
                    message: t('error'),
                    description: makeErrorReadable((error as Error).message)
                });
            }

            exportDataToExcel({
                metaName: meta,
                tableData: selectedRows,
                columns: fieldsSource
            });
        };

        return (
            <>
                <Flex justify="space-between" className="smart_table__footer">
                    <div>
                        {/* ### импорт/экспорт */}
                        {!isBigMobile && (
                            <Space.Compact className="smart_table__footer_left">
                                <ButtonWithTooltips
                                    type="default"
                                    className=""
                                    id="massPrintBtn"
                                    disabled={!hasSelected}
                                    tooltipTitle={t('massPrintBtn')}
                                    tooltipPlacement="top"
                                    onClick={handlePrint}
                                    icon={<PrinterOutlined />}
                                />
                                <ButtonWithTooltips
                                    type="default"
                                    className=""
                                    id="download_outlined"
                                    tooltipTitle={t('download_outlined')}
                                    tooltipPlacement="top"
                                    icon={<CloudUploadOutlined />}
                                    onClick={() => {
                                        // TODO: 19.08.2024 пока закомментили поиск экшна
                                        // if (actions) {
                                        //     const importAction = actions.find(
                                        //         // (action) => action.Handler_Code === 'ImportFromExcel'
                                        //         (action) => action.Handler?.MethodName === 'ImportFromExcel'
                                        //     );

                                        //     if (importAction) onClickAction(importAction, [v4()]);
                                        // }

                                        const action = getMockActionDataByGetPartially({
                                            Handler_Code: 'ImportFromExcel',
                                            Meta_Code: meta,
                                            Name: { ru: 'Импорт из Excel' },
                                            Handler: {
                                                ChildParams: [
                                                    {
                                                        ParentHandler_Code: '',
                                                        ParamName: 'document_type_code',
                                                        Name: {
                                                            en: 'Document type',
                                                            ru: 'Тип документа'
                                                        },
                                                        ValueType: 'code;ref:DctDocumentTypes.Code',
                                                        IsRequested: false,
                                                        IsMandatory: false,
                                                        ChildIndex: 0
                                                    },
                                                    {
                                                        ParentHandler_Code: '',
                                                        ParamName: 'file_path',
                                                        Name: { en: 'File', ru: 'Файл' },
                                                        ValueType: 'file;bucket:downloads',
                                                        IsRequested: true,
                                                        IsMandatory: false,
                                                        ChildIndex: 1
                                                    }
                                                ],
                                                Code: 'ImportFromExcel',
                                                Name: { ru: 'Импорт из Excel' },
                                                Meta_Code: meta
                                            }
                                        });

                                        onClickAction(action, [v4()]);
                                    }}
                                />

                                <ButtonWithTooltips
                                    type="default"
                                    className=""
                                    id="download_up_outlined"
                                    tooltipTitle={t('download_up_outlined')}
                                    tooltipPlacement="top"
                                    icon={<CloudDownloadOutlined />}
                                    onClick={() => {
                                        if (actions) {
                                            const exportAction = actions.find(
                                                // (action) => action.Handler_Code === 'ExportToExcel'
                                                (action) =>
                                                    action.Handler?.MethodName === 'ExportToExcel'
                                            );

                                            if (exportAction) onClickAction(exportAction);
                                        }

                                        // const action = getMockActionDataByGetPartially({
                                        //     Handler_Code: 'ExportToExcel',
                                        //     Meta_Code: meta,
                                        //     Name: { ru: 'Экспорт в Excel' },
                                        //     Handler: {
                                        //         ChildParams: [],
                                        //         Code: 'ExportToExcel',
                                        //         Name: { ru: 'Экспорт в Excel' },
                                        //         Meta_Code: meta
                                        //     }
                                        // });

                                        handleExportToExcel();

                                        // onClickAction(action);
                                    }}
                                    disabled={selectedRowKeys.length === 0}
                                />

                                <ButtonWithTooltips
                                    type="default"
                                    className=""
                                    id="mass_update"
                                    tooltipTitle={t('mass_update')}
                                    tooltipPlacement="top"
                                    icon={<DeliveredProcedureOutlined />}
                                    onClick={() => {
                                        if (actions) {
                                            const massUpdateAction = actions.find(
                                                // (action) => action.Handler_Code === 'ExportToExcel'
                                                (action) =>
                                                    action.Handler?.MethodName === 'MassUpdate'
                                            );

                                            if (massUpdateAction) onClickAction(massUpdateAction);
                                        }

                                        const action = getMockActionDataByGetPartially({
                                            Handler_Code: 'MassUpdate',
                                            Meta_Code: meta,
                                            Name: {
                                                ru: 'Массовое редактирование',
                                                en: 'Mass update'
                                            },
                                            Handler: {
                                                ChildParams: [],
                                                Code: 'MassUpdate',
                                                Name: {
                                                    ru: 'Массовое редактирование',
                                                    en: 'Mass update'
                                                },
                                                Meta_Code: meta
                                            }
                                        });

                                        onClickAction(action);

                                        // const action = getMockActionDataByGetPartially({
                                        //     Handler_Code: 'ExportToExcel',
                                        //     Meta_Code: meta,
                                        //     Name: { ru: 'Экспорт в Excel' },
                                        //     Handler: {
                                        //         ChildParams: [],
                                        //         Code: 'ExportToExcel',
                                        //         Name: { ru: 'Экспорт в Excel' },
                                        //         Meta_Code: meta
                                        //     }
                                        // });

                                        // handleExportToExcel();

                                        // onClickAction(action);
                                    }}
                                    disabled={selectedRowKeys.length === 0}
                                />

                                {/* <ButtonWithTooltips
                                type="default"
                                className=""
                                id="massPrintBtn"
                                disabled={!hasSelected}
                                tooltipTitle={t('massPrintBtn')}
                                tooltipPlacement="top"
                                onClick={() => {
                                    setPrinttingFormsModal(true);
                                }}
                                icon={<PrinterOutlined />}
                            /> */}
                            </Space.Compact>
                        )}
                    </div>

                    <Flex className="smart_table__footer_center">
                        {/* TODO: center part of toolbar */}
                        <></>
                    </Flex>

                    <Flex className="smart_table__footer_right">
                        {/* TODO: right part of toolbar */}
                        <Typography.Text strong>
                            {selectedRowKeys.length
                                ? `${t('selected')} ${selectedRowKeys.length} ${t('out_of')} `
                                : null}
                            {totalDataCount} {t('rows')}
                        </Typography.Text>
                    </Flex>
                </Flex>

                <div style={{ display: 'none' }}>
                    <div style={{ display: 'block', width: '1000px' }} ref={toPrintRef}>
                        <Typography.Title level={4} style={{ padding: 0, textAlign: 'center' }}>
                            {uiExcelTableTitle}
                        </Typography.Title>
                        {Object.entries(printMap).map(([key, value]) => {
                            return (
                                <ExcelField
                                    className={'to_print_excel'}
                                    file={value}
                                    uiTableTitle={key}
                                    hideIndicators={true}
                                />
                            );
                        })}
                    </div>
                </div>

                {/* <SelectPrintingFormModal */}
                {/*    open={showPrinttingFormsModal} */}
                {/*    setOpen={setPrinttingFormsModal} */}
                {/*    documentIds={selectedRowKeys.map((id) => Number(id))} */}
                {/*    tableName={toSnakeCase(meta) as TableName} */}
                {/*    viewName={`v${toSnakeCase(meta)}` as ViewName} */}
                {/* /> */}
            </>
        );
    }
);
