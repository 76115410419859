import { Select, SelectProps, TreeSelect, TreeSelectProps } from 'antd';
import { memo } from 'react';

import { filterSort } from '../../utils';

export const SmartSelectInner = memo<
    SelectProps &
        TreeSelectProps & {
            disableTree?: boolean;
        }
>(({ disableTree, maxCount, ...props }) => {
    // const [searchValue, setSearchValue] = useState<string>();

    // useEffect(() => {
    //     if (!props.multiple && props.mode !== 'multiple') {
    //         if (isUndefined(searchValue) && isDefined(props.value)) setSearchValue(props.value);
    //         else if (isEmpty(searchValue) && isDefined(props.value)) {
    //             setSearchValue(undefined);
    //             props.onChange(undefined);
    //         }
    //     }
    // }, [props, props.mode, props.multiple, props.value, searchValue]);

    if (disableTree)
        return (
            <Select
                {...props}
                maxCount={maxCount}
                mode={props.multiple || props.mode === 'multiple' ? 'multiple' : props.mode}
                optionFilterProp="label"
                // filterOption={filterOption}
                filterSort={filterSort}
            />
        );

    return (
        <TreeSelect
            // TODO: приделать поддержку maxCount
            {...props}
            treeData={props.options as TreeSelectProps['treeData']}
            treeIcon
            treeNodeLabelProp="label"
            treeNodeFilterProp="label"
            // filterTreeNode={filterOption}
            multiple={props.multiple || props.mode === 'multiple'}
            autoClearSearchValue={false}
            // searchValue={searchValue}
            // onSearch={setSearchValue}
        />
    );
});
