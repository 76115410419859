import React from 'react';

import { Permissions } from 'modules/auth/contexts';

interface PermissionRequireProps extends React.PropsWithChildren {
    id: string;
    permissions: Permissions;
}

export const AccessControl: React.FC<PermissionRequireProps> = ({ children, id, permissions }) => {
    const accessLevel = permissions.components[id] || 'read_write';
    if (accessLevel === 'no_access') return <></>;

    return <>{children}</>;
};
