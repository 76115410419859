import { Dropdown, MenuProps } from 'antd';
import React, { memo } from 'react';

interface ViewRowProps {
    onDoubleClick: () => void;
    onCtrlClick: () => void;
    onShiftClick: () => void;
    onClick: () => void;
    onRightClick?: () => void;
    virtual?: boolean;
    contextMenuItems?: MenuProps['items'];
    [key: string]: any;
}

const RowContent = memo<Omit<ViewRowProps, 'onRightClick' | 'contextMenuItems'>>(
    ({ onDoubleClick, onCtrlClick, onShiftClick, onClick, virtual, ...props }, ref) => (
        <tr
            {...props}
            ref={ref}
            style={{
                ...props.style,
                display: props.className.includes('placeholder')
                    ? 'table-row'
                    : virtual
                      ? 'flex'
                      : //   ? 'flex'
                        'table-row',
                background: props.row?.isHardGroup ? 'rgba(0,0,0,.02)' : undefined
            }}
            onDoubleClick={onDoubleClick}
            onClick={(e) => {
                if (e.shiftKey) {
                    if (onShiftClick) onShiftClick();
                } else if (e.ctrlKey) {
                    if (onCtrlClick) onCtrlClick();
                } else if (onClick) onClick();
            }}
        />
    )
);

export const ViewRow = memo<ViewRowProps>(({ onRightClick, contextMenuItems, ...props }, ref) => {
    if (props.className?.includes('placeholder') || !contextMenuItems)
        return <RowContent {...props} />;

    return (
        <Dropdown
            onOpenChange={onRightClick}
            trigger={['contextMenu']}
            menu={{ items: contextMenuItems }}
            destroyPopupOnHide
        >
            <RowContent {...props} ref={ref} />
        </Dropdown>
    );
});
